import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { card } from 'ionicons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    question: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
    answer: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
    card: {
      paddingBottom: '0px',
    },
  }),
);

export default function Faq() {
  const classes = useStyles();

  return (
    <div id="faq" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>FAQ</h2>
          <p>よくあるご質問</p>
        </div>
        <div className={classes.root}>
          <Card className={classes.root}>
            {/* <CardHeader
              title={'FAQ'}
              subheader={'よくあるご質問'}
              align="center"
              className={classes.card}
            /> */}
            <CardContent className={classes.card}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    音楽経験がなくても大丈夫でしょうか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    楽譜が読むのが苦手でも、心配いりません。ランデミーで楽しみながら、基本を身に付けられます。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    どのようなレッスンがありますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    ボイスマスタープログラム、楽器（ベーシック）プログラム、楽器（プロフェッショナル/音大受験）プログラムをご用意しております。
                    <br />
                    ボイスマスタープログラムは仕事ではっきり大きな声で話したい、営業で成約を取りたい、自分の声質を変えたい、歌が上手くなりたい方向けのプログラムです。
                    <br />
                    楽器（ベーシック）プログラムは趣味を含め、音楽を楽しみたい方向けのプログラムです。
                    <br />
                    楽器（プロフェッショナル/音大受験）プログラムは将来プロとして活躍していきたい方向けで受験に特化したレッスンを提供します。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンを受けるにはどのようにしたら良いですか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    以下の流れがレッスンを受けるまでの流れになります。
                    <br />
                    ① ランデミーにユーザー登録もしくは、ログインをする
                    <br />
                    ②
                    講師検索画面から好きな講師を選択し、「空き状況を見る&gt;」ボタンをクリック
                    <br />
                    ③ カレンダーの空いている所に予約を入れる 　 <br />
                    ※予め、トライアルレッスンもしくは、月額コースチケットを購入する
                    <br />
                    ④
                    レッスン時間になったら、マイページから「レッスンを開始する」ボタンをクリック
                    <br />
                    ⑤ レッスン画面でカメラのテストを行う
                    <br />
                    ⑥　⑤が問題なければ、Zoomでレッスンを受講する
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスン以外に料金はかかりますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    トライアルレッスン、月額コース、もしくは買切りコースのみの料金でレッスンをご受講いただけます。
                    <br />
                    ※講師によって、教則本が必要な場合もございますが、そちらはご自身でご用意をお願いいたします。
                    <br />
                    ※レッスンによって、対面レッスンが可能な場合もございますが、交通費等はご自身でご用意をお願いいたします。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンに必要なものは何ですか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    必要なもの、環境はこちらになります。
                    <br />
                    防音設備が整った部屋
                    <br />
                    <br />
                    高速インターネットが可能な環境
                    <br />
                    ※少なくとも10Mbps以上あると望ましいです。
                    <br />
                    <br />
                    PC（Windowsもしくは、Mac）
                    <br />
                    Google Chromeブラウザ 最新版
                    <br />
                    Zoomアプリケーション 最新版
                    <br />
                    Webカメラ・PC内臓マイク
                    <br />
                    <br />
                    （必要に応じて）レッスンで使用する楽器
                    <br />
                    （講師によって）教則本、メトロノーム、チューナー
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンのキャンセルはいつまでできますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    前日の23時59分まで可能です。
                    <br />
                    当日になりますと、キャンセル不可となります。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンを無断でキャンセル（欠席）した場合、どうなりますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    無断キャンセルはお控えください。
                    <br />
                    無断キャンセルの場合も返金には応じかねますので、ご了承ください。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    返金はできますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    商品の特性上、返金はできかねますので、ご了承ください。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    日割りはできますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    日割りはございません。
                    月額コースの場合は、ご契約日から1カ月間、レッスンチケットが有効となります。
                    <br />
                    買切りコースの場合は、コースに応じて有効期限が設定されます。
                    詳しくは運営事務局までお問い合わせください。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8a-content"
                  id="panel8a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    連弾はできますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    オンラインレッスンの場合、タイムラグが生じるため、講師と生徒様によるセッションは出来かねます。
                    <br />
                    また、講師が伴奏して生徒様に歌唱いただくようなレッスンもオンラインでは出来かねますことをご了承ください。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9a-content"
                  id="panel9a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    支払い方法は何がありますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    クレジットカード決済、Apple
                    Pay(iOSのみ)をお選びいただけます。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9a-content"
                  id="panel9a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンコースの有効期限はいつまでですか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    月額コースの場合、購入日から1ヶ月間有効です。日割りはございません。
                    <br />
                    買切りコースの場合は、コースに応じて有効期限が設定されます。
                    <br />
                    有効期限を過ぎると無効になりますので、期間内にレッスンをご予約いただき、レッスンのご受講をお願いいたします。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10a-content"
                  id="panel10a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンコースの変更は出来ますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    月額コースの場合、次の月のレッスンを変更いただけます。
                    <br />
                    料金コース画面で「次の月のレッスン」を選択ください。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11a-content"
                  id="panel11a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    レッスンコースの解約は出来ますか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    いつでも解約いただけます。
                    <br />
                    解約後も、現在ご契約されているコースは、購入日から有効期限内まで有効ですので、レッスンをご受講いただけます。
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12a-content"
                  id="panel12a-header"
                >
                  <HelpIcon />
                  {'　'}
                  <Typography className={classes.question}>
                    退会したいのですが、どうすれば良いですか？
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionAnswerIcon />
                  {'　'}
                  <Typography className={classes.answer} align="left">
                    いつでも退会いただけます。
                    <br />
                    退会後は、お客様の全てのデータ（コースご購入時のクレジットカード情報含む）が削除されますので、現在ご契約されているコースが有効な場合でも、レッスンをご受講いただけません。
                    また、退会による返金もございませんので、もし、現在のコースをご契約の場合は、「解約」されることをお勧めいたします。
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
