import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Fab, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(0, 0, 3),
    color: '#ffffff',
    backgroundColor: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    fontSize: 18,
  },
  linkText: {
    color: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
}));

export default function ResponsiveDialog(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [profile, setProfile] = React.useState(null);
  const [img, setImg] = React.useState(undefined);
  const [youtube, setYoutube] = React.useState(undefined);

  const handleClickOpen = () => {
    setProfile(props.props.profile);
    setImg(props.props.img);
    setYoutube(props.props.youtube);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab
        variant="extended"
        className={classes.button}
        aria-label="add"
        onClick={handleClickOpen}
      >
        プロフィールを見る &gt;{' '}
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'講師のプロフィール'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>{profile}</Typography>
          </DialogContentText>
          <DialogContent>
            {youtube ? (
              <DialogContent>
                <iframe
                  width="450"
                  height="315"
                  src={youtube}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </DialogContent>
            ) : (
              <></>
            )}
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            className={classes.linkText}
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
