import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { IonButton } from '@ionic/react';
import firebase from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import Console from "../hooks/console";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export const stripeConfig = {
  stripeApiKey: REACT_APP_STRIPE_API_KEY,
};

const stripeApiKey = stripeConfig.stripeApiKey!;
export const stripePromise = loadStripe(stripeApiKey);

type SubscriptionProps = {
  id: string;
  amount: Number;
  priceId: string;
  title: string;
  description: string;
  times: Number;
  mode: string;
};

const SubscriptionButton = (props: SubscriptionProps) => {
  const { id, amount, priceId, title, description, times, mode } = props;
  const [message, setMessage] = useState('');
  const { currentUser } = useContext(AuthContext);

  const handleClick = async () => {
    const stripe: any = await stripePromise;
    const sessionData = {
      uid: currentUser?.uid,
      name: currentUser?.displayName || '',
      email: currentUser?.email,
      currency: 'jpy',
      priceId: priceId,
      productId: id,
    };
    const session: any = await firebase
      .createSubscription(sessionData)
      .then(res => {
        Console.dir(res);
        return res.result;
      })
      .catch(error => {
        Console.error(error);
      });

    const result = await stripe.redirectToCheckout({
      sessionId: session?.id,
    });
    if (result.error) {
      setMessage(result.error.message);
    }
  };

  return <IonButton onClick={handleClick}>定期購入する</IonButton>;
};

export default SubscriptionButton;
