import React, { useContext } from 'react';
import {
  createTheme,
  createStyles,
  CssBaseline,
  Container,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { IonButton, IonContent, IonModal, IonLabel } from '@ionic/react';
import MessageList from './MessageList';
import MessageInputField from './MessageInputField';
import firebase, { firestore } from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import Console from '../hooks/console';
import CloseIcon from '@material-ui/icons/Close';

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
// 100vhにしていたのが原因
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '97%',
      gridTemplateRows: '1fr auto',
    },
    fix: {
      [customTheme.breakpoints.down('sm')]: {
        position: 'sticky',
        marginLeft: 350,
        marginTop: 30,
      },
      [customTheme.breakpoints.up('sm')]: {
        position: 'sticky',
        marginLeft: 580,
      },
    },
  }),
);
// showModal→chatShowModal
const Chat = (props: any) => {
  const { isOpen, setChatShowModal, classroomsId, checked } = props;
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  // onDidDismissモーダルが閉じる時にモーダルが閉じる時に発動する関数
  return (
    <IonModal isOpen={isOpen} onWillDismiss={() => setChatShowModal(false)}>
      <CloseIcon
        className={classes.fix}
        onClick={() => setChatShowModal(false)}
      />
      <div className={classes.root}>
        <MessageList classroomsId={classroomsId} uid={currentUser?.uid} />
        <MessageInputField
          classroomsId={classroomsId}
          uid={currentUser?.uid}
          checked={checked}
        />
      </div>
    </IonModal>
  );
};

export default Chat;

// const chatroomsData = {
//   // classroomsId: 'classroomsコレクションのドキュメントID',
//   teacherUid: '',
//   studentsUid: '',
//   messages: [
//     {
//       name: '先生',
//       text: 'こんにちは',
//       date: new Date(),
//     },
//     {
//       name: '生徒',
//       text: 'こんにちは',
//       date: new Date(),
//     },
//     {
//       name: '',
//       text: '',
//       date: new Date(),
//     },
//     {
//       name: '',
//       text: '',
//       date: new Date(),
//     },
//     {
//       name: '',
//       text: '',
//       date: new Date(),
//     },
//   ],
// }
