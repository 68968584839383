import { List, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import Console from '../hooks/console';
import firebase, { firestore } from '../hooks/firebase';
import MessageItem from './MessageItem';

const useStyles = makeStyles(() => ({
  root: {
    gridRow: 1,
    overflow: 'auto',
    width: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

const MessageList = (props: any) => {
  const { classroomsId, uid } = props;
  const [messages, setMessages] = useState<any[]>([]);
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      if (classroomsId) {
        await firebase.getChatroom(classroomsId).onSnapshot(snapshot => {
          if (snapshot.exists) {
            const data: any = snapshot.data();
            Console.log('MessageListのdata', data);
            setMessages(data.messages);
          }
        });
      } else {
        return {};
      }
    })();
  }, []);
  const length = messages.length;

  return (
    <List className={classes.root}>
      {messages.map((message, index, postedBy): any => {
        const isLastItem = length === index + 1;
        return (
          <MessageItem
            key={index}
            message={message}
            isLastItem={isLastItem}
            postedBy={postedBy}
          />
        );
      })}
    </List>
  );
};

export default MessageList;
