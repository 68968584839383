import React from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import { Link } from '@material-ui/core';
import { Copyright } from '@material-ui/icons';
import Header from '../components/Header';

const CancelStripe = () => {
  return (
    <IonPage>
      <Header title="Cancel" />
      <IonContent>
        <IonTitle>
          <IonCard>
            <IonCardContent>
              決済に失敗したか決済が途中で中断されました。
              <br />
              <br />
              決済はされていませんのでご安心ください。
              <br />
              <br />
              プラン画面へ移動してもう一度やり直してください。
              <br />
              <br />
              <Link href="/plans">{'Plansページに戻る'}</Link>
            </IonCardContent>
          </IonCard>
        </IonTitle>
        <img src="./img/success_stripe_background.svg" alt="" />
        <Copyright />
      </IonContent>
    </IonPage>
  );
};

export default CancelStripe;
