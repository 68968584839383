import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  'appBar': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  'toolbar': {
    flexWrap: 'wrap',
  },
  'toolbarTitle': {
    flexGrow: 1,
  },
  'link': {
    margin: theme.spacing(1, 1.5),
  },
  'heroContent': {
    padding: theme.spacing(8, 0, 6),
  },
  'cardHeader': {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#FF9A00'
        : '#FF9A00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '24px'
  },
  'cardPricing': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  'cardOffer': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    color: 'red',
    marginBottom: theme.spacing(2),
  },
  'footer': {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'グループレッスン・ライト',
    subheader: '月2回プラン',
    price: '12,800円',
    priceTaxIncluded: '14,080円(税込)',
    description: [
      '契約期間の縛りなし',
      'いつでも月額コースを解約可能',
      '有効期限内はレッスン受講OK',
    ],
    offer: [
      '　',
      '　',
      '　'
    ]
  },
  {
    title: 'グループレッスン・スタンダード',
    subheader: '月4回プラン',
    price: '19,800円',
    priceTaxIncluded: '21,780円(税込)',
    description: [
      '契約期間の縛りなし',
      'いつでも月額コースを解約可能',
      '有効期限内はレッスン受講OK',
    ],
    offer: [
      'ライトより',
      '23%OFF',
      '(1回あたり)'
    ]
  },
  {
    title: 'グループレッスン・プラス',
    subheader: '月8回プラン',
    price: '32,800円',
    priceTaxIncluded: '36,080円(税込)',
    description: [
      '契約期間の縛りなし',
      'いつでも月額コースを解約可能',
      '有効期限内はレッスン受講OK',
    ],
    offer: [
      'ライトより',
      '36%OFF',
      '(1回あたり)'
    ]
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <div id="pricing" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>料金プラン</h2>
          <p>3種類の料金プランをご用意しております。</p>
          <p>
            トライアルレッスン以外は、サブスクリプション（月額）でのお支払いとなります。
          </p>
          <p>
            ネット回線が早く、防音設備が整っている所でレッスン受講をお願いいたします。
          </p>
          {/* <p>
            サブスクリプションは月額プラン購入日の翌月以降、毎月自動更新されます。
          </p>
          <p>
          　サブスクリプションのご解約は、Langdemyへログイン --> 料金プラン画面から行うことができます。
          </p> */}
        </div>
        <React.Fragment>
          {/* <CssBaseline /> */}
          {/* Hero unit */}
          {/* <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              料金プラン
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              以下、3種類をご用意しております。
            </Typography>
          </Container> */}
          {/* End hero unit */}
          <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
              {tiers.map(tier => (
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={
                    tier.title === 'プロフェッショナルプラン/音大受験プラン'
                      ? 12
                      : 6
                  }
                  md={4}
                >
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h4"
                          color="textPrimary"
                        >
                          {tier.price}
                        </Typography>
                      </div>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h5"
                          variant="h5"
                          color="textPrimary"
                        >
                          {tier.priceTaxIncluded}
                        </Typography>
                      </div>
                      <ul>
                        {tier.description.map(line => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                      <ul className={classes.cardOffer}>
                        {tier.offer.map(line => (
                          <Typography
                            component="li"
                            variant="h5"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </React.Fragment>
      </div>
    </div>
  );
}
