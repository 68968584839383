import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Console from '../hooks/console';
import firebase from '../hooks/firebase';

const MessageField = ({
  inputEl,
  setText,
  text,
  classroomsId,
  uid,
  checked,
}: any) => {
  const [isComposed, setIsComposed] = useState(false);

  return (
    <TextField
      autoFocus
      multiline
      fullWidth={true}
      inputRef={inputEl}
      onChange={e => {
        setText(e.target.value);
      }}
      onKeyDown={async e => {
        if (isComposed) return;
        const target: any = e.target;
        if (target.value === '') return;
        // UI/UX観点でEnter keyの処理は一旦コメントアウト。
        // if (e.key === 'Enter') {
        //   await firebase.pushMessage({ text, classroomsId, uid });
        //   setText('');
        //   e.preventDefault();
        //   if (checked === 1) {
        //     firebase.emailNotification(classroomsId, uid);
        //     // Console.log(checked)
        //   }
        // }
      }}
      onCompositionStart={() => setIsComposed(true)}
      onCompositionEnd={() => setIsComposed(false)}
      value={text}
    />
  );
};

export default MessageField;
