import { Fab, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../hooks/Auth';
import LineAddFriends from './LineAddFriends';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff',
    backgroundColor: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    fontSize: 18,
  },
  linkText: {
    color: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
}));

export const HeaderLandingPage = (props: {
  data: {
    title1: string | undefined;
    title2: string | undefined;
    paragraph: string | undefined;
    buttonText: string | undefined;
  };
}) => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="custom-font">
                  {props.data ? props.data.title1 : 'Loading'}
                  <span></span>
                </h1>
                <h1 className="custom-font">
                  {props.data ? props.data.title2 : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <div className="btn btn--orange btn--circle btn--margin">ご自宅で</div>
                <div className="btn btn--orange btn--circle btn--margin">一流の講師陣</div>
                <div className="btn btn--orange btn--circle btn--margin">入会金0円</div>
                {currentUser ? (
                  <Fab
                    variant="extended"
                    className={classes.button}
                    aria-label="add"
                    href="./teachers"
                    size="large"
                  >
                    講師を探す &gt;{' '}
                  </Fab>
                ) : (
                  <>
                    {/* <Fab
                      variant="extended"
                      className={classes.button}
                      aria-label="add"
                      href="./signup"
                      size="large"
                    >
                      レッスンを予約する &gt;{' '}
                    </Fab>
                    <p className="sub-font">ユーザー登録画面に移動します</p> */}
                  </>
                )}
                <p>{props.data ? props.data.buttonText : 'Loading'}</p>

                {currentUser ? (
                  <p className="sub-font">
                    最短2分でお子さまに適性のあるレッスンが診断できます♪
                    <br />
                    <LineAddFriends />
                    {/* <br />
                    LINE友だち限定の情報配信中 */}
                  </p>
                ) : (
                  <p className="sub-font">
                    最短2分でお子さまに適性のあるレッスンが診断できます♪
                    <br />
                    <LineAddFriends />
                    {/* <br />
                    LINE友だち限定の情報配信中 */}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
