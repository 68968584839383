import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IonContent } from '@ionic/react';
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
} from '@material-ui/core';
import firebase from '../hooks/firebase';
import { initTier } from '../data/planData';
import Console from '../hooks/console';
import { Decipher } from 'crypto';
import { AuthContext } from '../hooks/Auth';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const priceRow = (strPrice: string, percentage: number) => {
  const price = Number(strPrice);
  const tmpReward = price * percentage;
  return tmpReward.toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  });
};

export const PricingTable = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tiers, setTiers] = useState([initTier]);
  // const [reward, setReward] = useState('');
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (currentUser === null) {
        props.history.push('/');
        return;
      }
      if (currentUser?.uid) {
        const uid: string = currentUser?.uid;
        Console.log('uid: ' + uid);
        if (await firebase.isInstructorUid(uid)) {
          const getTiers = await firebase.tiers();
          setTiers(getTiers);
          setOpen(true);
        } else {
          Console.log('あああuid: ' + uid);
          setOpen(false);
          props.history.push('/Teachers');
        }
      }
    })();
  }, [currentUser, props.history]);

  return (
    <div>
      <IonContent>
        {/* <Fab variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}>プロフィールを見る ></Fab> */}
        <Dialog
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          maxWidth={'xl'}
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'委託料テーブル'}
          </DialogTitle>
          <DialogContent>
            <Container>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="medium"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">プラン名</TableCell>
                      <TableCell align="right">価格</TableCell>
                      <TableCell align="right">謝礼(70%)</TableCell>
                      <TableCell align="right">レッスン回数/月</TableCell>
                      {/* <TableCell align="right">プランのタイプ</TableCell>
                      <TableCell align="right">サブスク/1回払い</TableCell>
                      <TableCell align="right">割引</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tiers.map(row => {
                      // setReward(() => priceRow(row.price, 0.7));
                      return (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                          <TableCell align="right">
                            {priceRow(row.price, 0.7)}
                          </TableCell>
                          <TableCell align="right">{row.times}</TableCell>
                          {/* <TableCell align="right">{row.planType}</TableCell>
                          <TableCell align="right">{row.mode}</TableCell>
                          <TableCell align="right">{row.benefit}</TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              閉じる
            </Button>
          </DialogActions> */}
        </Dialog>
      </IonContent>
    </div>
  );
};
