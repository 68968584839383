import React, { useState, useEffect, useContext } from 'react';
import TablePaginationComponent from './TablePaginationComponent';
import { AuthContext } from '../hooks/Auth';
import firebase from '../hooks/firebase';
import moment from 'moment';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import '../pages/Profile.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { cogSharp } from 'ionicons/icons';
import {
  Fab,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
} from '@material-ui/core';
import { IonModal } from '@ionic/react';
import LineAddFriends from './LineAddFriends';
import './AttendedStatus.css';
import CheckoutButton from './CheckoutButton';
import SubscriptionButton from './SubscriptionButton';
import UpdateSubscription from './UpdateSubscription';
import CancelSubscription from './CancelSubscription';

type data = {
  title?: string;
  start?: string;
  end?: any;
  id?: string;
  duration?: any;
  uid?: string;
  courseId?: string;
  teacherUid?: string;
  teacherName?: string;
  status?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lineClose: {
      width: '100',
      margin: 'auto',
    },
    ticketClose: {
      width: '100',
      margin: 'auto',
    },
    center: {
      margin: 'auto',
    },
    ticket: {
      margin: 'auto',
    },
    cardContainer: {
      [theme.breakpoints.down('sm')]: {
        // スマホサイズ
        margin: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        // タブレット, PCサイズ
        margin: theme.spacing(1),
      },
    },
    cardHeader: {
      [theme.breakpoints.down('sm')]: {
        // スマホサイズ
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[700],
      },
      [theme.breakpoints.up('md')]: {
        // タブレット, PCサイズ
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[700],
      },
    },
    cardPricing: {
      [theme.breakpoints.down('sm')]: {
        // スマホサイズ
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
      },
      [theme.breakpoints.up('md')]: {
        // タブレット, PCサイズ
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
      },
    },
    cardButton: {
      [theme.breakpoints.down('sm')]: {
        // スマホサイズ
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        // タブレット, PCサイズ
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

export default function AttendedStatus(props: any) {
  const { teacherUid, currentUser } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [eventList, setEventList] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [hasPurchasedVoiceTraining, setHasPurchasedVoiceTraining] =
    useState(false);
  const [plusVoiceOne, setPlusVoiceOne] = useState<any>([]);
  const now = new Date();
  const classes = useStyles();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomCard = (props: any) => {
    const { tier, mode, currentPlan, history, nextTier, nextPlan } = props;
    const classes = useStyles();
    return (
      <Card className={classes.cardContainer}>
        <CardHeader
          title={tier.title}
          subheader={tier.description}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography component="h2" variant="h3" color="textPrimary">
              ¥{tier.price}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              /月
            </Typography>
          </div>
          <ul>
            {tier.description &&
              tier.description.map((line: any) => (
                <Typography
                  component="li"
                  variant="subtitle1"
                  align="center"
                  key={line}
                >
                  {line}
                </Typography>
              ))}
          </ul>
        </CardContent>
        <CardActions className={classes.cardButton}>
          {mode === 'subscription' ? (
            <SubscriptionButton
              amount={Number(tier.price)}
              priceId={tier.priceId}
              title={tier.title}
              description={tier.description}
              times={tier.times}
              id={tier.id}
              mode={'create'}
            />
          ) : mode === 'payment' ? (
            <CheckoutButton
              amount={Number(tier.price)}
              priceId={tier.priceId}
              title={tier.title}
              description={tier.description}
              times={tier.times}
              id={tier.id}
            />
          ) : mode === 'update' ? (
            <UpdateSubscription
              nextId={nextTier ? nextTier.id : null}
              at={nextPlan ? nextPlan.enrolledAt.toDate() : null}
              amount={Number(tier.price)}
              priceId={tier.priceId}
              title={tier.title}
              description={tier.description}
              times={tier.times}
              id={tier.id}
              firestoreData={currentPlan}
              mode={'update'}
              history={history}
            />
          ) : (
            <CancelSubscription
              courseId={tier.id}
              firestoreData={currentPlan}
              history={history}
            />
          )}
        </CardActions>
      </Card>
    );
  };

  useEffect(() => {
    if (currentUser) {
      const classRoomsData: any[] = [];
      const list: any[] = [];
      (async () => {
        const hasPurchasedVoiceTraining = await firebase
          .getStudent(currentUser?.uid)
          .get()
          .then(snapshot => {
            if (snapshot.exists) {
              const data = snapshot.data();
              return data?.setting?.enrollment?.hasPurchasedVoiceTraining;
            }
          });
        setHasPurchasedVoiceTraining(hasPurchasedVoiceTraining);
        const getTiers = await firebase.tiers();
        const { courseEnrolled, courseIdList } =
          await firebase.getCourseEnrolledData(currentUser?.uid);
        courseIdList.map(async id => {
          const courseData = courseEnrolled[id];
          const courseId = courseData.id;
          const completedAt = courseData.completedAt?.toDate();
          await firebase
            .getCourse(courseId)
            .get()
            .then(snapshot => {
              const data = snapshot?.data();
              const period = data?.tier?.period;
              let price = '';
              if (period === 1) {
                price = '137500';
              } else if (period === 3) {
                price = '130625';
              } else if (period === 6) {
                price = '123750';
              }
              setPlusVoiceOne(
                getTiers.filter(
                  t => t.ticketType === 'addTicket' && t.price === price,
                ),
              );
            });
        });
      })();
      firebase
        .searchClassrooms()
        .get()
        .then(async snapshot => {
          snapshot.forEach(async snapshot => {
            const data = snapshot.data();
            if (
              data.invitees?.instructors[0].path.slice(12) ===
                currentUser?.uid ||
              data.invitees?.students[0].path.slice(9) === currentUser?.uid
            ) {
              classRoomsData.push(data);
            }
          });
          async function createData() {
            await Promise.all(
              classRoomsData.map(async (item: any) => {
                let start: any;
                let end: any;
                let studentUid: any;
                let teacherName = '';
                let instructorUid = '';
                let status = '';
                async function setData(item: any) {
                  await item.invitees?.instructors[0]
                    .get()
                    .then((snapshot: any) => {
                      if (snapshot.exists) {
                        const data = snapshot.data();
                        teacherName = data.profile?.name;
                        instructorUid = data.profile.uid;
                        if (data.profile.uid === currentUser?.uid) {
                          status =
                            data.schedule[`classrooms/${item.id}`]?.status;
                        }
                      }
                    });
                  await item.invitees?.students[0]
                    .get()
                    .then((snapshot: any) => {
                      if (snapshot.exists) {
                        const data = snapshot.data();
                        studentUid = data.profile.uid;
                        if (data.profile.uid === currentUser?.uid) {
                          status =
                            data.schedule[`classrooms/${item.id}`]?.status;
                        }
                      }
                    });
                  if (item.date !== undefined) {
                    start = moment(
                      `${item.date?.year}${item?.date.month}${item?.date.day}${item?.time.start.hour}${item?.time.start.minutes}`,
                      'YYYY-MM-DDTHH:mm',
                    ).format('YYYY-MM-DD HH:mm');

                    end = moment(
                      `${item?.date.year}${item?.date.month}${item?.date.day}${item?.time.end.hour}${item?.time.end.minutes}`,
                      'YYYY-MM-DDTHH:mm',
                    ).format('YYYY-MM-DDTHH:mm');
                  }
                }
                await setData(item);

                let courseTitle: any;
                const tiers = await firebase.tiers();
                tiers.map((tiersItem: any, index: any) => {
                  const customCourseTitle = String(item.courseId).substr(8);

                  if (tiersItem.id === customCourseTitle) {
                    courseTitle = tiersItem.title;
                  }
                });

                let data: data = {
                  title: '',
                  start: '',
                  end: '',
                  teacherName: '',
                  id: '',
                  duration: '',
                  courseId: '',
                  uid: '',
                  status: '',
                };
                async function pushData() {
                  if (
                    studentUid === currentUser?.uid ||
                    instructorUid === teacherUid
                  ) {
                    data = {
                      title: item.title,
                      start: start,
                      end: end,
                      id: item.id,
                      duration: item.duration,
                      courseId: courseTitle,
                      teacherName: teacherName,
                      status: status,
                    };
                    const endTime = new Date(end);
                    if (new Date().getTime() > endTime.getTime()) {
                      list.push(data);
                    }
                    list.sort(function (a: any, b: any) {
                      if (a.start < b.start) {
                        return 1;
                      } else {
                        return -1;
                      }
                    });
                  }
                }
                await pushData();
              }),
            );
          }
          await createData();
          async function setList() {
            setEventList(list);
          }
          await setList();
        });
    }
  }, [teacherUid, currentUser]);

  return (
    <TableContainer component={Paper}>
      <Table className="table" aria-label="custom pagination table">
        <TableHead style={{ width: 'auto' }}>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell align="center">受講コース</TableCell>
            <TableCell align="center">講師</TableCell>
            <TableCell align="center">受講時間</TableCell>
            <TableCell align="right">ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? eventList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : eventList
          ).map((row: any) => (
            <TableRow key={row.id}>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                component="th"
                size="small"
                scope="row"
              >
                {row.title}
              </TableCell>
              <TableCell
                className="tableCell_large add_height_for_tableCell"
                align="center"
              >
                {row.courseId}
                {row.courseId ===
                'トライアルレッスン(ボイトレ) 30分 初回のみ' ? (
                  <>
                    <Fab
                      variant="extended"
                      size="small"
                      className="tableCell_fabButton"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      LINEで個別相談
                    </Fab>
                    {!hasPurchasedVoiceTraining ? (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => setShowModal(false)}
                        cssClass={'custom-class'}
                      >
                        <h1 className={classes.center}>
                          LINEにて「無料個別相談」と
                          <br />
                          送信してください。
                        </h1>
                        <p className={classes.center}>
                          <LineAddFriends />
                        </p>
                        <Button
                          className={classes.lineClose}
                          onClick={() => {
                            setShowModal(false);
                          }}
                          variant="outlined"
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    ) : (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => setShowModal(false)}
                        cssClass={'custom-ticket-class'}
                      >
                        <div className={classes.ticket}>
                          {plusVoiceOne.map((plus: any) => (
                            <>
                              <CustomCard mode="payment" tier={plus} />
                            </>
                          ))}
                        </div>
                        <Button
                          className={classes.ticketClose}
                          onClick={() => {
                            setShowModal(false);
                          }}
                          variant="outlined"
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="center"
              >
                {row.teacherName}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="center"
              >
                {row.start}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="right"
              >
                {row.status === 'present'
                  ? '出席'
                  : row.status === 'canceled'
                  ? '事前キャンセル'
                  : row.status === 'reservation'
                  ? '欠席'
                  : ''}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              {eventList.length === 0 && (
                <TableCell
                  className="tableCell_text_center"
                  colSpan={6}
                  align="center"
                  style={{ verticalAlign: 'center !important' }}
                >
                  予約はありません
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              // colSpan={12}
              count={eventList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'event per page' },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationComponent}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
