import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Console from '../hooks/console';
import { logoGoogle } from 'ionicons/icons';
import firebase from '../hooks/firebase';
import SignUp from './SignUp';
import Copyright from '../components/Copyright';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../hooks/Auth';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Theme,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Header from '../components/Header';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff',
    backgroundColor: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
  googleIcon: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff',
    backgroundColor: '#4285F4',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
  linkText: {
    color: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
}));

const validateEmail = (email: string) => {
  let errorType = null;
  const isEmail = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  );

  // 未入力エラー
  if (!email) {
    errorType = 'empty';
    // メールアドレス形式エラー
  } else if (!isEmail.test(email)) {
    errorType = 'invalid';
  }
  return errorType;
};

const validatePassword = (password: string) => {
  let errorType = null;
  const isFullWidthCharacter = new RegExp(/[^\x01-\x7E]/);

  // 未入力エラー
  if (!password) {
    errorType = 'empty';
    // 半角英数記号・桁数エラー
  } else if (
    (password.length <= 5 && password.length >= 1) ||
    isFullWidthCharacter.test(password)
  ) {
    errorType = 'invalid';
  }
  return errorType;
};

const Login = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isComposed, setIsComposed] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const showToast = () => {
    setToastIsShown(true);
  };
  const [message, setMessage] = useState('');

  useEffect(() => {
    const disabled = email === '' || password === '';
    setDisabled(disabled);
    // if logged in, redirect to home
    // currentUser && props.history.push('Teachers');
  }, [email, password, currentUser, props.history]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <IonPage>
      <Header title="ログイン" />
      <IonContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
            <Typography component="h1" variant="h5">
              ランデミーこどもの音楽教室へようこそ
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={(e: any) => {
                  if (isComposed) return;

                  if (e.key === 'Enter') {
                    setEmail(e.target.value);
                    e.preventDefault();
                  }
                }}
                onCompositionStart={() => setIsComposed(true)}
                onCompositionEnd={() => setIsComposed(false)}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="password">パスワード</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  inputProps={{
                    type: showPassword ? 'text' : 'password',
                    autoComplete: 'no',
                  }}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                    e.preventDefault();
                  }}
                  onKeyDown={(e: any) => {
                    if (isComposed) return;

                    if (e.key === 'Enter') {
                      setPassword(e.target.value);
                      e.preventDefault();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={disabled}
                onClick={e => {
                  switch (validateEmail(email)) {
                    case 'empty':
                      setMessage('Emailを入力してください。');
                      setToastIsShown(true);
                      return;
                    case 'invalid':
                      setMessage(
                        'Emailに不正な文字が入っているため、ログインできません。Emailを修正してください。',
                      );
                      setToastIsShown(true);
                      return;
                    default:
                      break;
                  }

                  switch (validatePassword(password)) {
                    case 'empty':
                      setMessage('パスワードを入力してください。');
                      setToastIsShown(true);
                      return;
                    case 'invalid':
                      setMessage(
                        'パスワードが6桁以上ではないか、もしくは不正な文字が入っているため、登録できません。パスワードを修正してください。',
                      );
                      setToastIsShown(true);
                      return;
                    default:
                      break;
                  }

                  let user;
                  firebase
                    .login(email, password)
                    .then(async (result: any) => {
                      user = result.user;
                      if (await firebase.userExists(user)) {
                        firebase
                          .updateFirestoreUser(user)
                          .then(() => { })
                          .catch(e => { });
                      }

                      if (!user?.emailVerified) {
                        // 確認Emailを送信
                        firebase.verifyEmail(email).then(() => {
                          // Email sent.
                          setMessage(
                            'Emailの認証が完了してない為、Emailをお送りしました。受信箱をご確認ください。',
                          );
                          setToastIsShown(true);
                        });
                      }
                      props.history.push('Teachers');
                    })
                    .catch(e => {
                      switch (e.code) {
                        case 'auth/user-not-found':
                        case 'auth/wrong-password':
                          setMessage(
                            'Emailかパスワードに誤りがあるか、もしくはユーザーが登録されていません。',
                          );
                          setToastIsShown(true);
                          break;
                        case 'auth/too-many-requests':
                          setMessage(
                            'アカウントがロックされました。ロック解除するには「パスワードをお忘れの方はこちら」より、パスワードをリセットしてください。',
                          );
                          setToastIsShown(true);
                          break;
                        case 'auth/invalid-email':
                          setMessage('Emailの形式が正しくありません。');
                          setToastIsShown(true);
                          break;
                        case 'auth/network-request-failed':
                          setMessage(
                            'ネットワークに接続してください。機内モード等になっているかWi-Fi等に繋がっていない可能性があります。',
                          );
                          setToastIsShown(true);
                          break;
                        default:
                          setMessage(
                            '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                          );
                          setToastIsShown(true);
                          break;
                      }
                    });
                }}
              >
                ログイン
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="/password-remind"
                    variant="body1"
                    className={classes.linkText}
                  >
                    {'パスワードをお忘れの方はこちら'}
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="/signup"
                    variant="body1"
                    className={classes.linkText}
                  >
                    {'ユーザ登録はこちら'}
                  </Link>
                </Grid>
              </Grid>
            </form>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<IonIcon ios={logoGoogle} md={logoGoogle} />}
              className={classes.googleIcon}
              onClick={async () => {
                await firebase
                  .googleLogin()
                  .then(async user => {
                    if (await firebase.userExists(user)) {
                      await firebase
                        .updateFirestoreUser(user)
                        .then(() => {
                          props.history.push('/Teachers');
                        })
                        .catch(e =>
                          Console.error('error in updateFirestoreUser ' + e),
                        );
                    } else {
                      await firebase
                        .setFirestoreUser(user)
                        .then(() => {
                          props.history.push('/Teachers');
                        })
                        .catch(e =>
                          Console.error('error in setFirestoreUser ' + e),
                        );
                    }
                  })
                  .catch(e => Console.error('error in googleLogin ' + e));
              }}
            >
              Google ログイン
            </Button>
            <IonToast
              isOpen={toastIsShown}
              onDidDismiss={() => setToastIsShown(false)}
              message={message}
              duration={5000}
            />
          </div>
        </Container>
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <Box mt={8}>
              <Copyright />
            </Box>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Login;
