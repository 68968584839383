import React from 'react';

/* eslint-disable react/react-in-jsx-scope */
export const Services = (props: { data: any[] }) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>サービス</h2>
          <p>プロの講師による音楽教室を提供</p>
          <p>ユーザー登録をすることで、以下の機能が利用可能</p>
          {/* <p>いつでもご契約コースを解約OK</p> */}
        </div>
        <div className="row">
          {props.data
            ? props.data.map(
                (
                  d: {
                    name:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                    icon: string | undefined;
                    text:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  i: any,
                ) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {' '}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ),
              )
            : 'loading'}
        </div>
      </div>
    </div>
  );
};
