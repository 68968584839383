import React, { useContext, useEffect } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import { Link } from '@material-ui/core';
import Copyright from '../components/Copyright';
import Header from '../components/Header';

const SuccessStripe = () => {
  return (
    <IonPage>
      <Header title="Thank you" />
      <IonContent>
        <IonTitle>
          <IonCard>
            <IonCardContent>
              ご購入いただき、誠にありがとうございます。
              <br />
              レッスンをご受講できます。
              <br />
              <br />
              有効期間は本日より有効期限内までとなります。
              <br />
              講師検索より、お好きな講師をお選びいただき、
              <br />
              レッスンをご予約ください。
              <br />
              <br />
              <Link href="/teachers">{'講師検索へ進む'}</Link>
            </IonCardContent>
          </IonCard>
        </IonTitle>
        <img src="./img/success_stripe_background.svg" alt="" />
        <Copyright />
      </IonContent>
    </IonPage>
  );
};

export default SuccessStripe;
