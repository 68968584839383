import React, { useState, useContext, useEffect } from 'react';
import { CreateAvailabilityContext } from './CreateAvailability';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const CreateWorkingTimeDecideModal: React.FC = () => {
  const { startTime, endTime, instructorTitle, submitFunc, decideModalClose } =
    useContext(CreateAvailabilityContext);

  const close = () => {
    decideModalClose();
  };

  const clickFunction = async () => {
    if (new Date(startTime).getHours() === 21) {
      alert('この時間には設定できません。');
    }
    const str =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let uniqueId = '';
    // 桁数の定義
    const len = 20;
    for (let i = 0; i < len; i++) {
      uniqueId += str.charAt(Math.floor(Math.random() * str.length));
    }
    const now = new Date();
    if (moment(now).isSameOrBefore(startTime)) {
      const setData = {
        id: uniqueId,
        startTime: startTime,
        endTime: endTime,
        instructorTitle: instructorTitle,
      };
      submitFunc(setData);
    }
    decideModalClose();
  };

  return (
    <div className="modalbodywrapper">
      <div className="editmodalbody">
        <div className="icon-box">
          <Tooltip title="Close">
            <IconButton aria-label="close" onClick={close}>
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <p className="text-center">講師：{instructorTitle}</p>
          <p className="margin-top-small text-center">
            稼働開始時間: {moment(startTime).format('YYYY-MM-DD HH:mm')}
          </p>
          <p className="margin-top-small text-center">
            稼働終了時間: {moment(endTime).format('YYYY-MM-DD HH:mm')}
          </p>
          <div className="margin-top-small text-align">
            <div className="button">
              <Button variant="outlined" onClick={clickFunction}>
                決定
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWorkingTimeDecideModal;
