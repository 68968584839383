import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Navigation } from '../components/Navigation';
import { HeaderLandingPage } from '../components/HeaderLandingPage';
import { Services } from '../components/Services';
import { Contact } from '../components/Contact';
import JsonData from '../data/data.json';
import SmoothScroll from 'smooth-scroll';
import { Teacher } from '../components/Teacher';
import Faq from '../components/Faq';
import Pricing from '../components/Pricing';
import Instructions from '../components/Instructions';
import Copyright from '../components/Copyright';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const About = () => {
  const [landingPageData, setLandingPageData] = useState<any>({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <IonPage>
      {/* <Header /> */}
      <IonContent>
        <div>
          <Navigation />
          <HeaderLandingPage data={landingPageData.HeaderLandingPage} />
          <Teacher data={landingPageData.Teacher} />
          <Services data={landingPageData.Services} />
          {/* <Features data={landingPageData.Features} />
          <AboutLangdemy data={landingPageData.AboutLangdemy} /> */}
          {/* <Gallery /> */}
          {/* <Testimonials data={landingPageData.Testimonials} /> */}
          {/* <Team data={landingPageData.Team} /> */}
          <Instructions />
          <Pricing />
          <Faq />
          {/* <Contact
            imgData={landingPageData.Team}
            data={landingPageData.Contact}
          /> */}
          <div id="footer">
            <div className="container text-center">
              <p>
                <Copyright />
              </p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
