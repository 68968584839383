import { makeStyles, TextField, Theme } from '@material-ui/core';
import { IonButton, IonContent, IonModal, IonLabel } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import firebase from '../hooks/firebase';
import Console from "../hooks/console";

type modalProps = {
  yesFunc: Function;
  NoFunc: Function;
  uid: string;
  setToastIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  provider: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  text: {
    color: '#cc1f00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    fontSize: '24px',
  },
}));

const DeleteUserModal: React.FC<modalProps> = props => {
  const { yesFunc, NoFunc, uid, setMessage, setToastIsShown, provider } = props;
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isComposed, setIsComposed] = useState(false);

  useEffect(() => {
    const disabled = email === '' || password === '';
    setDisabled(disabled);
  }, [email, password]);

  return (
    <IonModal isOpen={true}>
      <IonContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <IonLabel className={classes.text}>
              アカウントを削除される場合は、もう一度ログインしてください。
              <br />
              既に月額プランをご契約されている場合、月額プランは自動的に解約されますが、
              <br />
              残りのチケットのデータも削除され、日割り計算は行われないことをご了承ください。
            </IonLabel>
            {provider !== "google.com" && (
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoFocus
                  onChange={(e: any) => setEmail(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (isComposed) return;
                    if (e.key === 'Enter') {
                      setEmail(e.target.value);
                      e.preventDefault();
                    }
                  }}
                  onCompositionStart={() => setIsComposed(true)}
                  onCompositionEnd={() => setIsComposed(false)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (isComposed) return;
                    if (e.key === 'Enter') {
                      setPassword(e.target.value);
                      e.preventDefault();
                    }
                  }}
                />
                <IonButton
                  type="button"
                  disabled={disabled}
                  onClick={e => {
                    firebase
                      .login(email, password)
                      .then(async () => {
                        const dbEmail: any = await firebase.getStudent(uid).get().then(snapshot => {
                          if (snapshot.exists) {
                            const data = snapshot.data() || {};
                            return data.contacts.email;
                          } else {
                            Console.log("uid data not found");
                          }
                        }).catch(e => Console.error("uid get error: " + e));
                        if (email === dbEmail) {
                          yesFunc()
                        } else {
                          setMessage('Emailかパスワードに誤りがあるか、もしくはユーザーが登録されていません。');
                          setToastIsShown(true);
                        }
                      })
                      .catch(e => {
                        switch (e.code) {
                          case 'auth/user-not-found':
                          case 'auth/wrong-password':
                            setMessage(
                              'Emailかパスワードに誤りがあるか、もしくはユーザーが登録されていません。',
                            );
                            setToastIsShown(true);
                            break;
                          case 'auth/too-many-requests':
                            setMessage(
                              'アカウントがロックされました。ロック解除するには「パスワードをお忘れの方はこちら」より、パスワードをリセットしてください。',
                            );
                            setToastIsShown(true);
                            break;
                          case 'auth/invalid-email':
                            setMessage('Emailの形式が正しくありません。');
                            setToastIsShown(true);
                            break;
                          case 'auth/network-request-failed':
                            setMessage(
                              'ネットワークに接続してください。機内モード等になっているかWi-Fi等に繋がっていない可能性があります。',
                            );
                            setToastIsShown(true);
                            break;
                          default:
                            setMessage(
                              '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                            );
                            setToastIsShown(true);
                            break;
                        }
                      });
                  }}
                >
                  アカウント削除
              </IonButton>
                <IonButton type="button" onClick={() => NoFunc()}>
                  キャンセル
              </IonButton>
              </form>
            )}
            {provider !== "password" && (
              <IonButton
                expand="full"
                onClick={async () => {
                  await firebase
                    .googleLogin()
                    .then(async (user: any) => {
                      const dbEmail: any = await firebase.getStudent(uid).get().then(snapshot => {
                        if (snapshot.exists) {
                          const data = snapshot.data() || {};
                          return data.contacts.email;
                        } else {
                          Console.log("uid data not found");
                        }
                      }).catch(e => Console.error("uid get error: " + e));
                      Console.log(`if(${user.email} === ${dbEmail})`);
                      if (user.email === dbEmail) {
                        yesFunc();
                      } else {
                        setMessage('Emailかパスワードに誤りがあるか、もしくはユーザーが登録されていません。');
                        setToastIsShown(true);
                      }
                    }
                    )
                    .catch(e => Console.error("googlelogin error: " + e));
                }}
              >
                Googleログインしてアカウントを削除
              </IonButton>
            )}
          </div>
        </Container>
      </IonContent>
    </IonModal>
  );
};

export default DeleteUserModal;
