import React, { useState, useEffect, useContext, createContext } from 'react';
import TablePaginationComponent from './TablePaginationComponent';
import '../pages/Profile.css';
import { AuthContext } from '../hooks/Auth';
import Console from '../hooks/console';
import firebase, { firestore, fieldValue } from '../hooks/firebase';
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { Modal } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import EditedModal from './EditedModal';
import LessonCancel from './LessonCancel';
import { IonToast } from '@ionic/react';
import EditModal from './EditModal';
import Chat from './Chat';

export const ReservationStatusContext = createContext<any>({});

type data = {
  title?: string;
  start?: string | Date;
  end?: any;
  id?: string;
  uid?: string;
  duration?: any;
  courseId?: string;
  teacherUid?: string;
  studentUid?: string;
  teacherName?: string;
  isClick?: boolean;
  start_url?: string;
  join_url?: string;
  connectId?: string;
  productId?: string;
  isOnline?: string;
};

const eventChange = async (start: any, end: any, id: any, duration: any) => {
  const dateObj = initDate(start, end);
  firebase.getClassroom(String(id)).update({
    ...dateObj,
    duration: duration,
  });
};

const initDate = (start: moment.MomentInput, end: moment.MomentInput) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return {
    date: {
      year: startMoment.format('YYYY'),
      month: startMoment.format('MM'),
      day: startMoment.format('DD'),
    },
    time: {
      start: {
        hour: startMoment.format('HH'),
        minutes: startMoment.format('mm'),
      },
      end: {
        hour: endMoment.format('HH'),
        minutes: endMoment.format('mm'),
      },
    },
  };
};

const runDelete = async (id: string) => {
  try {
    await firebase.getClassroom(String(id)).delete();
  } catch (err) {}
};

const ReservationStatus = (props: any) => {
  const {
    setOpenLessonReady,
    setIsOnline,
    setClassroomData,
    currentUser,
    checked,
    eventList,
    setEventList
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false); //予定を変更・削除するモーダルを制御する値です
  const [editModalJudgementNum, setEditModalJudgementNum] = useState<number>(0); //予定がクリックされた時のモーダルを管理する値です。1に変更された時に、編集画面に遷移します。
  const [decidedData, setDecidedData] = useState<any>({}); //予定を変更・削除するモーダルに表示する値です
  const [id, setId] = useState('');
  const [teacherSchedule, setTeacherSchedule] = useState<any>();
  const [message, setMessage] = useState('');
  const [toastIsShown, setToastIsShown] = useState(false);
  const [chatShowModal, setChatShowModal] = useState(false);
  // const modal = () => {
  //   if (showModal) return <Chat isOpen={showModal} />;
  //   else return <></>;
  // };
  const [classroomsId, setClassroomsId] = useState('');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  // dateが今日と同じ日付または今日よりも前の日付ならtrue, dateが後の日付ならfalse
  const beforeToday = (date: Date) => {
    const now = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    const today = now.getDate();

    if (year == thisYear) {
      if (month == thisMonth) {
        return day <= today;
      } else {
        return month < thisMonth;
      }
    } else {
      return year < thisYear;
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //予定を変更・削除するモーダルを開ける関数です
  const editModalOpen = (data: any) => {
    setEditModalIsOpen(true);
    setDecidedData(data);
    setId(data.id);
  };

  //予定を変更・削除するモーダルを閉める関数です
  const editModalClose = () => {
    setEditModalIsOpen(false);
    setEditModalJudgementNum(0);
  };

  //発火時に編集画面に遷移します
  const moveEdited = () => {
    setEditModalJudgementNum(1);
  };

  //子要素からデータを受け取り、変換したデータをカレンダーに反映、Firestoreに送信する関数です
  const changeFunc = (item: any) => {
    let isClick = true;
    if (moment(new Date()).isSame(item.start, 'day')) {
      isClick = false;
    }

    let changedEventData = eventList.filter((data: any) => data.id !== item.id);
    const data: data = {
      title: item.title,
      start: moment(item.start).format('YYYY-MM-DD HH:mm'),
      end: item.end,
      id: item.id,
      // duration: item.duration,
      uid: currentUser?.uid,
      teacherUid: item.teacherUid,
      teacherName: item.teacherName,
      courseId: item.courseId,
      duration: item.duration,
      isClick: isClick,
    };
    changedEventData.push(data);
    changedEventData.sort(function (a: any, b: any) {
      if (a.start < b.start) {
        return -1;
      } else {
        return 1;
      }
    });
    setEventList(changedEventData);
    eventChange(item.start, item.end, item.id, item.duration);
  };

  const sendMail = async (
    to: string,
    recipient_name: string,
    templateId: string,
  ) => {
    const emailData = {
      to: to,
      from: 'support@langdemy.com',
      cc: '',
      bcc: '',
      replyTo: recipient_name,
      templateId: templateId,
      dynamic_template_data: '',
    };

    firebase.sendEmail(emailData);
  };

  const cancelFirestoreData = async (id: string, uid: string | undefined) => {
    const { courseEnrolled } = await firebase.getCourseEnrolledData(uid);
    const classroomData: any = await firebase
      .getClassroom(id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          const data: any = snapshot.data();
          const id: string = data.courseId;
          return snapshot.data();
        } else {
          Console.log('classrooms data not found');
          return '';
        }
      })
      .catch(e => Console.error(e));
    const courseId = classroomData.courseId.slice(8);
    const batch = firestore.batch();
    const studentRef = firebase.getStudent(uid);
    const instructorRef = classroomData.invitees.instructors[0];
    const classroomKey = `classrooms/${id}`;
    const courseKey = `courses/${courseId}`;
    const courseData = courseEnrolled[courseKey];
    const isOnline = classroomData?.location?.online;
    let processCourseData = {};
    if (isOnline === 'No') {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(+1),
        offlineNumberOfClasses: fieldValue.increment(+1),
      };
    } else if (isOnline === 'Yes') {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(+1),
      };
    }

    const studentSchedule = {
      courseEnrolled: {
        ...courseEnrolled,
        [courseKey]: processCourseData,
      },
      schedule: {
        [classroomKey]: {
          status: 'canceled',
        },
      },
    };

    const instructorSchedule = {
      schedule: {
        [classroomKey]: { status: 'canceled' },
      },
    };

    batch.set(studentRef, studentSchedule, { merge: true }); // 生徒のスケジュールを更新
    batch.set(instructorRef, instructorSchedule, { merge: true }); // 講師のスケジュールを更新
    batch
      .commit()
      .then(async () => {
        setMessage('Emailをお送りしました。受信箱をご確認ください。');

        // 生徒へレッスン予約キャンセルEmailを送信する
        const studentInfo = await studentRef.get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
        });
        Console.dir('studentInfo' + JSON.stringify(studentInfo));
        const studentName = studentInfo?.profile?.name;
        const studentEmail = studentInfo?.contacts?.email;
        sendMail(
          studentEmail,
          studentName,
          'd-8d6663bfa1044a2eac26e4e20920d62d',
        );

        // 講師へレッスン予約キャンセルEmailを送信する
        const instructorInfo = await instructorRef
          .get()
          .then((snapshot: any) => {
            if (snapshot.exists) {
              return snapshot.data();
            }
          });
        Console.dir('instructorInfo' + JSON.stringify(instructorInfo));
        const instructorName = instructorInfo?.profile?.name;
        const instructorEmail = instructorInfo?.contacts?.email;
        sendMail(
          instructorEmail,
          instructorName,
          'd-b9021df325054da6a1b903a7af928e7a',
        );

        setToastIsShown(true);
      })
      .catch(err => {
        Console.error(err);
      });
    await runDelete(id);
  };

  //子要素から送られてくる子要素のidを受け取り、子要素を除いたデータを返し、子要素から送られてきたidを持つデータをFireStoreから消去する関数です
  const deleteFunc = () => {
    const data = eventList.filter((item: any) => item.id !== id);
    setEventList(data);
    cancelFirestoreData(id, currentUser?.uid);
  };


  const lessonReady = (data: any) => {
    setClassroomData(data);
    setOpenLessonReady(true);
    setIsOnline(data?.isOnline);
  };

  return (
    <React.Fragment>
      <ReservationStatusContext.Provider
        value={{
          editModalJudgementNum,
          decidedData,
          eventList,
          moveEdited,
          editModalClose,
          changeFunc,
          deleteFunc,
        }}
      >
        <Modal open={editModalIsOpen} onClose={editModalClose}>
          <div>
            {editModalJudgementNum === 0 ? (
              <LessonCancel
                decidedData={decidedData}
                editModalClose={editModalClose}
                deleteFunc={deleteFunc}
                isEdited={false}
              />
            ) : (
              <EditedModal
                decidedData={decidedData}
                changeFunc={changeFunc}
                editModalClose={editModalClose}
                eventList={eventList}
                editId={id}
                useAvailabilityTime
              />
            )}
          </div>
        </Modal>
      </ReservationStatusContext.Provider>
      <TableContainer component={Paper}>
        <Table className="table" aria-label="custom pagination table">
          <TableHead style={{ width: 'auto' }}>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              <TableCell>生徒</TableCell>
              <TableCell align="center">受講コース</TableCell>
              <TableCell align="center">講師</TableCell>
              <TableCell align="right">受講開始時間</TableCell>
              <TableCell align="right">予約キャンセル</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? eventList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : eventList
            ).map((row: any) => (
              <TableRow key={row.id}>
                {/* <TableCell padding="checkbox">
                  <Tooltip title="Create">
                    <IconButton
                      aria-label="create"
                      onClick={() => editModalOpen(row)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell> */}
                <TableCell
                  component="th"
                  scope="row"
                  className="tableCell_noraml"
                >
                  {row.title}
                </TableCell>
                <TableCell align="center" className="tableCell_noraml">
                  <div className="tableCell_minWidth flex flex_center">
                    <p>{row.courseId}</p>
                    {row?.isOnline === 'Yes' ? (
                      <Fab
                        disabled={row.isClick}
                        variant="extended"
                        size="small"
                        className={
                          !row.isClick
                            ? 'tableCell_fabButton'
                            : 'tableCell_disabled_fabButton'
                        }
                        onClick={() => lessonReady(row)}
                      >
                        レッスン開始
                      </Fab>
                    ) : (
                      <Fab
                        disabled={row.isClick}
                        variant="extended"
                        size="small"
                        className={
                          !row.isClick
                            ? 'tableCell_fabButton'
                            : 'tableCell_disabled_fabButton'
                        }
                        onClick={() => lessonReady(row)}
                      >
                        対面レッスン開始
                      </Fab>
                    )}
                    <Fab
                      variant="extended"
                      size="small"
                      className="tableCell_fabButton"
                      onClick={() => {
                        setChatShowModal(true);
                        setClassroomsId(row.id);
                      }}
                    >
                      チャット開始
                    </Fab>
                  </div>
                </TableCell>
                <TableCell align="center" className="tableCell_noraml">
                  {row.teacherName}
                </TableCell>
                <TableCell align="right" className="tableCell_noraml">
                  {row.start} ({row.duration}分)
                </TableCell>
                <TableCell align="right" className="tableCell_noraml">
                  <Fab
                    disabled={beforeToday(new Date(row.start))}
                    variant="extended"
                    size="small"
                    className={
                      !beforeToday(new Date(row.start))
                        ? 'tableCell_fabButtonRed'
                        : 'tableCell_disabled_fabButton'
                    }
                    onClick={() => editModalOpen(row)}
                  >
                    キャンセル
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
            <Chat
              classroomsId={classroomsId}
              isOpen={chatShowModal}
              setChatShowModal={setChatShowModal}
              checked={checked}
            />
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                {eventList.length === 0 && (
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ verticalAlign: 'center !important' }}
                  >
                    予約はありません
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                // colSpan={12}
                count={eventList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'event per page' },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationComponent}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <IonToast
        isOpen={toastIsShown}
        onDidDismiss={() => setToastIsShown(false)}
        message={message}
        duration={7000}
      />
    </React.Fragment>
  );
};

export default withRouter(ReservationStatus);
