import React, { useState, useEffect, useContext, createContext } from 'react';
// import TeacherEditModalJudgement from './_OLD_TeacherEditModalJudgement';
import TablePaginationComponent from './TablePaginationComponent';
// import { eventChange, changeStatus } from '../hooks/_OLD_calendar';
import '../pages/Profile.css';
import { AuthContext } from '../hooks/Auth';
import firebase, { firestore } from '../hooks/firebase';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { Fab, Modal } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import TeacherEditedModal from './_OLD_TeacherEditedModal';
// import TeacherEditModal from './_OLD_TeacherEditModal';
import EditedModal from './EditedModal';
import EditModal from './EditModal';
import Chat from './Chat';
import Console from '../hooks/console';

export const ReservationStatusContext = createContext<any>({});

type data = {
  title?: string;
  start?: string | Date;
  end?: any;
  id?: string;
  uid?: string;
  duration?: any;
  courseTitle?: string;
  studentUid?: string;
  studentName?: string;
  teacherUid?: any;
  teacherName?: any;
  isClick?: boolean;
  isOnline?: string;
};

const changeStatus = async (
  id: string,
  uid: string | undefined,
  teacherUid: string | undefined,
) => {
  await runDelete(id);
  const batch = firestore.batch();
  const classroomsId = `classrooms/${id}`;
  const studentsRef = firebase.getStudent(uid);
  const instructorRef = firebase.getInstructor(teacherUid);
  const userInfo = await studentsRef.get().then(snapshot => {
    if (snapshot.exists) {
      return snapshot.data();
    }
  });

  const schedule = userInfo?.schedule;
  const pushSchedule = {
    schedule: {
      ...schedule,
      [classroomsId]: { status: 'canceled' },
    },
  };
  batch.update(studentsRef, pushSchedule); // 生徒のスケジュールを更新
  batch.update(instructorRef, pushSchedule); // 講師のスケジュールを更新
  batch
    .commit()
    .then(() => {})
    .catch(err => {});
};

const eventChange = async (start: any, end: any, id: any, duration: any) => {
  const dateObj = initDate(start, end);
  firebase.getClassroom(String(id)).update({
    ...dateObj,
    duration: duration,
  });
};

const initDate = (start: moment.MomentInput, end: moment.MomentInput) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return {
    date: {
      year: startMoment.format('YYYY'),
      month: startMoment.format('MM'),
      day: startMoment.format('DD'),
    },
    time: {
      start: {
        hour: startMoment.format('HH'),
        minutes: startMoment.format('mm'),
      },
      end: {
        hour: endMoment.format('HH'),
        minutes: endMoment.format('mm'),
      },
    },
  };
};

const runDelete = async (id: string) => {
  try {
    await firebase.getClassroom(String(id)).delete();
  } catch (err) {}
};

const TeacherReservationStatus = (props: any) => {
  const {
    teacherUid,
    setOpenLessonReady,
    setClassroomData,
    currentUser,
    checked,
    setIsOnline,
    teacherEventList,
    setTeacherEventList,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false); //予定を変更・削除するモーダルを制御する値です
  const [editModalJudgementNum, setEditModalJudgementNum] = useState<number>(0); //予定がクリックされた時のモーダルを管理する値です。1に変更された時に、編集画面に遷移します。
  const [teacherDecidedData, setDecidedData] = useState<any>({}); //予定を変更・削除するモーダルに表示する値です
  const [chatShowModal, setChatShowModal] = useState(false);
  const [id, setId] = useState('');
  const [classroomsId, setClassroomsId] = useState('');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const goToLessonReady = (data: any) => {
    setOpenLessonReady(true);
    setClassroomData(data);
    setIsOnline(data?.isOnline);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, teacherEventList.length - page * rowsPerPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //予定を変更・削除するモーダルを開ける関数です
  const editModalOpen = (data: any) => {
    setEditModalIsOpen(true);
    setDecidedData(data);
    setId(data.id);
  };

  //予定を変更・削除するモーダルを閉める関数です
  const teacherEditModalClose = () => {
    setEditModalIsOpen(false);
    setEditModalJudgementNum(0);
  };

  //発火時に編集画面に遷移します
  const teacherMoveEdited = () => {
    setEditModalJudgementNum(1);
  };

  //子要素からデータを受け取り、変換したデータをカレンダーに反映、Firestoreに送信する関数です
  const changeFunc = (item: any) => {
    let changedEventData = teacherEventList.filter(
      (data: any) => data.id !== item.id,
    );
    const data: data = {
      title: item.title,
      start: moment(item.start).format('YYYY-MM-DD HH:mm'),
      end: item.end,
      id: item.id,
      // duration: item.duration,
      uid: currentUser?.uid,
      teacherUid: item.teacherUid,
      teacherName: item.teacherName,
      courseTitle: item.courseTitle,
      duration: item.duration,
    };
    changedEventData.push(data);
    setTeacherEventList(changedEventData);
    eventChange(item.start, item.end, item.id, item.duration);
  };

  //子要素から送られてくる子要素のidを受け取り、子要素を除いたデータを返し、子要素から送られてきたidを持つデータをFireStoreから消去する関数です
  const teacherDeleteFunc = (sentStudentUid: any) => {
    const data = teacherEventList.filter((item: any) => item.id !== id);
    setTeacherEventList(data);
    const teacherUid = currentUser?.uid;
    changeStatus(id, sentStudentUid, teacherUid);
  };

  return (
    <>
      {Console.dir(teacherEventList)}
      <Modal open={editModalIsOpen} onClose={teacherEditModalClose}>
        <div>
          {editModalJudgementNum === 0 ? (
            <EditModal
              decidedData={teacherDecidedData}
              editModalClose={teacherEditModalClose}
              deleteFunc={teacherDeleteFunc}
              isEdited={false}
            />
          ) : (
            <EditedModal
              decidedData={teacherDecidedData}
              changeFunc={changeFunc}
              editModalClose={teacherEditModalClose}
              eventList={teacherEventList}
              editId={id}
              useAvailabilityTime
            />
          )}
        </div>
      </Modal>
      <TableContainer component={Paper}>
        <Table className="table" aria-label="custom pagination table">
          <TableHead style={{ width: 'auto' }}>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              {/* <TableCell>名前</TableCell> */}
              <TableCell align="left">生徒</TableCell>
              <TableCell align="center">受講コース</TableCell>
              <TableCell align="right">受講開始時間</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? teacherEventList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : teacherEventList
            ).map((row: any) => (
              <TableRow key={row.id}>
                {
                  Console.log('row: ' + row)
                  /* <TableCell padding="checkbox">
                  <Tooltip title="Create">
                    <IconButton
                      aria-label="create"
                      onClick={() => editModalOpen(row)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell> */
                }
                {/* <TableCell
                  component="th"
                  scope="row"
                  className="tableCell_noraml"
                >
                  {row.title}
                </TableCell> */}
                <TableCell align="left" className="tableCell_noraml">
                  {row.title}
                </TableCell>
                <TableCell className="tableCell_noraml">
                  <div className="tableCell_minWidth flex flex_center">
                    <p>{row.courseTitle}</p>
                    {row?.isOnline === 'Yes' ? (
                      <Fab
                        disabled={row.isClick}
                        variant="extended"
                        size="small"
                        className={
                          !row.isClick
                            ? 'tableCell_fabButton'
                            : 'tableCell_disabled_fabButton'
                        }
                        onClick={() => goToLessonReady(row)}
                      >
                        レッスン開始
                      </Fab>
                    ) : (
                      <Fab
                        disabled={row.isClick}
                        variant="extended"
                        size="small"
                        className={
                          !row.isClick
                            ? 'tableCell_fabButton'
                            : 'tableCell_disabled_fabButton'
                        }
                        onClick={() => goToLessonReady(row)}
                      >
                        対面レッスン開始
                      </Fab>
                    )}
                    <Fab
                      variant="extended"
                      size="small"
                      className="tableCell_fabButton"
                      onClick={() => {
                        setChatShowModal(true);
                        setClassroomsId(row.id);
                      }}
                    >
                      チャット開始
                    </Fab>
                  </div>
                </TableCell>
                <TableCell align="right" className="tableCell_noraml">
                  {row.start} ({row.duration}分)
                </TableCell>
              </TableRow>
            ))}
            <Chat
              classroomsId={classroomsId}
              isOpen={chatShowModal}
              setChatShowModal={setChatShowModal}
              checked={checked}
            />
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                {teacherEventList.length === 0 && (
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ verticalAlign: 'center !important' }}
                  >
                    予約はありません
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                // colSpan={12}
                count={teacherEventList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'event per page' },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationComponent}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default withRouter(TeacherReservationStatus);
