import React from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(4),
    },
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
    },
  },
  cancelTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
  },
  cardLabel: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(1),
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
  },
  cardPricing: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
  },
  cardButton: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      margin: theme.spacing(1),
    },
  },
  dividerComponent: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
    },
  },
  divider: {
    border: 'none',
    borderTop: 'dotted 1px #000000',
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
  },
  modalWidth: {
    width: '900px',
  },
  center: {
    margin: 'auto',
  },
  modalTicket: {
    margin: 'auto',
  },
  close: {
    margin: 'auto',
    width: '100',
  },
}));

const PlanStatus = props => {
  const {
    tier,
    courseEnrolledData,
    title,
    countLabel,
    dateLabel,
    // endDateLabel,
    subscription,
    next,
    cancel,
    ticketType,
    planType,
    coursePeriod,
  } = props;
  const classes = useStyles();

  return (
    <>
      <IonCol size="1">
        <div className={classes.margin10}></div>
        <IonRow>
          <h5 className={classes.cardLabel} size="small">
            {title}
          </h5>
        </IonRow>
        <Card className={classes.cardContainer}>
          <CardContent className={classes.cardContent}>
            <h1 className={classes.cardLabel}>
              {subscription ||
              (planType === 'voiceTraining' && ticketType === 'regularTicket')
                ? 'プラン名'
                : 'チケット名'}
            </h1>
            <p className={classes.cardValue}>{tier?.title}</p>
          </CardContent>
          {!next && (
            <CardContent className={classes.cardContent}>
              <h1 className={classes.cardLabel}>{countLabel}</h1>
              <p className={classes.cardValue}>
                {courseEnrolledData?.numberOfClasses}回
              </p>
            </CardContent>
          )}
          {next && (
            <>
              <CardContent className={classes.cardContent}>
                <h1 className={classes.cardLabel}>契約開始日</h1>
                <p className={classes.cardValue}>
                  {courseEnrolledData?.enrolledAt &&
                    courseEnrolledData?.enrolledAt?.toDate().getMonth() + 1}
                  月
                  {courseEnrolledData?.enrolledAt &&
                    courseEnrolledData?.enrolledAt?.toDate().getDate()}
                  日
                </p>
              </CardContent>
            </>
          )}
          {/* 分割払いのボイトレの時の購入チケットの表示 */}
          {!next &&
            (planType === 'voiceTraining' && subscription ? (
              <CardContent className={classes.cardContent}>
                <h1 className={classes.cardLabel}>{dateLabel}</h1>
                <p className={classes.cardValue}>
                  {courseEnrolledData?.enrolledAt?.toDate().getMonth() +
                    coursePeriod +
                    1}
                  月
                  {cancel
                    ? courseEnrolledData?.enrolledAt?.toDate().getDate() - 1
                    : courseEnrolledData?.enrolledAt?.toDate().getDate()}
                  日
                </p>
              </CardContent>
            ) : (
              <CardContent className={classes.cardContent}>
                <h1 className={classes.cardLabel}>{dateLabel}</h1>
                <p className={classes.cardValue}>
                  {courseEnrolledData?.enrolledAt && subscription
                    ? courseEnrolledData?.enrolledAt?.toDate().getMonth() + 2
                    : courseEnrolledData?.completedAt?.toDate().getMonth() + 1}
                  月
                  {cancel
                    ? courseEnrolledData?.enrolledAt?.toDate().getDate() - 1
                    : courseEnrolledData?.enrolledAt?.toDate().getDate()}
                  日
                </p>
              </CardContent>
            ))}
          {courseEnrolledData?.offlineNumberOfClasses >= 0 && (
            <CardContent className={classes.cardContent}>
              <h1 className={classes.cardLabel}>
                今月の対面レッスンの残り回数
              </h1>
              <p className={classes.cardValue}>
                {courseEnrolledData?.numberOfClasses === 0
                  ? 0
                  : courseEnrolledData?.offlineNumberOfClasses}
                回
              </p>
            </CardContent>
          )}
        </Card>
      </IonCol>
    </>
  );
};

export default PlanStatus;
