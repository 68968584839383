import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonToast,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
} from '@ionic/react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Copyright from './Copyright';
import { AuthContext } from '../hooks/Auth';
// import classes from '*.module.css';
import { card, key } from 'ionicons/icons';
import './LessonReady.css';
import firebase, { firestore } from '../hooks/firebase';
import { schedule } from 'firebase-functions/lib/providers/pubsub';
// import { ZoomMtg } from '@zoomus/websdk'
import Console from '../hooks/console';
import { snapshotConstructor } from 'firebase-functions/v1/firestore';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '100%', // 4:3
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

const LessonReady = (props: any) => {
  const {
    closeLessonReady,
    classroomData,
    teacherUid,
    isOnline,
    beforeMinute,
    setBeforeMinute,
    lessonMessage,
    takeLesson,
    toastIsShown,
    setToastIsShown,
  } = props;
  Console.log('online', isOnline);
  Console.dir(`classroomData: ${classroomData}`);
  // Console.dir(props);
  const { currentUser } = useContext(AuthContext);

  const [videoIsShown, setVideoIsShown] = useState(false);
  const [lessonIsShown, setLessonIsShown] = useState(false);
  const [message, setMessage] = useState('');
  const [startTime, setStartTime] = useState<null | Date>(null);
  const [duration, setDuration] = useState<number>(0);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const mediaStreamRef = useRef<MediaStream>();

  // dateが今日と同じ日付または今日よりも前の日付ならtrue, dateが後の日付ならfalse
  const beforeToday = (date: Date) => {
    const now = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    const today = now.getDate();

    if (year == thisYear) {
      if (month == thisMonth) {
        return day <= today;
      } else {
        return month < thisMonth;
      }
    } else {
      return year < thisYear;
    }
  };

  // 映像と音声をストリーミングする
  const mediaStreamConstraints = {
    video: true,
    audio: true,
    sampleRate: { ideal: 32000 },
    sampleSize: { ideal: 16 },
    echoCancellation: true,
    echoCancellationType: 'system',
    noiseSuppression: false,
    latency: 0.01,
    mandatory: {
      googEchoCancellation: true,
      googAutoGainControl: true,
      googNoiseSuppression: true,
      googHighpassFilter: true,
      googTypingNoiseDetection: true,
    },
  };

  // videoIsShownとlessonIsShownのstateをtrueにする関数
  const showVideo = () => {
    setVideoIsShown(true);
    setLessonIsShown(true);

    (async () => {
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia(
        mediaStreamConstraints,
      ); // カメラと音声の権限の許可を得る
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStreamRef.current;
      } // videoを有効化
      if (audioRef.current) {
        audioRef.current.srcObject = mediaStreamRef.current;
      } // audioを有効化
    })();
    return () => {
      // lessonReadyページを閉じたときmediaStreamを停止する
      if (mediaStreamRef.current) mediaStreamRef.current.getTracks()[0].stop();
    };
  };

  const stopVideo = async () => {
    setVideoIsShown(false);
    mediaStreamRef?.current?.getTracks().forEach(track => {
      track.stop();
    });
  };

  const dateString = (num: number) => {
    const stringNum = '00' + String(num);
    return stringNum.slice(-2);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (Object.keys(classroomData).length > 0) {
        Console.log('classroomData: ' + JSON.stringify(classroomData));
        const start: string = classroomData.start;
        const lessonStartTime = new Date(
          Number(start.slice(0, 4)),
          Number(start.slice(5, 7)) - 1,
          Number(start.slice(8, 10)),
          Number(start.slice(11, 13)),
          Number(start.slice(14, 16)),
        );
        setStartTime(lessonStartTime);
        const dif = lessonStartTime.getTime() - new Date().getTime();
        const beforeMinute = Math.floor(dif / (1000 * 60));
        setBeforeMinute(beforeMinute);
        setDuration(Number(classroomData.duration));
      }
    }
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [currentUser, classroomData]);

  return (
    <IonPage>
      <Header title="レッスン準備" />
      <IonContent>
        <IonButton
          size="large"
          fill="clear"
          onClick={() => closeLessonReady(false)}
        >
          ←
        </IonButton>
        <IonCard>
          <IonCardContent>
            <p>
              レッスンは{dateString(startTime?.getHours() || 0)}:
              {dateString(startTime?.getMinutes() || 0)}
              からです。
            </p>
            {beforeMinute > 10 ? (
              <p>
                レッスン開始10分前になりましたら、レッスン開始ボタンを押せるようになります。今しばらくお待ちください。
              </p>
            ) : (
              <p>
                レッスン開始ボタンを押してください。Zoomが起動し、講師、生徒様が入室でき次第、レッスンが始まります。
              </p>
            )}

            {isOnline === 'Yes' && (
              <p>
                カメラをテストします。テスト完了後、レッスンを受講できます。
              </p>
            )}

            <div>
              {/* <IonGrid className="custom-grid-padding">
                <IonRow class="ion-justify-content-start"> */}
              <IonCol
                size="6"
                size-sm="2"
                key={key}
                className="custom-column-padding"
              >
                {isOnline === 'Yes' && (
                  <>
                    <IonButton onClick={() => showVideo()}>
                      カメラをテストする
                    </IonButton>
                    <IonButton onClick={() => stopVideo()}>
                      テストを終了する
                    </IonButton>
                    <Card>
                      <CardHeader title="" subheader="" />
                      <CardMedia image="" title="" />
                      <CardContent>
                        <video
                          style={{ width: '450px', maxWidth: '100%' }}
                          ref={videoRef}
                          autoPlay
                          playsInline
                          muted
                        />
                      </CardContent>
                    </Card>
                  </>
                )}

                <Card>
                  <CardContent>
                    <IonButton
                      disabled={
                        beforeMinute < -1 * duration || 10 < beforeMinute
                      }
                      onClick={() => takeLesson()}
                    >
                      レッスン開始
                    </IonButton>
                  </CardContent>
                </Card>
              </IonCol>
              {/* </IonRow>
              </IonGrid> */}
            </div>
          </IonCardContent>
        </IonCard>
        <IonToast
          isOpen={toastIsShown}
          onDidDismiss={() => setToastIsShown(false)}
          message={lessonMessage}
          duration={5000}
        />
      </IonContent>

      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Box mt={8}>
            <Copyright />
          </Box>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default LessonReady;
