import React, { useState } from 'react';
import { IonButton, IonLabel } from '@ionic/react';
import { ToolbarProps, NavigateAction } from 'react-big-calendar';
import Console from '../hooks/console';

// ToolbarProps メモ
// date: Date;
// view: View;
// views: ViewsProps;
// label: string;
// localizer: { messages: Messages };
// onNavigate: (navigate: NavigateAction, date?: Date) => void;
// onView: (view: View) => void;
// children?: React.ReactNode;

const CustomToolbar = (props: ToolbarProps) => {
  const { onNavigate, onView, label } = props;
  const [nextCount, setNextCount] = useState<number>(0);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [backDisabled, setBackDisabled] = useState<boolean>(false);

  const navigate = (action: NavigateAction) => {
    Console.log(action);
    if (nextCount > 7) {
      setBackDisabled(false);
      setNextDisabled(true); // nextボタンを押せないようにする
    } else if (nextCount < -7) {
      setBackDisabled(true); // backボタンを押せないようにする
      setNextDisabled(false);
    } else {
      setBackDisabled(false);
      setNextDisabled(false);
    }

    switch (action) {
      case 'TODAY':
        setNextCount(0);
        setNextDisabled(false);
        setBackDisabled(false);
        break;
      case 'PREV':
        setNextCount(nextCount - 1);
        break;
      case 'NEXT':
        setNextCount(nextCount + 1);
    }
    onNavigate(action);
  };
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <IonButton type="button" onClick={() => navigate('TODAY')}>
          TODAY
        </IonButton>
        <IonButton
          type="button"
          onClick={() => navigate('PREV')}
          disabled={backDisabled}
        >
          BACK
        </IonButton>
        <IonButton
          type="button"
          onClick={() => navigate('NEXT')}
          disabled={nextDisabled}
        >
          NEXT
        </IonButton>
      </span>
      {/* <span className="rbc-btn-group">
        <IonButton type="button" onClick={() => onView('day')}>
          DAY
        </IonButton>
        <IonButton type="button" onClick={() => onView('week')}>
          WEEK
        </IonButton>
        <IonButton type="button" onClick={() => onView('month')}>
          MONTH
        </IonButton>
      </span> */}
      <IonLabel className="rbc-toolbar-label">{label}</IonLabel>
    </div>
  );
};

export default CustomToolbar;
