import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import firebase from '../hooks/firebase';
import Console from '../hooks/console';

const MessageSubmitButton = ({
  inputEl,
  setText,
  text,
  classroomsId,
  uid,
  checked,
}: any) => {
  return (
    <IconButton
      disabled={text === ''}
      onClick={async () => {
        firebase.pushMessage({ text, classroomsId, uid });
        setText('');
        inputEl.current.focus();
        if (checked === 1) {
          if (await firebase.isClassroomsId(classroomsId)) {
            firebase.emailNotification(classroomsId, uid);
            // Console.log(checked)
          } else {
            firebase.beforeReservationEmailNotification(classroomsId, uid);
          }
        }
      }}
    >
      <SendIcon />
    </IconButton>
  );
};

export default MessageSubmitButton;
