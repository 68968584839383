import React, { useState, useContext, useEffect } from 'react';
import firebase from '../hooks/firebase';
// import { CalendarContext } from './Reservation';
import { AuthContext } from '../hooks/Auth';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IonButton } from '@ionic/react';
import Checkbox from '@material-ui/core/Checkbox';

const planName: any = {
  ondai: '楽器(プロフェッショナル)プラン',
  takaraduka: '宝塚受験プラン',
  basic: '楽器(ベーシック)プラン',
  voiceTraining: 'ボイスマスタープログラム',
};

const DecideModal = (props: any) => {
  const {
    start,
    end,
    title,
    submitFunc,
    decideModalClose,
    eventList,
    history,
    specialization,
    teacherName,
    setChecked,
    checked,
    offlineNumberOfClasses,
    isEnrolledAt,
    isCompletedAt,
    isAnnounceOpen,
    isTicket,
    allNotTime,
    courseEnrolledForwardCaseData,
    useTitle,
    minute,
    submitCourseEnrolled,
    isdisplayInfo,
    offlineClasses,
    setUseTitle,
    setMinute,
    setSubmitCourseEnrolled,
    setOfflineClasses,
    setOfflineNumberOfClasses,
    isModalOpen,
    getUserTicket,
    courseChange,
    modalOpen,
  } = props;
  const { currentUser } = useContext(AuthContext);
  const [ownCourse, setOwnCourse] = useState<any>([]); //所有している全てのcourseEnrolledが入ります
  const [courseEnrolled, setCourseEnrolled] = useState<any>({});
  const [courseIdList, setCourseIdList] = useState<any[]>([]);

  const list: any[] = []; //　useEffectで最後にsetOwnCourseに渡す配列
  const now = new Date();

  const close = () => {
    decideModalClose();
  };

  const reservationDeside = async () => {
    const courseData: any =
      courseEnrolled[`courses/${submitCourseEnrolled.id}`];
    const numberOfClasses: number = courseData.numberOfClasses;
    if (numberOfClasses <= 0) {
      const enrolledAt: Date = courseData.enrolledAt.toDate();
      const now: Date = new Date();
      const thisMonthUpdate: Date = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        enrolledAt.getDate(),
      );
      const nextUpdate: Date =
        now.getTime() < thisMonthUpdate.getTime()
          ? thisMonthUpdate
          : new Date(
              thisMonthUpdate.getFullYear(),
              thisMonthUpdate.getMonth() + 1,
              thisMonthUpdate.getDate(),
            );
      alert(
        `このコースはすでに今月分の残り回数を使い切っています。このコースの更新日は${nextUpdate.getMonth()}月${nextUpdate.getDate()}日です。`,
      );
      return false;
    }
    const plan = submitCourseEnrolled.planType;
    if (plan === 'instrumentTrial') {
      // instrumentTrialプランはbasicプランを担当する先生のみ
      submitCourseEnrolled.planType = 'basic';
    }
    if (plan === 'voiceTrial') {
      // ボイトレのtrialプランはvoiceTrainingプランを担当する先生のみ
      submitCourseEnrolled.planType = 'voiceTraining';
    }
    if (
      !specialization.find(
        (planType: string) => planType === submitCourseEnrolled.planType,
      )
    ) {
      alert(
        `申し訳ございません。${teacherName}先生は${planName[plan]}を担当していません。`,
      );
      return false;
    }
    const newEndTime = moment(start)
      .add(submitCourseEnrolled.minute, 'm')
      .format('YYYY-MM-DDTHH:mm');
    const data = {
      title: title,
      start: start,
      end: newEndTime,
      duration: submitCourseEnrolled.minute,
      courseId: submitCourseEnrolled.id,
    };

    let judgement = false;

    await eventList.map((item: any) => {
      if (
        new Date(item.start) < new Date(newEndTime) &&
        new Date(item.end) > new Date(start)
      ) {
        judgement = true;
        alert('すでに予約が入っています');
      }

      if (
        item.endTime <= new Date(start).getTime() &&
        new Date(start).getTime() < item.endTime + 15 * 60 * 1000
      ) {
        judgement = true;
        alert('講師の休憩時間を含んでいるため予約できません。');
      }
    });

    // 現在の時間より過去に予定を設定出来ないようにする関数です
    // const now = new Date();
    if (moment(now).isSameOrBefore(start) && !judgement) {
      if (new Date(end) < new Date(newEndTime)) {
        alert('予約枠外です');
      } else {
        submitFunc(data);
      }
    }
    decideModalClose();
  };

  // const modalOpen = () => {
  //   setIsModalOpen(true);
  // };

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    (async () => {
      const { courseEnrolled, courseIdList } =
        await firebase.getCourseEnrolledData(currentUser?.uid);

      setCourseEnrolled(courseEnrolled);
      setCourseIdList(courseIdList);

      getUserTicket(courseEnrolled, courseIdList);

      const tiers = await firebase.tiers();
      courseIdList.map((id: string) => {
        const courseData = courseEnrolled[id];
        const tier = tiers.find(tier => tier.id === courseData.id);
        if (
          tier !== undefined &&
          courseData.numberOfClasses > 0 &&
          ((courseData.mode === 'payment' &&
            now.getTime() < courseData.completedAt.toDate().getTime()) || // 有効期限の単発チケット
            (courseData.mode === 'subscription' &&
              courseData.status === 'ongoing' &&
              courseData.completedAt === null &&
              courseData.enrolledAt.toDate().getTime() < now.getTime()) || // 契約中のプラン
            (courseData.mode === 'subscription' &&
              courseData.status === 'canceled' &&
              !!courseData.completedAt &&
              now.getTime() < courseData.completedAt.toDate().getTime())) // 解約したが余っていて有効なチケット
        ) {
          list.push(tier);
        }
      });
      setMinute(list[0] ? list[0].minute : 0);
      setUseTitle(list[0] ? list[0].title : '');
      setSubmitCourseEnrolled(list[0] ? list[0] : {});
      setOwnCourse(list);
      setOfflineClasses(list[0] ? list[0]?.offlineClasses : null);
      const offlineNumberOfClasses =
        courseEnrolled[`courses/${list[0]?.id}`]?.offlineNumberOfClasses;
      if (offlineNumberOfClasses || '') {
        setOfflineNumberOfClasses(offlineNumberOfClasses);
      }
      modalOpen();
    })();
  }, [history]);

  return (
    <>
      {isModalOpen && (
        <>
          {allNotTime && (
            <div className="modalbodywrapper">
              <div className="editmodalbody">
                <div className="icon-box">
                  <Tooltip title="Close">
                    <IconButton aria-label="close" onClick={() => close()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  {!isCompletedAt && (
                    <p className="text-center margin-bottom-small">
                      有効期限が切れています。
                    </p>
                  )}
                  {!isEnrolledAt && (
                    <>
                      <p className="text-center margin-bottom-small">
                        コースを変更いただきありがとうございます。次のコースのレッスンは{' '}
                        {`${moment(
                          courseEnrolledForwardCaseData.enrolledAt.toDate(),
                        ).format('LL')}`}
                        から予約できます。予約可能日までお待ちください。
                        なお、現在のコースのチケットは追加でご購入いただけます。
                      </p>
                      <div className="margin-top-small text-align">
                        <div className="button">
                          <IonButton onClick={() => history.push('/Plans')}>
                            チケット購入画面に移動する
                          </IonButton>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {!isTicket && !allNotTime && (
            <div className="modalbodywrapper">
              <div className="editmodalbody">
                <div className="icon-box">
                  <Tooltip title="Close">
                    <IconButton aria-label="close" onClick={() => close()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <p className="text-center margin-bottom-small">
                    チケットの残数が0になっています。
                  </p>
                  <p className="text-center margin-bottom-large">
                    チケットを追加購入しますか？
                  </p>

                  <div className="margin-top-small text-align">
                    <div className="button">
                      <IonButton onClick={() => props.history.push('/Plans')}>
                        チケット購入画面に移動する
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isAnnounceOpen && isTicket && !allNotTime && (
            <div className="modalbodywrapper">
              <div className="editmodalbody">
                <div className="icon-box">
                  <Tooltip title="Close">
                    <IconButton aria-label="close" onClick={() => close()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <p className="text-center">予約者：{title}</p>
                  <div className="margin-top-small flex_center text-center">
                    <div>
                      {/* TODO {ownCourse[0].title}でシステムエラーになるので、一旦削除 */}
                      {/* {IsdisplayInfo ? (
                        <p className="margin-top-small  text-center">
                          {ownCourse[0].title}
                        </p>
                      ) : (
                        <p className="margin-top-small  text-center">
                          {useTitle}
                        </p>
                      )} */}
                      <p className="margin-top-small  text-center">
                        {useTitle}
                      </p>
                    </div>

                    <FormControl className="pull_down_small">
                      <Select value={''}>
                        {ownCourse.map((item: any) => (
                          <MenuItem
                            value={item.title}
                            key={item}
                            onClick={() => courseChange(item, currentUser?.uid)}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <p className="margin-top-small text-center">
                    受講開始時間： {moment(start).format('YYYY-MM-DD HH:mm')}
                  </p>

                  <div>
                    {isdisplayInfo ? (
                      <p className="margin-top-small text-center">
                        受講時間： {ownCourse[0] && ownCourse[0].minute}分
                      </p>
                    ) : (
                      <p className="margin-top-small text-center">
                        受講時間： {minute}分
                      </p>
                    )}
                    {offlineClasses >= 1 && offlineNumberOfClasses >= 1 && (
                      <p className="margin-top-small text-center">
                        対面レッスンを希望する
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      </p>
                    )}
                  </div>
                  <div className="margin-top-small text-align">
                    <div className="button">
                      <IonButton onClick={() => reservationDeside()}>
                        決定
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isAnnounceOpen && (
            /* プランやチケットがない人 */ <div className="modalbodywrapper">
              <div className="editmodalbody">
                <div className="icon-box">
                  <Tooltip title="Close">
                    <IconButton aria-label="close" onClick={() => close()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <p className="text-center margin-bottom-small">
                    レッスンをご受講いただくには、月額プランのご契約が必要です。
                  </p>
                  <div className="margin-top-small text-align">
                    <div className="button">
                      <IonButton onClick={() => history.push('/Plans')}>
                        購入画面に移動する
                      </IonButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DecideModal;
