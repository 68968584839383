import React, { useContext, useEffect, useState } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonPage,
  IonButton,
  IonToast,
} from '@ionic/react';
import './Profile.css';
import Copyright from '../components/Copyright';
import firebase from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import DeleteUserModal from '../components/DeleteUserModal';
import EmailPasswordLoginModal from '../components/EmailPasswordLoginModal';
import Header from '../components/Header';
import ReservationStatus from '../components/ReservationStatus';
import AgencyAttendedStatus from '../components/AgencyAttendedStatus';
import AgencyTeacherReservationStatus from '../components/AgencyTeacherReservationStatus';
import { withRouter } from 'react-router-dom';
import Console from '../hooks/console';
import LessonReady from '../components/LessonReady';

const AgencyProfile = (props: any) => {
  const { history } = props;
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [agencyUserUid, setAgencyUserUid] = useState<string>(''); // currentUserが講師ならuid, 講師でないなら空文字
  const [directorUid, setDirectorUid] = useState<string>('');
  const [toastIsShown, setToastIsShown] = useState(false);
  const [message, setMessage] = useState('');
  const [linkedProvider, setLinkedProvider] = useState('');
  const [openLessonReady, setOpenLessonReady] = useState<boolean>(false);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [classroomData, setClassroomData] = useState<any>({});
  const googleProvider = 'google.com';
  const passwordProvider = 'password';

  const uid = currentUser?.uid;

  const modal = () => {
    if (showModal)
      return (
        <DeleteUserModal
          yesFunc={async () => {
            await firebase.deleteUser(uid);
            // await cancellationCourse();
            alert('正常に削除されました。');
            history.push('/login');
          }}
          NoFunc={() => setShowModal(false)}
          setToastIsShown={setToastIsShown}
          setMessage={setMessage}
          uid={uid || ''}
          provider={linkedProvider}
        ></DeleteUserModal>
      );
    else return <></>;
  };

  const loginModal = () => {
    if (openLoginModal) {
      return (
        <EmailPasswordLoginModal
          yesFunc={async () => {
            setOpenLoginModal(false);
            setLinkedProvider('');
          }}
          NoFunc={() => setOpenLoginModal(false)}
          setToastIsShown={setToastIsShown}
          setMessage={setMessage}
          currentUser={currentUser}
        />
      );
    }
  };

  const submitTeacherUid = () => {
    history.push('/Teachers');
  };

  const createBankAccount = async () => {
    const agencyUserData: any = await firebase
      .getAgencyUser(currentUser?.uid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        } else {
          Console.log('schedule not found');
          return {};
        }
      });
    const agencyId = agencyUserData?.agencyId;
    let url;
    const host = window.location.host;
    const headText = host.slice(0, 5);
    const STATE_VALUE = agencyId;
    const email = currentUser?.email;
    let envVar;
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'prod-langdemy') {
      // envVar = 'apis'; // GKE
      envVar = 'prod-langdemy';
    } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'stg-langdemy') {
      // envVar = 'stg-apis'; // GKE
      envVar = 'stg-langdemy';
    } else {
      // envVar = 'dev-apis'; // GKE
      envVar = 'dev-langdemy';
    }

    //const domain = `https://${envVar}.langdemy.com/`; //GKE
    const domain = `https://asia-northeast1-${envVar}.cloudfunctions.net/`; // CloudFunctions

    if (headText === 'langd' || headText === 'music' || headText === 'stg-l') {
      url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHsbDMIv1L1S1WroseuAV1ZEgS67br&state=${STATE_VALUE}&stripe_user[email]=${email}`; // 本番環境
    } else {
      url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHFDZkLhOwDM7cL3P7SiQcoi4VPm4h&state=${STATE_VALUE}&stripe_user[email]=${email}`; // テスト環境
    }
    window.open(url);
  };

  const showPricingTable = () => {
    history.push('/pricing-table');
  };

  const cancellationCourse = async () => {
    const userDoc: any = await firebase
      .getStudent(currentUser?.uid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        }
      })
      .catch(e => {});

    const courseEnrolled = userDoc.courseEnrolled || {};
    Object.keys(courseEnrolled).map(id => {
      courseEnrolled[id].status = 'cancel';
      courseEnrolled[id].completedAt = new Date();
    });
    const stripeData = userDoc.stripeData;
    const data = {
      id: stripeData.subscription,
    };
    const result: any = await firebase.cancelSubscription(data);
    const setData = {
      courseEnrolled: {
        ...courseEnrolled,
      },
      stripeData: {
        ...stripeData,
        subscription: '',
      },
      updatedAt: new Date(),
      updatedBy: currentUser?.uid,
    };
    firebase
      .getStudent(currentUser?.uid)
      .update(setData)
      .then(() => {})
      .catch(e => {});
  };

  useEffect(() => {
    Console.log('ティーチャーUID', agencyUserUid);
    currentUser === null && history.push('/login');
    if (currentUser) {
      (async () => {
        if (
          currentUser.providerData.length === 1 &&
          currentUser.providerData[0]?.providerId === googleProvider
        ) {
          setLinkedProvider(googleProvider);
        } else if (
          currentUser.providerData.length === 1 &&
          currentUser.providerData[0]?.providerId === passwordProvider
        ) {
          setLinkedProvider(passwordProvider);
        }
        if (currentUser?.uid) {
          const uid: string = currentUser?.uid;
          if (await firebase.isAgencyUserUid(uid)) {
            setAgencyUserUid(uid);
          }
          if (await firebase.isDirectorUid(uid)) {
            setDirectorUid(uid);
          }
        }
      })();
    }
  }, [currentUser, history]);

  return (
    <>
      {!openLessonReady ? (
        <>
          <IonPage>
            <Header />
            <IonContent>
              <IonCard>
                <IonCardHeader>
                  <h1>今後のレッスン予定</h1>
                </IonCardHeader>
                <IonCardContent>
                  {Console.log('ティーチャーUID', agencyUserUid)}
                  {/* {teacherUid ? ( */}
                  <AgencyTeacherReservationStatus
                    agencyUserUid={agencyUserUid}
                    setOpenLessonReady={setOpenLessonReady}
                    setClassroomData={setClassroomData}
                    currentUser={currentUser}
                  />
                  {/* ) : (
                      <ReservationStatus
                        setOpenLessonReady={setOpenLessonReady}
                        setClassroomData={setClassroomData}
                        currentUser={currentUser}
                      />
                    )} */}
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <h1>受講履歴</h1>
                </IonCardHeader>
                <IonCardContent>
                  <AgencyAttendedStatus
                    agencyUserUid={agencyUserUid}
                    currentUser={currentUser}
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                {linkedProvider === googleProvider && (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {
                          setOpenLoginModal(true);
                        }}
                      >
                        Emailとパスワードのログインを有効にする
                      </IonButton>
                    </IonCardContent>
                  </>
                )}
                {/* {linkedProvider === passwordProvider && (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {}}
                      >
                        Googleログインもできるようにする
                      </IonButton>
                    </IonCardContent>
                  </>
                )} */}
                {agencyUserUid ? (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {
                          createBankAccount();
                        }}
                      >
                        銀行口座登録をする
                      </IonButton>
                    </IonCardContent>
                  </>
                ) : (
                  <></>
                )}
                <IonCardContent>
                  <IonButton
                    onClick={() => {
                      firebase.logout();
                      props.history.push('/login');
                    }}
                  >
                    ログアウト
                  </IonButton>
                </IonCardContent>
                {!!agencyUserUid || !!directorUid ? (
                  <></>
                ) : (
                  <IonCardContent>
                    <a
                      className=""
                      href="#"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      退会
                    </a>
                  </IonCardContent>
                )}
              </IonCard>
              {/* <IonButton onClick={() => set()}>セット</IonButton> */}
              {modal()}
              {loginModal()}
              <Copyright />
              <IonToast
                isOpen={toastIsShown}
                onDidDismiss={() => setToastIsShown(false)}
                message={message}
                duration={5000}
              />
            </IonContent>
          </IonPage>
        </>
      ) : (
        <LessonReady
          classroomData={classroomData}
          agencyUserUid={agencyUserUid}
          closeLessonReady={setOpenLessonReady}
        />
      )}
    </>
  );
};

export default withRouter(AgencyProfile);
