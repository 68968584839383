import Menu from './components/Menu';
import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import About from './pages/About';
import Teachers from './pages/Teachers';
import AgencyTeachers from './pages/AgencyTeachers';
import AgencyProfile from './pages/AgencyProfile';
import OperatorTeachers from './pages/OperatorTeachers';
import SuccessStripe from './pages/SuccessStripe';
import SuccessUpdate from './pages/SuccessUpdate';
import SuccessCancel from './pages/SuccessCancel';
import CancelStripe from './pages/CancelStripe';
import { AuthProvider } from './hooks/Auth';
import Profile from './pages/Profile';
import Plans from './pages/Plans';
import PasswordRemind from './pages/PasswordRemind';
import { Terms } from './components/Terms';
import { Law } from './components/Law';
import { Privacy } from './components/Privacy';
import { PricingTable } from './components/PricingTable';

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        {/* <IonSplitPane contentId="main"> */}
        {
          <AuthProvider>
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/login" component={Login} exact />
              <Route path="/signUp" component={SignUp} exact />
              <Route path="/password-remind" component={PasswordRemind} exact />
              <Route path="/terms" component={Terms} exact />
              <Route path="/privacy" component={Privacy} exact />
              <Route path="/law" component={Law} exact />
              <Route path="/about" component={About} exact />
              <AuthProvider>
                {/* <Route path="/CreateMeeting" component={CreateMeeting} exact /> */}
                <Route path="/teachers" component={Teachers} exact></Route>
                <Route path="/operatorTeachers" component={OperatorTeachers} exact></Route>
                <Route path="/profile" component={Profile} exact></Route>
                <Route path="/plans" component={Plans} exact></Route>
                <Route path="/success" component={SuccessStripe} exact></Route>
                <Route path="/agencies" component={AgencyTeachers} exact></Route>
                <Route path="/agencyProfile" component={AgencyProfile} exact></Route>
                <Route
                  path="/successupdate"
                  component={SuccessUpdate}
                  exact
                ></Route>
                <Route
                  path="/successcancel"
                  component={SuccessCancel}
                  exact
                ></Route>
                <Route path="/cancel" component={CancelStripe} exact></Route>
                <Route
                  path="/pricing-table"
                  component={PricingTable}
                  exact
                ></Route>
                {/* <Route
                  path="/subscriptions"
                  component={Subscriptions}
                  exact
                ></Route> */}
              </AuthProvider>
              <Redirect from="/" to="/about" component={About} exact />
            </IonRouterOutlet>
          </AuthProvider>
        }
        {/* </IonSplitPane> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
