/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import firebase from 'firebase/app';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationJA from './locales/ja/translation.json';
import translationEN from './locales/en/translation.json';
import { createTheme, ThemeProvider } from '@material-ui/core';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ja: {
      translation: translationJA,
    },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  interpolation: { escapeValue: false },
});

const { REACT_APP_STRIPE_API_KEY } = process.env;

export const stripeConfig = {
  stripeApiKey: REACT_APP_STRIPE_API_KEY,
};

export const stripePromise = loadStripe(stripeConfig.stripeApiKey!);

if (process.env.NODE_ENV === 'production') {
  firebase.analytics();
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF9A00',
      contrastText: '#fff',
    },
    secondary: {
      main: '#3dc2ff',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
