import React, { useContext, useEffect, useRef } from 'react';
import {
  makeStyles,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Console from '../hooks/console';
import firebase, { firestore } from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
}));

const MessageItem = ({ message, isLastItem, postedBy }: any) => {
  const ref = useRef<any>(null);
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const breakedText = message.text.split('\n').map((line: any, key: any) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));
  // 省略
  useEffect(() => {
    if (isLastItem) {
      // ここでスクロールする
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLastItem]);
  return (
    <ListItem divider={true} ref={ref}>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText primary={message.name} secondary={breakedText} />
    </ListItem>
  );
};

export default MessageItem;
