import { makeStyles, Grid, Avatar } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import MessageField from './MessageField';
import firebase from '../hooks/firebase';
import MessageSubmitButton from './MessageSubmitButton';

const useStyles = makeStyles({
  root: {
    gridRow: 2,
    margin: '26px',
  },
  fix: {
    position: 'fixed',
    bottom: '20px',
  },
});

const MessageInputField = (props: any) => {
  const { classroomsId, uid, checked } = props;
  const [text, setText] = useState<string | number>('');
  const inputEl = useRef(null);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}>
          <Avatar />
        </Grid>
        <Grid item xs={10}>
          <MessageField
            inputEl={inputEl}
            setText={setText}
            text={text}
            uid={uid}
            classroomsId={classroomsId}
            checked={checked}
          />
        </Grid>
        <Grid item xs={1}>
          <MessageSubmitButton
            inputEl={inputEl}
            setText={setText}
            text={text}
            uid={uid}
            classroomsId={classroomsId}
            checked={checked}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MessageInputField;