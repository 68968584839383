import React, { useState, useEffect, createContext, useContext } from 'react';
// import CreateAvailabilityEditModalJudgement from './_OLD_CreateAvailabilityEditModalJudgement';
import CreateAvailabilityDecideModal from './CreateAvailabilityDecideModal';
// import { eventChange, eventDelete } from '../hooks/calendar';
// import {
//   workingTimeFirebaseSubmit,
//   deleteAvailability,
//   changeAvailability,
// } from '../hooks/createAvailability';
import { AuthContext } from '../hooks/Auth';
import firebase from '../hooks/firebase';
import './Reservation.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ja';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from '@material-ui/core/Modal';
import Reservation from './Reservation';
import EditedModal from './EditedModal';
import EditModal from './EditModal';
import cloud from 'firebase';
import CustomToolbar from './CustomToolbar';
import Console from '../hooks/console';

const localizer = momentLocalizer(moment);
export const CreateAvailabilityContext = createContext<any>({});

const width = window.innerWidth;

const adjustData = (
  startTime: moment.MomentInput,
  endTime: moment.MomentInput,
) => {
  const year = moment(startTime).format('YYYY');
  const month = moment(startTime).format('MM');
  const day = moment(startTime).format('DD');
  const start = moment(startTime).format('HHmm');
  const end = moment(endTime).format('HHmm');
  const data = { year, month, day, start, end };
  return data;
};

type CreateAvailabilityType = {
  selectTeacherUid: string;
};

const CreateAvailability = (props: CreateAvailabilityType) => {
  const { selectTeacherUid } = props;

  type data = {
    title: string;
    start: Date;
    end: Date;
    id: string;
    uid?: string | undefined;
    teacherUid?: string;
  };

  const { currentUser } = useContext(AuthContext);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [instructorTitle, setInstructorTitle] = useState<string>('');
  const [id, setId] = useState<string>(''); //削除する時にデータの照合に使う値です
  const [eventList, setEventList] = useState<any>([]); //react-big-calendarのeventsに入れる値です
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false); //予定を変更・削除するモーダルを制御する値です
  const [decideModalIsOpen, setDecideModalIsOpen] = useState<boolean>(false); //予定を決めるモーダルを制御する値です
  const [editModalJudgementNum, setEditModalJudgementNum] = useState<number>(0); //予定がクリックされた時のモーダルを管理する値です。1に変更された時に、編集画面に遷移します。
  const [decidedData, setdecidedData] = useState<any>({}); //予定を変更・削除するモーダルに表示する値です
  const [workingTimeList, setWorkingTimeList] = useState<any>();
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [lessonTimeList, setLessonTimeList] = useState<any>([]);

  const safariDate = (date: Date) => {
    const jpDate = new Date(date.setHours(date.getHours() + 9));
    const isoDate = jpDate.toISOString();
    return new Date(isoDate.slice(0, 19));
  };
  const now = safariDate(new Date());
  let title: any;

  //予定の背景色の定義をしています。引数の値はreact-big-calendarのeventsに入っている値です。
  const eventBackgroundColor = (event: any) => {
    let style = {
      backgroundColor: '#40C4FF',
      color: 'black',
      border: '0px',
    };

    return {
      style: style,
    };
  };

  //日にちの文字の色を定義してます
  const calendarStyle = () => {
    return {
      style: {
        color: '#428cff',
      },
    };
  };

  //予定を変更・削除するモーダルを閉める関数です
  const editModalClose = () => {
    setEditModalIsOpen(false);
    setEditModalJudgementNum(0);
  };

  //予定を決めるモーダルを閉める関数です
  const decideModalClose = () => {
    setDecideModalIsOpen(false);
  };

  //予定を変更・削除するモーダルを開ける関数です
  const editModalOpen = (event: any) => {
    setEditModalIsOpen(true);
    setId(event.id);
    const data = eventList.filter((item: any) => item.id === event.id);
    setdecidedData(data[0]);
  };

  //予定を決めるモーダルを開ける関数です
  const decideModalOpen = async (e: any) => {
    const start = e.slots.slice(0, 1)[0];
    const end = e.slots.slice(-1)[0];
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    const instructorDoc: any = await firebase.getInstructor(selectTeacherUid).get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      } else {
        return {};
      }
    }).catch(e => {
      Console.error(e);
      return {};
    });
    if (!(instructorDoc?.zoomApi.hasOwnProperty('apiKey') && instructorDoc?.zoomApi.hasOwnProperty('apiSecret') && instructorDoc?.zoomApi.hasOwnProperty('email'))) {
      alert('Zoomアカウントをランデミー運営事務局で設定中です。設定終わり次第、予約枠を作成いただけますので、お時間いただければ幸いです。');
      return;
    }
    if (new Date(start).getHours() === 21 || new Date(start).getHours() < 9) {
      alert('この時間は設定することはできません。');
      return;
    }
    if (moment(tomorrow).isBefore(e.start)) {
      setDecideModalIsOpen(true);
      setStartTime(start);
      setEndTime(end);
    } else if (!moment(now).isSameOrBefore(e.start)) {
      alert('現在時刻より前に予約枠を設定することはできません');
      return;
    } else {
      alert('本日の予約枠を設定することはできません。');
      return;
    }
  };

  //発火時に編集画面に遷移します
  const moveEdited = () => {
    setEditModalJudgementNum(1);
  };

  //子要素からデータを受け取りカレンダーにデータを反映、Firestoreに送信する関数です
  const submitFunc = async (setData: any) => {
    // 新規予約枠をFirestoreに保存
    const { id, startTime, endTime, instructorTitle } = setData;

    const data = adjustData(startTime, endTime);
    const ref = `availability.${data.year}.${data.month}.${data.day}`;
    const avail = await firebase.getInstructor(selectTeacherUid).get().then(snapshot => {
      if (snapshot.exists) {
        const data: any = snapshot.data()
        return data.availability;
      }
    });
    const availList = !avail ? [] : (
      !avail[data.year] ? [] : (
        !avail[data.year][data.month] ? [] : (
          !avail[data.year][data.month][data.day] ? [] : avail[data.year][data.month][data.day]
        )
      )
    );
    Console.log('availList:', availList);
    const innerAvail = !!availList ? availList.find((avail: any) =>
      (
        (Number(avail.start) <= Number(data.start) && Number(data.start) <= Number(avail.end)) ||
        (Number(avail.start) <= Number(data.end) && Number(data.end) <= Number(avail.end)) ||
        (Number(data.start) <= Number(avail.start) && Number(avail.end) <= Number(data.end))
      )) : null;
    Console.log('innerAvail:', innerAvail)
    if (innerAvail === null) {
      Console.log('error in get availList');
    } else if (innerAvail !== undefined) {
      alert('同じ時間に複数の予約枠を設定できません。');
      return;
    }

    await firebase.getInstructor(selectTeacherUid).update({
      [ref]: cloud.firestore.FieldValue.arrayUnion({
        end: data.end,
        start: data.start,
        id: id,
      }),
    });

    const event: data = {
      id: id,
      title: instructorTitle,
      start: new Date(startTime),
      end: new Date(endTime),
      uid: selectTeacherUid,
      teacherUid: selectTeacherUid,
    };
    setEventList((state: any) => [...state, event]);
  };

  const intTime = (date: Date) => {
    return date.getHours() * 100 + date.getMinutes()
  }

  // 子要素から送られてくる子要素のidを受け取り、子要素を除いたデータを返し、子要素から送られてきたidを持つデータをFireStoreから消去する関数です
  const deleteFunc = () => {
    const deleteEvent = eventList.find((item: any) => item.id === id) || {};
    const deleteDate = adjustData(deleteEvent.start, deleteEvent.end);
    const inLesson = lessonTimeList.find((lesson: { start: Date, end: Date }) => {
      if (
        (Number(deleteDate.year) === lesson.start.getFullYear() && Number(deleteDate.month) - 1 === lesson.start.getMonth() && Number(deleteDate.day) === lesson.start.getDate()) &&
        (
          (deleteEvent.start.getTime() <= lesson.start.getTime() && lesson.start.getTime() <= deleteEvent.end.getTime()) ||
          (deleteEvent.start.getTime() <= lesson.end.getTime() && lesson.end.getTime() <= deleteEvent.end.getTime())
        )
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (inLesson !== undefined) {
      alert('この時間はすでに予約が入っているので削除できません。')
      return;
    }

    const data = eventList.filter((item: any) => item.id !== id);
    setEventList(data);
    const deleteData = {
      start: deleteEvent.start,
      end: deleteEvent.end,
      id: id,
    };
    deleteAvailability(deleteData);
  };

  // firestoreに予約枠の削除を反映させる
  const deleteAvailability = async (deleteData: any) => {
    const { start, end, id } = deleteData;
    const instructorRef = firebase.getInstructor(selectTeacherUid);
    const data = adjustData(start, end);
    const ref = `availability.${data.year}.${data.month}.${data.day}`;
    await instructorRef
      .update({
        [ref]: cloud.firestore.FieldValue.arrayRemove({
          end: data.end,
          start: data.start,
          id: id,
        }),
      })
      .then(snapshot => Console.log('then snapshot ' + snapshot))
      .catch(e => Console.error('error in deleteAvailability: ' + e));
  };

  //子要素からデータを受け取り、変換したデータをカレンダーに反映、Firestoreに送信する関数です
  const changeFunc = (item: any) => {
    let changedEventData = eventList.filter((data: any) => data.id !== item.id);
    const data: data = {
      title: item.title,
      start: new Date(item.newStart),
      end: new Date(item.newEnd),
      id: item.id,
    };
    changedEventData.push(data);
    setEventList(changedEventData);
    const updateData = {
      start: item.start,
      end: item.end,
      newStart: item.newStart,
      newEnd: item.newEnd,
      id: item.id,
    };
    changeAvailability(updateData);
  };

  // firestoreに予約枠の更新を反映させる
  const changeAvailability = async (updateData: any) => {
    const { start, end, newStart, newEnd, id } = updateData;
    const instructorRef = firebase.getInstructor(selectTeacherUid);
    const data = adjustData(start, end);
    const oldData = adjustData(newStart, newEnd);
    const ref = `availability.${data.year}.${data.month}.${data.day}`;
    const newRef = `availability.${oldData.year}.${oldData.month}.${oldData.day}`;

    await instructorRef.update({
      [ref]: cloud.firestore.FieldValue.arrayRemove({
        end: data.end,
        start: data.start,
        id: id,
      }),
    });

    await instructorRef.update({
      [newRef]: cloud.firestore.FieldValue.arrayUnion({
        end: oldData.end,
        start: oldData.start,
        id: id,
      }),
    });
  };

  const openCalendar = () => {
    setIsOpenCalendar(true);
  };

  useEffect(() => {
    let instructorData: any;
    let getMonth;
    let getDay;
    let getData;
    let availabilityTime: any[] = [];
    let eventList: any[] = [];

    firebase
      .searchInstructors()
      .get()
      .then(async snapshot => {
        snapshot.forEach(async (snapshot: any) => {
          const getInstructorsData = snapshot.data();
          if (getInstructorsData.profile.uid === selectTeacherUid) {
            instructorData = getInstructorsData;
            title = getInstructorsData.profile.name;
            setInstructorTitle(getInstructorsData.profile.name);
          }
        });

        const getYear = Object.keys(instructorData.availability);

        getYear.map((item: any) => {
          let getYear = item;
          getMonth = Object.keys(instructorData.availability[getYear]);
          getMonth.map((item: any) => {
            let getMonth = item;
            getDay = Object.keys(
              instructorData.availability[getYear][getMonth],
            );
            getDay.map((item: any, index: any) => {
              let getDay = item;
              getData = Object.keys(
                instructorData.availability[getYear][getMonth][getDay],
              );
              getData.map((item: any, index: any) => {
                let setTime =
                  instructorData.availability[getYear][getMonth][getDay][index];
                const pushNumberTime = {
                  year: Number(getYear),
                  month: Number(getMonth.substr(1) - 1),
                  day: Number(getDay.substr(1)),
                  startHour: Number(setTime.start.substr(0, 2)),
                  startMinutes: Number(setTime.start.substr(2, 2)),
                  endHour: Number(setTime.end.substr(0, 2)),
                  endMinutes: Number(setTime.end.substr(2, 2)),
                };
                const startTime = `${getYear}/${getMonth}/${getDay} ${setTime.start.substr(
                  0,
                  2,
                )}:${setTime.start.substr(2, 2)}`;
                const endTime = `${getYear}/${getMonth}/${getDay} ${setTime.end.substr(
                  0,
                  2,
                )}:${setTime.end.substr(2, 2)}`;

                const eventData = {
                  title: title,
                  start: new Date(startTime),
                  end: new Date(endTime),
                  id: setTime.id,
                };
                availabilityTime.push(pushNumberTime);
                eventList.push(eventData);
              });
            });
          });
        });

        const classroomsIdList = Object.keys(instructorData.schedule).filter(id => instructorData.schedule[id].status === 'reservation').map(id => id.slice(11));

        const getClass = async (id: string) => {
          return await firebase.getClassroom(id).get().then(snapshot => {
            if (snapshot.exists) {
              Console.log(`get success classroomsId = ${id}`);
              return snapshot.data();
            } else {
              Console.log(`data of classroomsId = ${id} is not found`);
              return {}
            }
          }).catch(e => Console.error("error in myClassrooms get " + e));
        };
        const promises: any[] = classroomsIdList.map((id: string) => getClass(id));
        const myClassrooms: any[] = await Promise.all(promises).then(value => {
          // Console.log("Promise.all value: " + value);
          return value;
        }).catch(e => {
          Console.error("error in Promise.all" + e)
          return [];
        });
        const formatDate = (date: any, time: any) => {
          return new Date(date.year, date.month, date.day, time.hour, time.minutes, 0, 0);
        }
        const lessonTimes = myClassrooms.map(obj => {
          return {
            start: formatDate(obj.date, obj.time.start),
            end: formatDate(obj.date, obj.time.end),
          }
        })
        setLessonTimeList(lessonTimes)
        setEventList(eventList);
        Console.log(eventList)
        setWorkingTimeList(availabilityTime);
        openCalendar();
      });
  }, [currentUser]);

  return (
    <div>
      {/* <button onClick={() => deleteFunction()}>button</button> */}
      {}
      <CreateAvailabilityContext.Provider
        value={{
          eventList,
          decidedData,
          startTime,
          endTime,
          instructorTitle,
          editModalJudgementNum,
          deleteFunc,
          changeFunc,
          submitFunc,
          editModalOpen,
          decideModalOpen,
          editModalClose,
          decideModalClose,
          moveEdited,
        }}
      >
        {/* 予定がクリックされた時はこちらが開きます */}
        <Modal open={editModalIsOpen} onClose={editModalClose}>
          <div>
            {editModalJudgementNum === 0 ? (
              <EditModal
                decidedData={decidedData}
                editModalClose={editModalClose}
                deleteFunc={deleteFunc}
                isEdited={false}
              />
            ) : (
                <EditedModal
                  decidedData={decidedData}
                  changeFunc={changeFunc}
                  editModalClose={editModalClose}
                  eventList={eventList}
                  useAvailabilityTime
                  editId={id}
                />
              )}
          </div>
        </Modal>
        {/* テーブルがクリックされた時はこちらが開きます */}
        <Modal open={decideModalIsOpen} onClose={decideModalClose}>
          <div>
            <CreateAvailabilityDecideModal />
          </div>
        </Modal>
        {/* 1024pxを境目に表示するのをパソコンとスマホ・タブレットで分けています*/}
        {/* <div className={count2 ? 'visible' : 'display_none'}> */}
        <div>
          {/* {width >= 764 ? ( */}
          <div>
            {isOpenCalendar && (
              <Calendar
                selectable={true}
                timeslots={4}
                step={30}
                className="calendar-box"
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="week"
                // 平日のみは
                // defaultView="work_week"
                // views={{ work_week: true}}
                views={{ week: true}} 
                events={eventList}
                // eventPropGetter={eventBackgroundColor}
                dayPropGetter={calendarStyle}
                // slotPropGetter={customSlotPropGetter}
                onSelectSlot={decideModalOpen}
                min={
                  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10)
                }
                max={
                  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21)
                }
                // onSelectEvent={event => alert(event.title)}
                onSelectEvent={event => editModalOpen(event)}
                popup
                components={{ toolbar: CustomToolbar }}
              />
            )}
          </div>
          {/* // ) : (
          //   <Reservation />
          // )} */}
        </div>
      </CreateAvailabilityContext.Provider>
    </div>
  );
};

export default CreateAvailability;
