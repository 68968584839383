import { IonModal } from '@ionic/react';
import { Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    question: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    }
  }),
);

const Tutorial = (props: any) => {
  const classes = useStyles();
  return (
    <IonModal isOpen={props.isOpen} cssClass="my-custom-class">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <HelpIcon />
          {'　'}
          <Typography className={classes.question}>
            レッスンチケット購入方法
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <iframe
            width="600"
            height="480"
            src="https://www.youtube.com/embed/P3whjwDNWvM"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2 a-content"
          id="panel2a-header"
        >
          <HelpIcon />
          {'　'}
          <Typography className={classes.question}>
            レッスン当日の流れ
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <iframe
            width="600"
            height="480"
            src="https://www.youtube.com/embed/eNbK_-wQurs"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </AccordionDetails>
      </Accordion>
    </IonModal>
  );
};

export default Tutorial;
