import React, { useState, useEffect } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CreateIcon from '@material-ui/icons/Create';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

type EditModalProps = {
  decidedData: any;
  editModalClose: any;
  deleteFunc: any;
  isEdited: boolean;
}

const EditModal = (props: EditModalProps) => {
  const { decidedData, editModalClose, deleteFunc, isEdited } = props;

  const [data, setData] = useState({
    title: '',
    start: '',
    end: new Date(),
    id: '',
    duration: 0,
  });
  const now = new Date();
  let determineTime: any;

  const createTime = () => {
    let yearAndMonth: any = moment(data.start, 'YYYY-MM').format('YYYY-MM');
    let yesterdayDateTime: any;
    if (new Date(data.start).getDate() === 1) {
      yesterdayDateTime = new Date(data.start).getDate();
    } else {
      yesterdayDateTime = new Date(data.start).getDate() - 1;
    }
    determineTime = moment(`${yearAndMonth}-${yesterdayDateTime} 23:59:59`);
  };

  //予定を削除する関数です
  const deleteFunction = () => {
    createTime();
    if (moment(now).isSameOrBefore(new Date(determineTime))) {
      deleteFunc();
      editModalClose();
    } else if (!moment(now).isSameOrBefore(new Date(determineTime))) {
      alert('予約の変更・削除は前日の23時59分59秒までです');
      editModalClose();
    }
  };


  useEffect(() => {
    setData({
      title: decidedData?.title,
      start: decidedData?.start,
      end: new Date(decidedData?.end),
      id: decidedData?.id,
      duration: decidedData?.duration,
    });
    //  }
  }, []);

  return (
    <div className="modalbodywrapper">
      <div className="icon-box">
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => deleteFunction()}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Close">
          <IconButton aria-label="close" onClick={() => editModalClose()}>
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className="editmodalbody">
        {/* {availabilityDecidedData ? (
          <p className="text-center">講師：{data.title}</p>
        ) : ( */}
        {isEdited ? (
          <>
            <p className="text-center">予約者：{data.title}</p>
            <div className="margin-top-small">
              <p className="text-center">
                開始時間： {moment(data.start).format('MM-DD HH:mm')}
              </p>
            </div>
            <div className="margin-top-small">
              <p className="text-center">
                終了時間: {moment(data.end).format('MM-DD HH:mm')}
              </p>
            </div>
          </>
        ) : (
            <>
              <div className="margin-top-small">
                <p className="text-center">
                  稼働開始時間： {moment(data.start).format('MM-DD HH:mm')}
                </p>
              </div>
              <div className="margin-top-small">
                <p className="text-center">
                  稼働終了時間: {moment(data.end).format('MM-DD HH:mm')}
                </p>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default EditModal;
