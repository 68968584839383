import React, { useContext } from 'react';
import { AuthContext } from '../hooks/Auth';
import AudioPlayer from './AudioPlayer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import './Navigation.css';

/* eslint-disable react/react-in-jsx-scope */
export const Navigation = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  const { currentUser } = useContext(AuthContext);
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {' '}
            <span className="sr-only">メニュー</span>{' '}
            <span className="icon-bar"></span>{' '}
            <span className="icon-bar"></span>{' '}
            <span className="icon-bar"></span>{' '}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            {/* <img src="./assets/icon/icon.png" alt="..." height="36" /> */}
            ランデミーこどもの音楽教室
          </a>{' '}
          {/* <AudioPlayer /> */}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {currentUser ? (
              <li>
                <a href="./Teachers" className="page-scroll">
                  講師検索
                </a>
              </li>
            ) : (
              <>
                <li>
                  <a href="./login" className="page-scroll">
                    {t('navigation.login')}
                  </a>
                </li>
              </>
            )}
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li> */}
            <li>
              <a href="#teacher" className="page-scroll">
                {t('navigation.teacher')}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {t('navigation.services')}
              </a>
            </li>
            <li>
              <a href="#instructions" className="page-scroll">
                {t('navigation.instructions')}
              </a>
            </li>
            {/* <li>
              <a href="#pricing" className="page-scroll">
                {t('navigation.pricing')}
              </a>
            </li> */}
            {/* <li>
              <a href="#team" className="page-scroll">
                開発チーム
              </a>
            </li> */}
            <li>
              <a href="#pricing" className="page-scroll">
                {t('navigation.pricing')}
              </a>
            </li>
            <li>
              <a href="#faq" className="page-scroll">
                {t('navigation.faq')}
              </a>
            </li>
            {/* <li>
              <a
                href="https://forms.gle/R2i5VTUfUkyXvc2c7"
                className="page-scroll"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('navigation.inquiry')}
              </a>
            </li> */}
            {/* <li>
              <Select
                id="select-lang"
                defaultValue="ja"
                onChange={e => changeLanguage(e.target.value)}
                style={{ fontSize: 14, marginTop: 12, marginLeft: 22 }}
              >
                <MenuItem value="ja" style={{ fontSize: 14 }}>
                  日本語
                </MenuItem>
                <MenuItem value="en" style={{ fontSize: 14 }}>
                  English
                </MenuItem>
              </Select>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
