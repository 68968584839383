import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { helpCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import Tutorial from './Tutorial';

const Header = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle className="title">ランデミーこどもの音楽教室 {props.title}</IonTitle>
        <IonButtons slot="end">
          <IonButton
            fill="solid"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            使い方を見る
            <IonIcon slot="end" icon={helpCircle} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <Tutorial isOpen={showModal} />
    </IonHeader>
  );
};

export default Header;
