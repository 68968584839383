import {
  IonButtons,
  IonContent,
  IonFooter,
  IonToast,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from '../hooks/firebase';
import Copyright from '../components/Copyright';
import { AuthContext } from '../hooks/Auth';
import { withRouter } from 'react-router-dom';
import {
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  Theme,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Console from '../hooks/console';
import Header from '../components/Header';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff',
    backgroundColor: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
  linkText: {
    color: '#FF9A00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  },
}));

const sendMail = async (
  to: string,
  recipient_name: string,
  templateId: string,
) => {
  const emailData = {
    to: to,
    from: 'support@langdemy.com',
    cc: '',
    bcc: '',
    replyTo: recipient_name,
    templateId: templateId,
    dynamic_template_data: '',
  };

  firebase.sendEmail(emailData);
};

// クライアント側バリデーションチェック
const validateEmail = (email: string) => {
  let errorType = null;
  const isEmail = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  );

  // 未入力エラー
  if (!email) {
    errorType = 'empty';
    // メールアドレス形式エラー
  } else if (!isEmail.test(email)) {
    errorType = 'invalid';
  }
  return errorType;
};

const validatePassword = (password: string, repassword: string) => {
  let errorType = null;
  const isFullWidthCharacter = new RegExp(/[^\x01-\x7E]/);

  // 未入力エラー
  if (!password) {
    errorType = 'empty';
    // 半角英数記号・桁数エラー
  } else if (
    (password.length <= 5 && password.length >= 1) ||
    isFullWidthCharacter.test(password)
  ) {
    errorType = 'invalid';
    // 確認用パスワードエラー
  } else if (password !== repassword) {
    errorType = 'differentPassword';
  }

  return errorType;
};

const SignUp = (props: any) => {
  // const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isComposed, setIsComposed] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const showToast = () => {
    setToastIsShown(true);
  };
  const [message, setMessage] = useState('');

  useEffect(() => {
    const disabled =
      email === '' ||
      password === '' ||
      fullName === '' ||
      !checked ||
      repassword === '';
    setDisabled(disabled);
    // if logged in, redirect to home
    // currentUser && props.history.push('/Teachers');
  }, [email, password, fullName, checked, repassword]);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <IonPage>
      <Header title="ユーザー登録" />
      <IonContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              ランデミーこどもの音楽教室へようこそ
            </Typography>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              ユーザー登録
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="fname"
                    name="fullName"
                    variant="outlined"
                    required
                    fullWidth
                    id="fullName"
                    label="氏名　必須"
                    autoFocus
                    onChange={(e: any) => setFullName(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setFullName(e.target.value);
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => setIsComposed(true)}
                    onCompositionEnd={() => setIsComposed(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email　必須"
                    name="email"
                    autoComplete="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setEmail(e.target.value);
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => setIsComposed(true)}
                    onCompositionEnd={() => setIsComposed(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      パスワード（6文字以上）　必須
                    </InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      onChange={(e: any) => setPassword(e.target.value)}
                      onKeyDown={(e: any) => {
                        if (isComposed) return;
                        if (e.key === 'Enter') {
                          setPassword(e.target.value);
                          e.preventDefault();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      パスワード（確認用）
                    </InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      name="repassword"
                      type={showPassword ? 'text' : 'password'}
                      id="repassword"
                      autoComplete="current-password"
                      onChange={(e: any) => {
                        setRepassword(e.target.value);
                        if (password === e.target.value) {
                          Console.log('一致するので通って良い');
                        }
                      }}
                      onKeyDown={(e: any) => {
                        if (isComposed) return;
                        if (e.key === 'Enter') {
                          setRepassword(e.target.value);
                          e.preventDefault();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onChange={handleChange}
                        />
                        <Link
                          href="/Terms"
                          variant="body1"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.linkText}
                        >
                          利用規約
                        </Link>
                      </>
                    }
                    label="に同意する"
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={disabled}
                onClick={async () => {
                  switch (validateEmail(email)) {
                    case 'empty':
                      setMessage('Emailを入力してください。');
                      setToastIsShown(true);
                      return;
                    case 'invalid':
                      setMessage(
                        'Emailに不正な文字が入っているため、登録できません。Emailを修正してください。',
                      );
                      setToastIsShown(true);
                      return;
                    default:
                      break;
                  }

                  switch (validatePassword(password, repassword)) {
                    case 'empty':
                      setMessage('パスワードを入力してください。');
                      setToastIsShown(true);
                      return;
                    case 'invalid':
                      setMessage(
                        'パスワードが6桁以上ではないか、もしくは不正な文字が入っているため、登録できません。パスワードを修正してください。',
                      );
                      setToastIsShown(true);
                      return;
                    case 'differentPassword':
                      setMessage(
                        'パスワードがパスワード（確認用）と一致していません。どちらも同じパスワードを設定してください。',
                      );
                      setToastIsShown(true);
                      return;
                    default:
                      break;
                  }

                  await firebase
                    .signUp(email, password)
                    .then(res => {
                      const user = res.user;
                      firebase
                        .setFirestoreUser(user, email, fullName)
                        .then(() => {
                          // Email sent.
                          setMessage(
                            'Emailをお送りしました。受信箱をご確認ください。',
                          );
                          sendMail(
                            email,
                            fullName,
                            'd-b9eb07beac3b44f8a8d6b4b695e36193',
                          );
                          setToastIsShown(true);
                          props.history.push('/Teachers');
                        })
                        .catch(err => { });
                    })
                    .catch(e => {
                      switch (e.code) {
                        case 'auth/email-already-in-use':
                          setMessage(
                            'ユーザー登録が既にされているか、もしくは入力内容に不正な文字が入ったため、登録失敗しました。',
                          );
                          setToastIsShown(true);
                          break;
                        case 'auth/invalid-email':
                          setMessage(
                            'Emailに不正な文字が入っているため、登録できません。Emailを修正してください。',
                          );
                          setToastIsShown(true);
                          break;
                        case 'auth/weak-password':
                          setMessage('パスワードは6文字以上入力してください。');
                          setToastIsShown(true);
                          break;
                        default:
                          setMessage(
                            '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                          );
                          setToastIsShown(true);
                          break;
                      }
                    });
                }}
              >
                ユーザ登録
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link
                    href="/login"
                    variant="body2"
                    className={classes.linkText}
                  >
                    既にアカウントをお持ちの方は、ログイン
                  </Link>
                </Grid>
              </Grid>
            </form>
            <IonToast
              isOpen={toastIsShown}
              onDidDismiss={() => setToastIsShown(false)}
              message={message}
              duration={5000}
            />
          </div>
        </Container>
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <Box mt={8}>
              <Copyright />
            </Box>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(SignUp);
