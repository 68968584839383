import { User } from '@firebase/auth-types';
import React, { createContext, useEffect, useState } from 'react';
import firebase from 'firebase';
import firebaseHooks from './firebase';
import Console from "./console";
import { IonToast } from '@ionic/react';

type AuthContextProps = {
  currentUser: User | null | undefined;
};

const AuthContext = createContext<AuthContextProps>({
  currentUser: undefined,
});

const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] =
    useState<User | null | undefined>(undefined);
  const [toastIsShown, setToastIsShown] = useState(false);
  const [message, setMessage] = useState('');

  const getParam = (name: string) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  useEffect(() => {
    // let unmounted = false;
    (async () => {
      firebase.auth().onAuthStateChanged(async user => {
        setCurrentUser(user);
      });
    })();
    // const cleanup = () => {
    //   unmounted = true;
    // };
    // return cleanup;
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
      }}
    >
      {children}
      <IonToast
        isOpen={toastIsShown}
        onDidDismiss={() => setToastIsShown(false)}
        message={message}
        duration={10000}
      />
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
