import React, { useState, useEffect, useContext } from 'react';
import DecideModal from './DecideModal';
import firebase, { firestore, fieldValue } from '../hooks/firebase';
import fb from 'firebase';
import { AuthContext } from '../hooks/Auth';
import './Reservation.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ja';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from '@material-ui/core/Modal';
import EditedModal from './EditedModal';
import EditModal from './EditModal';
import CustomToolbar from './CustomToolbar';
import { Card, CardContent } from '@material-ui/core';
import Console from '../hooks/console';
import MobileReservation from './MobileReservation';
import { IonToast } from '@ionic/react';

// const localizer = momentLocalizer(moment);
// export const CalendarContext = createContext<any>({});

const width = window.innerWidth;

let performanceStart: number;

const eventChange = async (start: any, end: any, id: any, duration: any) => {
  const dateObj = initDate(start, end);
  firebase.getClassroom(String(id)).update({
    ...dateObj,
    duration: duration,
  });
};

const initDate = (start: moment.MomentInput, end: moment.MomentInput) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return {
    date: {
      year: startMoment.format('YYYY'),
      month: startMoment.format('MM'),
      day: startMoment.format('DD'),
    },
    time: {
      start: {
        hour: startMoment.format('HH'),
        minutes: startMoment.format('mm'),
      },
      end: {
        hour: endMoment.format('HH'),
        minutes: endMoment.format('mm'),
      },
    },
  };
};

const runDelete = async (id: string) => {
  try {
    await firebase.getClassroom(String(id)).delete();
  } catch (err) {}
};

const localizer = momentLocalizer(moment);
const ColoredDateCellWrapper = ({ children }: any) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'white',
    },
  });

type CalenderProps = {
  selectTeacherUid: string;
  history: any;
  specialization: string[];
  teacherName: string;
  authCode: string;
};

const Reservation = (props: CalenderProps) => {
  const { selectTeacherUid, history, specialization, teacherName, authCode } =
    props;
  type EventType = {
    studentName: string;
    start: Date;
    end: Date;
    duration: number;
    uid: string | undefined;
    id: string;
    endTime: number;
  };

  type instructorModel = {
    address: {
      add1: string;
      add2: string;
      city: string;
      state: string;
      zip: string;
    };
    availability: any;
    bankAccount: {
      connectId: string;
    };
    contacts?: {
      email: string;
      lineUid: string;
      mobile: string;
    };
    createdAt: Date;
    createdBy: string;
    profile: {
      name: string;
      pic: { profile: ''; thumbnail: '' };
      uid: string;
    };
    schedule: any;
    updatedAt: Date;
    updatedBy: string;
    workInfo: {
      specialization: string[];
      career: string[];
      category: string[];
      joinedAt: string;
      retiredAt: string;
      status: string;
      reportingTo: string;
    };
  };

  const { currentUser } = useContext(AuthContext);
  // const { currentUser } = useContext(AuthContext);
  // const [teacherUid, setTeacherUid] = useState<string>('SZLn8uuNaj0PRg179n5A');
  // const { currentUser } = useContext(AuthContext);
  // const [teacherUid, setTeacherUid] = useState<string>(
  //   'VyEfs3NDc1PyRzRZpp3eI2GRs2v1',
  // );
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');
  const [studentName, setStudentName] = useState<string>('');
  const [id, setId] = useState<string>(''); //削除する時にデータの照合に使う値です
  const [teacherEventList, setTeacherEventList] = useState<EventType[]>([]); //react-big-calendarのeventsに入れる値です
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false); //予定を変更・削除するモーダルを制御する値です
  const [decideModalIsOpen, setDecideModalIsOpen] = useState<boolean>(false); //予定を決めるモーダルを制御する値です
  const [editModalJudgementNum, setEditModalJudgementNum] = useState<number>(0); //予定がクリックされた時のモーダルを管理する値です。1に変更された時に、編集画面に遷移します。
  const [decidedData, setDecidedData] = useState<any>({}); //予定を変更・削除するモーダルに表示する値です
  const [workingTimeList, setWorkingTimeList] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);

  const showToast = () => {
    setToastIsShown(true);
  };
  const [message, setMessage] = useState('');

  const [currentTeacher, setCurrentTeacher] = useState<instructorModel>();
  const [currentPlan, setCurrentPlan] = useState<any>({}); // 現在サブスク受講中のプランの概要tiers
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>({}); // 現在サブスク受講中のプランのFirestoreに保存されているデータ
  const [currentTicket, setCurrentTicket] = useState<any>({}); // 現在サブスク受講中のプランの概要tiers
  const [paymentTicket, setPaymentTicket] = useState<any>({}); // 現在サブスク受講中のプランのFirestoreに保存されているデータ

  const [checked, setChecked] = useState(false);
  const [offlineNumberOfClasses, setOfflineNumberOfClasses] =
    useState<number>(0);

  let AvailabilityTimeList: any[] = [];
  const [useAvailabilityTime, setUseAvailabilityTime] = useState<any>([]);
  // safariで値をイベントを認識できるDate型を返す
  const [allNotTime, setAllNotTime] = useState(false);
  const [isTicket, setIsTicket] = useState(true);
  const [isAnnounceOpen, setIsAnnounceOpen] = useState(true);
  const [isCompletedAt, setIsCompletedAt] = useState(true);
  const [isEnrolledAt, setIsEnrolledAt] = useState(true);
  const [courseEnrolledForwardCaseData, setCourseEnrolledForwardCaseData] =
    useState<any>(null);
  const [useTitle, setUseTitle] = useState<any>(null);
  const [minute, setMinute] = useState<any>(null);
  const [offlineClasses, setOfflineClasses] = useState(null);
  const [submitCourseEnrolled, setSubmitCourseEnrolled] = useState<any>({});
  const [isdisplayInfo, setIsDisplayInfo] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalOpen = () => {
    setIsModalOpen(true);
  };

  const safariDate = (date: Date) => {
    const jpDate = new Date(date.setHours(date.getHours() + 9));
    const isoDate = jpDate.toISOString();
    return new Date(isoDate.slice(0, 19));
  };
  const now: Date = safariDate(new Date());

  // let formats = {
  //   dateFormat: 'dd',
  //   dayFormat: (date: any, culture: any, localizer: any) =>
  //     localizer.format(date, 'DDD', culture),
  //   dayRangeHeaderFormat: ({ start, end }: any, culture: any, localizer: any) =>
  //     localizer.format(start, { date: 'short' }, culture) + ' — ' +
  //     localizer.format(end, { date: 'short' }, culture)
  // }

  const getUserTicket = async (courseEnrolledData: any, courseIdList: any) => {
    const studentsRef = firebase.getStudent(currentUser?.uid);
    let count = 0; //0ならチケットを持っていないとする。１以上ならチケットを持ってるとする。
    let allowCount = 0; //ダメな条件に合った時にカウントしていく、最後にカウント数が配列のlengthと同じ数なら、コースを複数持っていたとしても、全部ダメな条件にあってはまったとする

    if (courseIdList.length === 0) {
      setIsAnnounceOpen(false);
    }
    const submitCourseEnrolledList: any[] = [];
    const numberOfTicketsList: any[] = []; // チケットの残数が０であるかの確認に使います。
    const includeKeyList = Object.entries(courseEnrolledData).map(([key]) => ({
      key,
    }));
    for (let i = 0; i < includeKeyList.length; i++) {
      const key = includeKeyList[i].key; //定数keyに入るのはこのような文字列 -> courses/prod_JOISbi8V0f8dLl
      numberOfTicketsList.push(
        (courseEnrolledData[key].mode === 'subscription' &&
          (courseEnrolledData[key].completedAt === null ||
            courseEnrolledData[key].completedAt.toDate().getTime() >
              new Date().getTime())) ||
          (courseEnrolledData[key].mode === 'payment' &&
            courseEnrolledData[key].completedAt.toDate().getTime() >
              new Date().getTime())
          ? courseEnrolledData[key].numberOfClasses
          : 0,
      );
      submitCourseEnrolledList.push(courseEnrolledData[key]);
      //チケットが0であるか確認
      for (let i = 0; i < numberOfTicketsList.length; i++) {
        if (numberOfTicketsList[i] !== 0) {
          count = count + 1; //countが0ならチケットがないものとする。ユーザが持っている全てのcourseEnrolledのチケットが０であるかを確認する時に使います
        }
      }
      if (courseEnrolledData[key].numberOfClasses !== 0) {
        //チケットが0ではない場合
        if (courseEnrolledData[key].completedAt !== null) {
          if (now > courseEnrolledData[key].completedAt?.toDate()) {
            //completedAtが今より後の場合
            if (courseEnrolledData[key].status === 'canceled') {
              //statusがcanceledの場合
              setIsCompletedAt(true);
            } else {
              setIsCompletedAt(false);
              allowCount = allowCount + 1;
            }
          }
        }
        if (
          now < courseEnrolledData[key].enrolledAt.toDate() &&
          courseEnrolledData[key].status === 'ongoing'
        ) {
          setIsEnrolledAt(false);
          allowCount = allowCount + 1;
          setCourseEnrolledForwardCaseData(courseEnrolledData[key]);
        }
      }
    }
    if (allowCount === includeKeyList.length) {
      setAllNotTime(true);
    }
    if (count === 0) {
      setIsTicket(false);
    }
  };

  // スマホかどうかの判定
  const isSmartPhone = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(max-device-width: 768px)').matches
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // dateが今日と同じ日付または今日よりも前の日付ならtrue, dateが後の日付ならfalse
  const beforeToday = (date: Date = safariDate(new Date())) => {
    const now = safariDate(new Date());
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    const today = now.getDate();
    if (year == thisYear) {
      if (month == thisMonth) {
        return day >= today;
      } else {
        return month > thisMonth;
      }
    } else {
      return year > thisYear;
    }
  };

  const customSlotPropGetter = (date: Date) => {
    let backgroundColor = '#fff'; // デフォルトに白を設定
    const time = date.getTime();

    teacherEventList.map((item: EventType) => {
      // console.log(item)
      // console.log(item.endTime, time, item.endTime + 15 * 60 * 1000)
      if (item.endTime <= time && time < item.endTime + 15 * 60 * 1000) {
        backgroundColor = '#2A2E27'; // 予約枠に色付
      }
    });

    workingTimeList.map((item: any) => {
      if (
        item.startTime <= time &&
        time < item.endTime &&
        backgroundColor !== '#2A2E27'
      ) {
        backgroundColor = '#DCEDC8'; // 予約枠に色付
      }
    });
    if (
      now.getFullYear() === date.getFullYear() &&
      now.getMonth() === date.getMonth() &&
      now.getDate() === date.getDate() &&
      backgroundColor !== '#DCEDC8'
    ) {
      backgroundColor = '#eaf6ff'; // 予約枠以外の今日の部分に色変え
    }
    // console.log('=================')

    return {
      style: {
        backgroundColor: backgroundColor,
      },
    };
  };

  //予定の背景色の定義をしています。引数の値はreact-big-calendarのeventsに入っている値です。
  const eventBackgroundColor = (event: any) => {
    let backgroundColor;

    //自分の予定は#428cff色、他人の予定は#78909C色
    if (event.uid === currentUser?.uid) {
      backgroundColor = '#428cff';
    } else {
      backgroundColor = '#78909C';
    }

    let style = {
      backgroundColor: backgroundColor,
      // opacity: 0.8,
      color: 'black',
      border: '0px',
    };

    return {
      style: style,
    };
  };

  //日にちの文字の色を定義してます
  const calendarStyle = () => {
    return {
      style: {
        color: '#428cff',
      },
    };
  };

  //予定を変更・削除するモーダルを閉める関数です
  const editModalClose = () => {
    setEditModalIsOpen(false);
    setEditModalJudgementNum(0);
  };

  //予定を決めるモーダルを閉める関数です
  const decideModalClose = () => {
    setDecideModalIsOpen(false);
  };

  //予定を変更・削除するモーダルを開ける関数です
  const editModalOpen = (event: any) => {
    Console.log('edit ' + event);
    // クリックした予定がその人の予定の時のみ、モーダルを開けます
    setId(event.id);
    const data = teacherEventList.find((item: any) => item.id === event.id);
    setDecidedData(data);
    Console.log(data?.start);
    if (!beforeToday(data?.start)) {
      setMessage('予約キャンセルは前日までしかできません。');
      setToastIsShown(true);
      return;
    }

    if (event.uid === currentUser?.uid) {
      setEditModalIsOpen(true);
    }
  };

  // 予定を決めるモーダルを開ける関数です
  const decideModalOpen = (e: any) => {
    let start = e.slots.slice(0, 1)[0];
    let end = e.slots.slice(-1)[0];
    let startDay = moment(start).format('D');
    useAvailabilityTime.map((item: any) => {
      // itemが予約可能時間の情報
      if (moment(item.start).format('D') === startDay) {
        // startの日付が一致しているか確認
        if (
          safariDate(new Date(item.start)) <= safariDate(new Date(start)) &&
          safariDate(new Date(item.end)) >= safariDate(new Date(end))
        ) {
          // 予約枠の枠内に収まるかどうか
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          tomorrow.setHours(0);
          tomorrow.setMinutes(0);
          if (moment(tomorrow).isBefore(e.start)) {
            const stringEnd = String(item.end);
            setDecideModalIsOpen(true);
            setStart(moment(start).format('YYYY-MM-DDTHH:mm'));
            setEnd(moment(stringEnd).format('YYYY-MM-DDTHH:mm'));
            // setDecideModalIsOpen(true);
          } else if (!moment(now).isSameOrBefore(e.start)) {
            alert('現在時刻より前には予約をすることができません');
          } else {
            alert('申し訳ございません。当日予約はできません。');
          }
        }
      }
    });
  };

  type SetDataType = {
    title: string;
    start: Date;
    end: Date;
    duration: number;
    courseId: string;
  };
  // 子要素からデータを受け取りカレンダーにデータを反映、Firestoreに送信する関数です

  const createMeeting = async (setData: SetDataType) => {
    try {
      performanceStart = performance.now();
      const classroomsRef = firebase.newClassroomDoc();
      const dateObj = initDate(setData.start, setData.end);
      classroomsRef.set({ ...dateObj });
      Console.dir('createMeeting setData: ' + JSON.stringify(setData));
      const { start, duration } = setData;
      const teacherDoc: any = await firebase
        .getInstructor(selectTeacherUid)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data();
            if (data === undefined) {
              throw 'snapshot.data() is undefined';
            } else {
              return data;
            }
          } else {
            throw 'nothing teacher document';
          }
        })
        .catch(e => {
          throw 'teacher get error ' + e;
        });
      const classroomKey = `classrooms/${classroomsRef.id}`;
      firebase
        .getInstructor(selectTeacherUid)
        .set(
          {
            schedule: {
              ...teacherDoc.schedule,
              [classroomKey]: {
                status: 'temporaryReservation',
              },
            },
          },
          { merge: true },
        )
        .then(() =>
          Console.log('仮予約完了:', performance.now() - performanceStart),
        );
      const startDate = safariDate(new Date(start));
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startDate.getHours(),
        startDate.getMinutes() + duration,
      );
      const zoomApi = teacherDoc.zoomApi || {};
      if (
        !(
          zoomApi.hasOwnProperty('apiKey') &&
          zoomApi.hasOwnProperty('apiSecret') &&
          zoomApi.hasOwnProperty('email')
        )
      ) {
        setMessage(
          '恐れ入りますが、こちらの講師のZoomアカウントをランデミー運営事務局で設定中です。予約が可能になるまでしばらくお待ち下さい。',
        );
        setToastIsShown(true);
        return;
      }

      const scheduleIdList: string[] = Object.keys(teacherDoc.schedule);
      const reservationIdList: string[] = scheduleIdList
        .filter(id => teacherDoc.schedule[id].status === 'reservation')
        .map(id => id.slice(11));
      const temporaryReservationIdList: string[] = scheduleIdList
        .filter(id => teacherDoc.schedule[id].status === 'temporaryReservation')
        .map(id => id.slice(11));
      Console.log('temporaryReservationIdList:', temporaryReservationIdList);
      const getClass = async (id: string) => {
        return await firebase
          .getClassroom(id)
          .get()
          .then(snapshot => {
            if (snapshot.exists) {
              Console.log(`get success classroomsId = ${id}`);
              const classDoc: any = snapshot.data();
              const d = classDoc.date;
              const s = classDoc.time.start;
              const e = classDoc.time.end;

              return {
                start: new Date(
                  Number(d.year),
                  Number(d.month) - 1,
                  Number(d.day),
                  Number(s.hour),
                  Number(s.minutes),
                ),
                end: new Date(
                  Number(d.year),
                  Number(d.month) - 1,
                  Number(d.day),
                  Number(e.hour),
                  Number(e.minutes),
                ),
              };
            } else {
              Console.log(`data of classroomsId = ${id} is not found`);
              return {};
            }
          })
          .catch(e => Console.error('error in myClassrooms get ' + e));
      };
      const promises: any[] = reservationIdList.map((id: string) =>
        getClass(id),
      );
      const temporaryPromises: any[] = temporaryReservationIdList.map(
        (id: string) => getClass(id),
      );
      const myClassrooms: any[] = await Promise.all(promises)
        .then(value => {
          return value;
        })
        .catch(e => {
          Console.error('error in Promise.all' + e);
          return [];
        });
      const temporaryClassrooms: any[] = await Promise.all(temporaryPromises)
        .then(value => {
          return value;
        })
        .catch(e => {
          Console.error('error in Promise.all' + e);
          return [];
        });

      let isNotPossible = false;
      let isTemporary = false;
      let fifteen = false;
      const sTime = startDate.getTime();
      const eTime = endDate.getTime();
      myClassrooms.forEach((obj: { start: Date; end: Date }) => {
        if (obj.hasOwnProperty('start') && obj.hasOwnProperty('end')) {
          const { start, end } = obj;
          if (
            (sTime <= start.getTime() && start.getTime() < eTime) ||
            (sTime < end.getTime() && end.getTime() <= eTime) ||
            (start.getTime() <= sTime && eTime <= end.getTime())
          ) {
            isNotPossible = true;
          } else if (start.getTime() === eTime || sTime === end.getTime()) {
            fifteen = true;
          }
        }
      });
      temporaryClassrooms.forEach((obj: { start: Date; end: Date }) => {
        if (obj.hasOwnProperty('start') && obj.hasOwnProperty('end')) {
          const { start, end } = obj;
          if (
            (sTime <= start.getTime() && start.getTime() < eTime) ||
            (sTime < end.getTime() && end.getTime() <= eTime) ||
            (start.getTime() <= sTime && eTime <= end.getTime())
          ) {
            isTemporary = true;
          } else if (start.getTime() === eTime || sTime === end.getTime()) {
            isTemporary = true;
          }
        }
      });

      if (isNotPossible) {
        setMessage(
          '既にレッスンの予約が入っています。お手数をおかけいたしますが、画面をリフレッシュして別日程でご予約をお願いいたします。',
        );
        setToastIsShown(true);
        return;
      }
      if (isTemporary) {
        setMessage(
          'たった今この時間の予約が入りました。お手数をおかけいたしますが、画面をリフレッシュして別日程でご予約をお願いいたします。',
        );
        setToastIsShown(true);
        firebase.getInstructor(selectTeacherUid).set(
          {
            schedule: {
              ...teacherDoc.schedule,
              [classroomKey]: {
                status: 'canceled',
              },
            },
          },
          { merge: true },
        );
        return;
      }
      if (fifteen) {
        setMessage(
          '既に予約されているレッスンと15分以上間隔を開けてレッスンを予約してください。',
        );
        setToastIsShown(true);
        return;
      }

      const zoomStartDateWithZ = safariDate(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startDate.getHours() + 9,
          startDate.getMinutes(),
        ),
      );

      // .toISOString().replace(/\..*/, '+09:00');
      Console.log('zoomStartDateWithZ: ' + zoomStartDateWithZ);

      // UTCタイムゾーン部分は消して、日本のタイムゾーンの表記を足す
      let zoomStartDate = zoomStartDateWithZ.toISOString().slice(0, 19); // "2020-04-22T22:39:03+09:00"
      Console.log('zoomStartDate: ' + zoomStartDate);

      // const getTokenEndpoint = `https://zoom.us/oauth/token?grant_type=authorization_code&code=${authCode}&state=${currentUser?.uid}`
      // const options = {
      //   methods: "POST",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     "Authorization": "Basic WENnZjd5OE9SU3BrQ2FkWll4blR3OmpYMzQzRjViUlNpREFaZzVKQzZ5TkM1YW01a2I1M0Z5"
      //   }
      // }
      // const response = await fetch(getTokenEndpoint, options);
      // const OAuthData = await response.json();
      // Console.dir('OAuthData:', OAuthData);

      // const meEndpoint = 'https://api.zoom.us/v2/users/me';

      // const meResponse = await fetch(meEndpoint, {
      //   headers: {
      //     Authorization: 'Bearer ' + OAuthData.access_token,
      //   }
      // });
      // const meJson = await meResponse.json();
      // Console.log("meJson:", meJson);

      const data = {
        start_time: zoomStartDate,
        duration: duration,
        email: currentUser?.email,
        // token: OAuthData.access_token,
        // teacherEmail: meJson.id,
        // apiKey: currentUser?.uid,
        // apiSecret: authCode,
        // teacherEmail: !!zoomApi
        //   ? zoomApi.email
        //   : teacherDoc.contacts?.email,
        // apiKey: zoomApi.apiKey,
        // apiSecret: zoomApi.apiSecret,
        teacherEmail: teacherDoc.zoomApi?.email,
        apiKey: teacherDoc.zoomApi?.apiKey,
        apiSecret: teacherDoc.zoomApi?.apiSecret,
      };
      Console.dir('before call createMeeting data: ' + JSON.stringify(data));
      const zoomResponse: any = await firebase
        .createMeeting(data)
        .then(res => {
          Console.dir('createMeeting then response:', res);
          return res.result;
        })
        .catch(error => {
          Console.error('createMeeting catch error:', error);
          setMessage(
            'レッスンの予約に失敗しました。システムが復旧するまでしばらくお待ちください。',
          );
          setToastIsShown(true);
          firebase.getInstructor(selectTeacherUid).set(
            {
              schedule: {
                ...teacherDoc.schedule,
                [classroomKey]: {
                  status: 'canceled',
                },
              },
            },
            { merge: true },
          );
          return null;
        });
      Console.log('zoomResponse:', zoomResponse);
      if (!zoomResponse) {
        setMessage(
          'レッスンの予約に失敗しました。システムが復旧するまでしばらくお待ちください。',
        );
        setToastIsShown(true);
        firebase.getInstructor(selectTeacherUid).set(
          {
            schedule: {
              ...teacherDoc.schedule,
              [classroomKey]: {
                status: 'canceled',
              },
            },
          },
          { merge: true },
        );
        return;
      }
      // const zoomResponse = { // createMeetingがあるので代わりのレスポンスデータ(ローカル確認用)
      //   host_email: "test",
      //   timezone: "test",
      //   created_at: "test",
      //   join_url: "test",
      //   start_url: "test",
      //   id: "test",
      //   agenda: "test",
      // }
      return await setClassroomDoc(zoomResponse, setData, classroomsRef);
    } catch (e) {
      Console.error(e);
    }
  };
  // firestoreに保存
  const setClassroomDoc = async (
    zoomResponse: any,
    setData: SetDataType,
    classroomsRef: fb.firestore.DocumentReference<fb.firestore.DocumentData>,
  ) => {
    Console.log('setData', setData);
    const { title, start, end, duration, courseId } = setData;
    const studentRef = firebase.getStudent(currentUser?.uid);
    const studentName = await studentRef
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data()?.profile?.name
            ? snapshot.data()?.profile?.name
            : '';
        } else {
          return '';
        }
      })
      .catch(e => {
        Console.error(e);
        return '';
      });
    const instructorRef = firebase.getInstructor(selectTeacherUid);
    const chatroomsRef = firebase.getChatroom(classroomsRef.id);
    const dateObj = initDate(start, end);
    Console.dir(zoomResponse);

    let location = {};
    if (checked) {
      location = {
        online: 'No',
        room: zoomResponse?.id,
      };
    } else {
      location = {
        online: 'Yes',
        room: zoomResponse?.id,
      };
    }

    const data = {
      ...dateObj,
      id: classroomsRef.id,
      title: studentName,
      // summary: zoomResponse,
      courseId: 'courses/' + courseId,
      invitees: {
        students: [studentRef],
        instructors: [instructorRef],
      },
      // attendees: {
      //   students: [studentRef],
      //   instructors: [instructorRef],
      // },
      attendees: null,
      location: location,
      zoomResponse: {
        host_email: zoomResponse?.host_email,
        duration: duration,
        timezone: zoomResponse?.timezone,
        created_at: zoomResponse?.created_at,
        join_url: zoomResponse?.join_url,
        start_url: zoomResponse?.start_url,
      },
      createdAt: safariDate(new Date()),
      createdBy: currentUser?.uid,
      updatedAt: safariDate(new Date()),
      updatedBy: currentUser?.uid,
    };

    const event: EventType = {
      studentName: studentName,
      start: safariDate(new Date(start)),
      end: safariDate(new Date(end)),
      duration: duration,
      uid: currentUser?.uid,
      id: classroomsRef.id,
      endTime: new Date(end).getTime(),
    };
    setTeacherEventList((state: any) => [...state, event]);
    const classroomKey = `classrooms/${classroomsRef.id}`;
    const courseKey = `courses/${courseId}`;
    const { courseEnrolled } = await firebase.getCourseEnrolledData(
      currentUser?.uid,
    );
    const courseData = courseEnrolled[courseKey];
    let processCourseData = {};
    if (checked) {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(-1),
        offlineNumberOfClasses: fieldValue.increment(-1),
      };
    } else {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(-1),
      };
    }
    Console.log('courseData.numberOfClasses', courseData.numberOfClasses);
    Console.log('processCourseData', processCourseData);
    const studentSchedule = {
      courseEnrolled: {
        ...courseEnrolled,
        [courseKey]: processCourseData,
      },
      schedule: {
        [classroomKey]: {
          status: 'reservation',
        },
      },
    };
    const instructorSchedule = {
      schedule: {
        [classroomKey]: {
          status: 'reservation',
        },
      },
    };

    const chatroomsData = {
      teacherUid: selectTeacherUid,
      studentsUid: currentUser?.uid,
      messages: [],
    };
    const batch = firestore.batch();
    batch.set(chatroomsRef, chatroomsData);
    batch.set(instructorRef, instructorSchedule, { merge: true });
    batch.set(studentRef, studentSchedule, { merge: true });
    batch.set(classroomsRef, data);
    batch
      .commit()
      .then(async () => {
        Console.log('success batch in calender.js');
        setMessage('Emailをお送りしました。受信箱をご確認ください。');

        // 生徒へ予約確定Emailを送信
        const studentInfo = await studentRef.get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
        });
        Console.dir('studentInfo' + JSON.stringify(studentInfo));
        const studentName = studentInfo?.profile?.name;
        const studentEmail = studentInfo?.contacts?.email;
        if (checked) {
          // 対面レッスン
          sendMail(
            studentEmail,
            studentName,
            'd-dda1807a86e54581840963db80c52bef',
          );
        } else {
          // オンラインレッスン
          sendMail(
            studentEmail,
            studentName,
            'd-8694ae2b757a4d55ba881058eea16112',
          );
        }

        // 講師へ予約確定Emailを送信
        const instructorInfo = await instructorRef.get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
        });
        Console.dir('instructorInfo' + JSON.stringify(instructorInfo));
        const instructorName = instructorInfo?.profile?.name;
        const instructorEmail = instructorInfo?.contacts?.email;
        if (checked) {
          sendMail(
            instructorEmail,
            instructorName,
            'd-d6981b5c24e94bfe9e7578008805639d',
          );
        } else {
          sendMail(
            instructorEmail,
            instructorName,
            'd-5076435f70dd462baad4f74e499e9cc5',
          );
        }

        setToastIsShown(true);
        Console.log('予約完了:', performance.now() - performanceStart);
        history.push('/profile');
      })
      .catch(e => Console.error('batch error in calender.js: ' + e));
  };

  const sendMail = async (
    to: string,
    recipient_name: string,
    templateId: string,
  ) => {
    const emailData = {
      to: to,
      from: 'support@langdemy.com',
      cc: '',
      bcc: '',
      replyTo: recipient_name,
      templateId: templateId,
      dynamic_template_data: '',
    };

    firebase.sendEmail(emailData);
  };

  //子要素から送られてくる子要素のidを受け取り、子要素を除いたデータを返し、子要素から送られてきたidを持つデータをFireStoreから消去する関数です
  const deleteFunc = () => {
    const data = teacherEventList.filter((item: any) => item.id !== id);
    setTeacherEventList(data);
    changeStatus();
  };

  const changeStatus = async () => {
    const isOnline = await firebase
      .getClassroom(id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          const data: any = snapshot.data();
          return data?.location?.online;
        } else {
          Console.log('classrooms data not found');
          return '';
        }
      });
    const courseId = await firebase
      .getClassroom(id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          const data: any = snapshot.data();
          const id: string = data.courseId;
          return id.slice(8);
        } else {
          Console.log('classrooms data not found');
          return '';
        }
      })
      .catch(e => Console.error(e));
    const batch = firestore.batch();
    const studentRef = firebase.getStudent(currentUser?.uid);
    const instructorRef = firebase.getInstructor(selectTeacherUid);

    const classroomKey = `classrooms/${id}`;
    const courseKey = `courses/${courseId}`;
    const { courseEnrolled } = await firebase.getCourseEnrolledData(
      currentUser?.uid,
    );
    const courseData = courseEnrolled[courseKey];

    let processCourseData = {};
    if (isOnline === 'No') {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(+1),
        offlineNumberOfClasses: fieldValue.increment(+1),
      };
    } else if (isOnline === 'Yes') {
      processCourseData = {
        ...courseData,
        numberOfClasses: fieldValue.increment(+1),
      };
    }
    const studentSchedule = {
      courseEnrolled: {
        ...courseEnrolled,
        [courseKey]: processCourseData,
      },
      schedule: {
        [classroomKey]: {
          status: 'canceled',
        },
      },
    };

    const instructorSchedule = {
      schedule: {
        [classroomKey]: { status: 'canceled' },
      },
    };
    batch.set(studentRef, studentSchedule, { merge: true }); // 生徒のスケジュールを更新
    batch.set(instructorRef, instructorSchedule, { merge: true }); // 講師のスケジュールを更新
    batch
      .commit()
      .then(async () => {
        setMessage('Emailをお送りしました。受信箱をご確認ください。');

        // 生徒へレッスン予約キャンセルEmailを送信する
        const studentInfo = await studentRef.get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
        });
        Console.dir('studentInfo' + JSON.stringify(studentInfo));
        const studentName = studentInfo?.profile?.name;
        const studentEmail = studentInfo?.contacts?.email;
        sendMail(
          studentEmail,
          studentName,
          'd-8d6663bfa1044a2eac26e4e20920d62d',
        );

        // 講師へレッスン予約キャンセルEmailを送信する
        const instructorInfo = await instructorRef.get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
        });
        Console.dir('instructorInfo' + JSON.stringify(instructorInfo));
        const instructorName = instructorInfo?.profile?.name;
        const instructorEmail = instructorInfo?.contacts?.email;
        sendMail(
          instructorEmail,
          instructorName,
          'd-b9021df325054da6a1b903a7af928e7a',
        );

        setToastIsShown(true);
      })
      .catch(err => {
        Console.error(err);
      });
    await runDelete(id);
  };

  // 子要素からデータを受け取り、変換したデータをカレンダーに反映、Firestoreに送信する関数です
  const changeFunc = (item: any) => {
    let changedEventData = teacherEventList.filter(
      (data: any) => data.id !== item.id,
    );
    const data: EventType = {
      studentName: item.title,
      start: safariDate(new Date(item.start)),
      end: safariDate(new Date(item.end)),
      id: item.id,
      duration: item.duration,
      uid: currentUser?.uid,
      endTime: new Date(item.end).getTime(),
    };
    eventChange(item.start, item.end, item.id, item.duration);
    changedEventData.push(data);
    setTeacherEventList(changedEventData);
  };

  const openCalendar = () => {
    setIsOpen(true);
  };

  const courseChange = async (item: any, id: string) => {
    setUseTitle(item.title);
    setMinute(item.minute);
    setSubmitCourseEnrolled(item);
    setIsDisplayInfo(false);
    setOfflineClasses(item?.offlineClasses);
    const { courseEnrolled } = await firebase.getCourseEnrolledData(id);
    const offlineNumberOfClasses =
      courseEnrolled[`courses/${item.id}`]?.offlineNumberOfClasses;
    setOfflineNumberOfClasses(offlineNumberOfClasses);
  };

  useEffect(() => {
    Console.log('useEffect in Reservation.tsx');
    Console.log('currentUser: ' + currentUser?.uid);
    (async () => {
      if (currentUser) {
        isSmartPhone();
        const { courseEnrolled, courseIdList } =
          await firebase.getCourseEnrolledData(currentUser?.uid);
        const nowPlanId = courseIdList.filter(id => {
          // ongoingでcompletedAtがnull or canceledでcompletedAtが今より前の時間
          const courseData = courseEnrolled[id];
          const completedAt =
            courseData.completedAt === null
              ? null
              : courseData.completedAt?.toDate();
          const isBefore =
            completedAt === null ? false : beforeToday(completedAt);
          return (
            (courseData.status === 'ongoing' &&
              completedAt === null &&
              courseData.mode === 'subscription') ||
            (courseData.status === 'canceled' &&
              isBefore &&
              courseData.mode === 'subscription') ||
            (isBefore && courseData.mode === 'payment')
          );
        });
        nowPlanId.forEach(async id => {
          const tier: any =
            (await firebase.findTier(courseEnrolled[id].id)) || {};
          if (tier.mode === 'subscription') {
            setCurrentPlan(tier);
            setSubscriptionPlan(courseEnrolled[id]);
          } else {
            setCurrentTicket(tier);
            setPaymentTicket(courseEnrolled[id]);
          }
        });

        let instructorData: any;
        let getMonth;
        let getDay;
        let getData;
        let availabilityTime: any[] = [];

        firebase
          .searchInstructors()
          .get()
          .then(async snapshot => {
            await snapshot.forEach(snapshot => {
              const getInstructorsData = snapshot.data();
              if (getInstructorsData.profile.uid === selectTeacherUid) {
                instructorData = getInstructorsData;
              }
            });

            Console.dir(instructorData);

            if (instructorData.availability !== undefined) {
              const getYear = Object.keys(instructorData.availability);
              getYear.map((item: any) => {
                let getYear = item;
                getMonth = Object.keys(instructorData.availability[getYear]);
                getMonth.map((item: any) => {
                  let getMonth = item;
                  getDay = Object.keys(
                    instructorData.availability[getYear][getMonth],
                  );
                  getDay.map((item: any) => {
                    let getDay = item;
                    getData = Object.keys(
                      instructorData.availability[getYear][getMonth][getDay],
                    );
                    getData.map((item: any, index: any) => {
                      let setTime =
                        instructorData.availability[getYear][getMonth][getDay][
                          index
                        ];
                      const pushNumberTime = {
                        year: Number(getYear),
                        month: Number(Number(getMonth) - 1),
                        // day: Number(getDay.substr(1)),
                        day: Number(getDay),
                        startHour: Number(setTime.start.substr(0, 2)),
                        startMinutes: Number(setTime.start.substr(2, 2)),
                        endHour: Number(setTime.end.substr(0, 2)),
                        endMinutes: Number(setTime.end.substr(2, 2)),
                        startTime: new Date(
                          Number(getYear),
                          Number(Number(getMonth) - 1),
                          Number(getDay),
                          Number(setTime.start.substr(0, 2)),
                          Number(setTime.start.substr(2, 2)),
                        ).getTime(),
                        endTime: new Date(
                          Number(getYear),
                          Number(Number(getMonth) - 1),
                          Number(getDay),
                          Number(setTime.end.substr(0, 2)),
                          Number(setTime.end.substr(2, 2)),
                        ).getTime(),
                      };
                      const startTime = `${getYear}-${getMonth}-${getDay} ${setTime.start.substr(
                        0,
                        2,
                      )}:${setTime.start.substr(2, 2)}`;
                      const endTime = `${getYear}-${getMonth}-${getDay} ${setTime.end.substr(
                        0,
                        2,
                      )}:${setTime.end.substr(2, 2)}`;

                      const eventData = {
                        start: startTime,
                        end: endTime,
                        id: setTime.id,
                      };
                      availabilityTime.push(pushNumberTime);
                      useAvailabilityTime.push(eventData);
                      AvailabilityTimeList.push(eventData);
                    });
                  });
                });
              });
            }

            // setTeacherEventList(teacherEventList);
            setWorkingTimeList(availabilityTime);
            openCalendar();
            setUseAvailabilityTime(AvailabilityTimeList);
          });

        firebase
          .searchStudents()
          .get()
          .then(snapshot => {
            snapshot.forEach(snapshot => {
              if (snapshot.data().profile?.uid === currentUser?.uid) {
                setStudentName(snapshot.data().profile.name);
              }
            });
          });

        const classRoomsData: any[] = [];
        let myData: any[] = [];
        let strangerData: any[] = [];
        let uid = '';
        let teacherInfo: any;
        let studentInfo: any;

        firebase
          .searchClassrooms()
          .get()
          .then(async snapshot => {
            snapshot.forEach(async (snapshot: any) => {
              try {
                classRoomsData.push(snapshot.data());
              } catch (e) {}
            });

            async function createEventList() {
              let studentName;
              // let title2: any[] = [];
              await Promise.all(
                classRoomsData.map(async item => {
                  const getTeacherInfo =
                    await item.invitees?.instructors[0].get();
                  const getStudentInfo = await item.invitees?.students[0].get();

                  teacherInfo = await getTeacherInfo;
                  studentInfo = await getStudentInfo;

                  if (teacherInfo?.data()?.profile?.uid === selectTeacherUid) {
                    if (studentInfo.data() !== undefined) {
                      uid = studentInfo.data().profile.uid;
                    }

                    if (uid === currentUser?.uid) {
                      myData.push(item);
                      studentName = studentInfo.data().profile.name;
                    }

                    if (uid !== currentUser?.uid) {
                      strangerData.push(item);
                    }
                  }
                }),
              );
              const list: any[] = [];

              async function sortData(data: any, id: any, title1: any) {
                await Promise.all(
                  data.map(async (item: any) => {
                    const start = moment(
                      `${item.date.year}${item.date.month}${item.date.day}${item.time.start.hour}${item.time.start.minutes}`,
                      'YYYY-MM-DDTHH:mm',
                    ).format('YYYY-MM-DDTHH:mm');

                    const end = moment(
                      `${item.date.year}${item.date.month}${item.date.day}${item.time.end.hour}${item.time.end.minutes}`,
                      'YYYY-MM-DDTHH:mm',
                    ).format('YYYY-MM-DDTHH:mm');
                    data = {
                      title: title1,
                      start: safariDate(new Date(start)),
                      end: safariDate(new Date(end)),
                      id: item.id,
                      duration: item.duration,
                      uid: id,
                      endTime: new Date(end).getTime(),
                    };
                    list.push(data);
                  }),
                );
              }

              sortData(strangerData, '', '予約が入っています');
              sortData(myData, currentUser?.uid, studentName);
              setTeacherEventList(list);
            }
            await createEventList();
          });
      }
    })();
  }, [currentUser, history]);

  return (
    <div>
      {/* 予定がクリックされた時はこちらが開きます */}
      <Modal open={editModalIsOpen} onClose={editModalClose}>
        <div>
          {editModalJudgementNum === 0 ? (
            <EditModal
              decidedData={decidedData}
              editModalClose={editModalClose}
              deleteFunc={deleteFunc}
              isEdited={true}
            />
          ) : (
            <EditedModal
              decidedData={decidedData}
              changeFunc={changeFunc}
              editModalClose={editModalClose}
              eventList={teacherEventList}
              editId={id}
              useAvailabilityTime={useAvailabilityTime}
            />
          )}
        </div>
      </Modal>
      {/* テーブルがクリックされた時はこちらが開きます */}
      <Modal open={decideModalIsOpen}>
        <div>
          <DecideModal
            start={start}
            end={end}
            title={studentName}
            submitFunc={createMeeting}
            decideModalClose={decideModalClose}
            eventList={teacherEventList}
            history={history}
            specialization={specialization}
            teacherName={teacherName}
            checked={checked}
            setChecked={setChecked}
            offlineNumberOfClasses={offlineNumberOfClasses}
            isEnrolledAt={isEnrolledAt}
            isCompletedAt={isCompletedAt}
            isAnnounceOpen={isAnnounceOpen}
            isTicket={isTicket}
            allNotTime={allNotTime}
            courseEnrolledForwardCaseData={courseEnrolledForwardCaseData}
            useTitle={useTitle}
            minute={minute}
            submitCourseEnrolled={submitCourseEnrolled}
            isdisplayInfo={isdisplayInfo}
            offlineClasses={offlineClasses}
            setUseTitle={setUseTitle}
            setMinute={setMinute}
            setSubmitCourseEnrolled={setSubmitCourseEnrolled}
            setOfflineClasses={setOfflineClasses}
            setOfflineNumberOfClasses={setOfflineNumberOfClasses}
            isModalOpen={isModalOpen}
            getUserTicket={getUserTicket}
            courseChange={courseChange}
            modalOpen={modalOpen}
          />
        </div>
      </Modal>
      <div>
        {isOpen && (
          <>
            {isMobile ? (
              <>
                <MobileReservation
                  selectTeacherUid={selectTeacherUid}
                  createMeeting={createMeeting}
                />
              </>
            ) : (
              <>
                <Calendar
                  selectable={true}
                  timeslots={4}
                  step={15}
                  // className="calendar-box"
                  localizer={localizer}
                  defaultDate={safariDate(new Date())}
                  // 平日のみは
                  // defaultView="work_week"
                  // views={{ work_week: true}}
                  defaultView="week"
                  views={{ week: true }}
                  events={teacherEventList}
                  eventPropGetter={eventBackgroundColor}
                  dayPropGetter={calendarStyle}
                  // onSelectEvent={editModalOpen}
                  slotPropGetter={customSlotPropGetter}
                  onSelectSlot={decideModalOpen}
                  min={safariDate(
                    new Date(
                      now.getFullYear(),
                      now.getMonth(),
                      now.getDate(),
                      10,
                    ),
                  )}
                  max={safariDate(
                    new Date(
                      now.getFullYear(),
                      now.getMonth(),
                      now.getDate(),
                      21,
                    ),
                  )}
                  // longPressThreshold={10}
                  onSelectEvent={event => editModalOpen(event)}
                  popup
                  // onNavigate={(focusDate, flipUnit, prevOrNext) => updateCalendarData(focusDate, prevOrNext)}
                  // onSelectEvent={(data) => this.showModalInfo(data)}
                  // events={this.state.data}
                  components={{ toolbar: CustomToolbar }}
                  // formats={formats}
                />
              </>
            )}
          </>
        )}
      </div>
      <IonToast
        isOpen={toastIsShown}
        onDidDismiss={() => setToastIsShown(false)}
        message={message}
        duration={7000}
      />
      {/* </Calendar> */}
    </div>
  );
};

export default Reservation;
