import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    myCard: {
      // 講師がログインしているとき自分のカードの色を変える
      maxWidth: 345,
      backgroundColor: 'eeeeee',
    },
    grid: {
      margin: theme.spacing(1),
    },
    card: {
      paddingBottom: '0px',
    },
    media: {
      margin: theme.spacing(1),
      height: 0,
      paddingTop: '100%', // 4:3
    },
    button: {
      color: '#ffffff',
      backgroundColor: '#FF9A00',
      fontWeight: 'bold',
      fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    category: {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
    },
    career: {
      merginTop: '0px',
      merginButtom: '0px',
    },
  }),
);

export const Teacher = (props: { data: any[] }) => {
  const classes = useStyles();
  return (
    <div id="teacher" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>講師陣</h2>
          <p>
            管楽器、打楽器、声楽、ボイストレーニング、声質改善トレーニング等、幅広いニーズにお応え出来るよう、レッスンを提供しております。
          </p>
        </div>

        <div id="row">
          {props.data?.map(
            (
              d: {
                name: string | undefined;
                career1: string | undefined;
                career2: string | undefined;
                img: string | undefined;
                job1: string | undefined;
                job2: string | undefined;
              },
              i: any,
            ) => (
              <div key={`${d.name}-${i}`} className="col-md-3 offset-md-2">
                <div className="thumbnail">
                  <h5>{d.name}</h5>
                  <h4 className={classes.career}>{d.career1}</h4>
                  <h4 className={classes.career}>{d.career2}</h4>
                  <img src={d.img} alt="..." className="teacher-img" />
                  <div className="caption">
                    <p>{d.job1}</p>
                    <p>{d.job2}</p>
                  </div>
                  <ResponsiveDialog props={d} />
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
