import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Container from '@material-ui/core/Container';
import { IonContent } from '@ionic/react';
import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

export const Law = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [open]);

  return (
    <div>
      <IonContent>
        {/* <Fab variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}>プロフィールを見る ></Fab> */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'特定商取引法に基づく表記'}
          </DialogTitle>
          <DialogContent>
            <Container>
              <ReactMarkdown>
                {`
会社名
Langdemy(ランデミー)株式会社

事業者の名称
ランデミーこどもの音楽教室

事業者の所在地
郵便番号 ：107-0062

住所 ：東京都港区南青山4-16-11

事業者の連絡先
電話番号 ： 050-5832-6278

営業時間：平日10:00-21:00
定休日：土日祝日

※上記営業時間帯に電話をお受けできない場合がございます。その場合は、大変お手数をおかけいたしますが、ランデミー LINE公式アカウントにて、ご用件をお伝えいただきますと幸いです。

ランデミー LINE公式アカウントのURLはこちらになります。
https://line.me/ti/p/@187gljwu

販売価格について
販売価格は、決済代行会社（Stripe）で表示された金額と致します。

代金（対価）の支払方法と時期
支払方法：クレジットカード決済、Apple Pay(iOSのみ)がご利用頂けます。
支払時期：コース決済時でお支払いが確定致します。

返品についての特約に関する事項
商品の特性上、返品には応じることが出来かねますので、ご了承ください。

`}
              </ReactMarkdown>
            </Container>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              閉じる
            </Button>
          </DialogActions> */}
        </Dialog>
      </IonContent>
    </div>
  );
};
