import React, { useState, useContext, useEffect } from 'react';
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import { withRouter } from 'react-router-dom';
import { initTier } from '../data/planData';
import Console from '../hooks/console';
import { PlanChild } from '../components/PlanChild';
import Header from '../components/Header';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(4),
    },
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
    },
  },
  cancelTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
  },
  cardLabel: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(1),
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
  },
  cardPricing: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
  },
  cardButton: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      margin: theme.spacing(1),
    },
  },
  dividerComponent: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
    },
  },
  divider: {
    border: 'none',
    borderTop: 'dotted 1px #000000',
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
  },
  modalWidth: {
    width: '900px',
  },
  center: {
    margin: 'auto',
  },
  modalTicket: {
    margin: 'auto',
  },
  close: {
    margin: 'auto',
    width: '100',
  },
}));

const Plans = props => {
  const { currentUser } = useContext(AuthContext);
  const { history } = props;
  const classes = useStyles();
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [availableTrial, setAvailableTrial] = useState(false);
  const [availableVoiceTrial, setAvailableVoiceTrial] = useState(false);
  // const [plan, setPlan] = useState('basic'); // プルダウンのプラン
  const [planType, setPlanType] = useState(null); // チケットが選択された場合 "basic" or "ondai" or "takaraduka" or "voiceTraining"
  const [ticketType, setTicketType] = useState(null);
  const [plusOne, setPlusOne] = useState([]); // 追加で頼むチケットのオブジェクト
  const [plusVoiceOne, setPlusVoiceOne] = useState([]); // 追加で頼むチケットのオブジェクト
  const [hasPurchasedVoiceTraining, setHasPurchasedVoiceTraining] =
    useState(false); // ボイスマスタープログラムに入会しているかどうか

  const [
    availableVoiceTrainingNumberOfClasses,
    setAvailableVoiceTrainingNumberOfClasses,
  ] = useState(null); // ボイトレの追加チケットの残り回数
  // courseEnrolledの構成
  // ・使える
  //   ・サブスク
  //     ①契約中のサブスクプラン (単)
  //     ②契約期間中だけど解約したサブスクプラン (単)
  //   ・チケット
  //     ③-1有効期限内の1枚チケット (単)
  //     ③-2有効期限内の1枚トライアルチケット (単)
  // ・使えない
  //   ・サブスク
  //     ④次の更新日から有効になるプラン (単)
  //     ⑤解約して有効期限もきれたプラン (複)
  //   ・チケット
  //     ⑥枚数が0枚 (複)
  //     ⑦枚数は1枚あるけど有効期限切れ (複)
  const [currentTier, setCurrentTier] = useState(null); // ①契約中のサブスクプラン (単) のtiers
  const [currentPlan, setCurrentPlan] = useState({}); // ①契約中のサブスクプラン (単) のFirestoreに保存されているデータ
  const [currentVoiceTrainingTier, setCurrentVoiceTrainingTier] =
    useState(null); // ボイトレの契約チケットのtiers
  const [currentVoiceTrainingPlan, setCurrentVoiceTrainingPlan] = useState({}); // ボイスマスタープログラムの契約プラン
  const [
    currentVoiceTrainingInstallmentTier,
    setCurrentVoiceTrainingInstallmentTier,
  ] = useState(null); // ボイトレの分割払い契約チケットのtiers
  const [
    currentVoiceTrainingInstallmentPlan,
    setCurrentVoiceTrainingInstallmentPlan,
  ] = useState(null); // ボイトレの分割払い契約チケットのデータ
  const [currentCanceledTier, setCurrentCanceledTier] = useState(null); // ②契約期間中だけど解約したサブスクプラン (単) のtiers
  const [currentCanceledPlan, setCurrentCanceledPlan] = useState({}); // ②契約期間中だけど解約したサブスクプラン (単) のFirestoreに保存されているデータ
  const [availableZeroTicketTier, setAvailableZeroTicketTier] = useState(null); // ③-0-1有効期限内の0枚チケット (単) のtiers
  const [availableZeroTicketData, setAvailableZeroTicketData] = useState({}); // ③-0-1有効期限内の0枚チケット (単) のFirestoreに保存されているデータ
  const [availableZeroTrialTier, setAvailableZeroTrialTier] = useState(null); // ③-0-2有効期限内の0枚トライアルチケット (単) のtiers
  const [availableZeroTrialData, setAvailableZeroTrialData] = useState({}); // ③-0-2有効期限内の0枚トライアルチケット (単) のFirestoreに保存されているデータ
  const [availableTicketTier, setAvailableTicketTier] = useState(null); // ③-1有効期限内の1枚チケット (単) のtiers
  const [availableTicketData, setAvailableTicketData] = useState({}); // ③-1有効期限内の1枚チケット (単) のFirestoreに保存されているデータ
  const [
    availableVoiceTrainingTicketTier,
    setAvailableVoiceTrainingTicketTier,
  ] = useState(null); // ボイトレの追加チケットのtiers
  const [
    availableVoiceTrainingTicketData,
    setAvailableVoiceTrainingTicketData,
  ] = useState({}); // ボイトレの追加チケットのデータ
  const [availableTrialTier, setAvailableTrialTier] = useState(null); // ③-2有効期限内の1枚トライアルチケット (単) のtiers
  const [availableTrialData, setAvailableTrialData] = useState({}); // ③-2有効期限内の1枚トライアルチケット (単) のFirestoreに保存されているデータ
  const [availableVoiceTrainingTrialTier, setAvailableVoiceTrainingTrialTier] =
    useState(null); // ③-2有効期限内の1枚ボイトレトライアルチケット (単) のtiers
  const [availableVoiceTrainingTrialData, setAvailableVoiceTrainingTrialData] =
    useState({}); // ③-2有効期限内の1枚ボイトレトライアルチケット (単) のFirestoreに保存されているデータ
  const [nextTier, setNextTier] = useState(null); // ④次の更新日から有効になるプラン (単) のtiers
  const [nextPlan, setNextPlan] = useState({}); // ④次の更新日から有効になるプラン (単) Firestoreに保存されているデータ
  const [canceledTier, setCanceledTier] = useState(null); // ⑤解約して有効期限もきれたプラン (複) のtiers
  const [canceledPlan, setCanceledPlan] = useState({}); // ⑤解約して有効期限もきれたプラン (複) のFirestoreに保存されているデータ
  const [tiers, setTiers] = useState([initTier]);
  const [instrumentTrial, setInstrumentTrial] = useState(initTier);
  const [voiceTrial, setVoiceTrial] = useState(initTier);
  const [isRendering, setIsRendering] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const [coursePeriod, setCoursePeriod] = useState(1);

  const now = new Date();

  // dateが今日と同じ日付または今日よりも前の日付ならtrue, dateが後の日付ならfalse
  const beforeToday = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const thisYear = now.getFullYear();
    const thisMonth = now.getMonth() + 1;
    const today = now.getDate();

    if (year === thisYear) {
      if (month === thisMonth) {
        return day >= today;
      } else {
        return month > thisMonth;
      }
    } else {
      return year > thisYear;
    }
  };

  useEffect(() => {
    (async () => {
      if (currentUser) {
        const isEmailVerify = currentUser?.emailVerified;
        setIsEmailVerify(isEmailVerify);
        if (!isEmailVerify) {
          const email = await firebase
            .getStudent(currentUser?.uid)
            .get()
            .then(snapshot => {
              return snapshot.data()?.contacts?.email;
            });
          // 確認Emailを送信
          firebase.verifyEmail(email).then(() => {
            setToastIsShown(true);
          });
        }
        const hasPurchasedVoiceTraining = await firebase
          .getStudent(currentUser?.uid)
          .get()
          .then(snapshot => {
            if (snapshot.exists) {
              const data = snapshot.data();
              return data.setting?.enrollment?.hasPurchasedVoiceTraining;
            } else {
              Console.log('uid data not found');
            }
          });
        setHasPurchasedVoiceTraining(hasPurchasedVoiceTraining);
        const { availableTrial, availableVoiceTrial } =
          await firebase.getCourseEnrolledData(currentUser?.uid);
        setAvailableTrial(availableTrial);
        setAvailableVoiceTrial(availableVoiceTrial);
        const { courseEnrolled, courseIdList } =
          await firebase.getCourseEnrolledData(currentUser?.uid);
        const getTiers = await firebase.tiers();
        setTiers(getTiers);
        const instrumentTrialData = getTiers.find(
          tier =>
            tier.planType === 'instrumentTrial' && tier.status === 'active',
        );
        setInstrumentTrial(instrumentTrialData);
        const voiceTrialData = getTiers.find(
          tier => tier.planType === 'voiceTrial' && tier.status === 'active',
        );
        setVoiceTrial(voiceTrialData);

        const today = new Date();
        let latestDate = new Date(today.setMonth(today.getMonth() - 1));
        courseIdList.forEach(async id => {
          // ongoingでcompletedAtがnull or canceledでcompletedAtが今より先の時間
          const courseData = courseEnrolled[id];
          const enrolledAt = courseData?.enrolledAt?.toDate();
          const completedAt =
            courseData?.completedAt === null
              ? null
              : courseData?.completedAt?.toDate();
          const numberOfClasses = courseData.numberOfClasses;
          const status = courseData.status;
          const mode = courseData.mode;
          const tier =
            getTiers.find(tier => tier.id === courseEnrolled[id].id) || {};
          const courseId = id.replace('courses/', '');
          const planType = await firebase
            .getCourse(courseId)
            .get()
            .then(snapshot => {
              const courseData = snapshot.data();
              const planType = courseData?.tier?.planType;
              return planType;
            });
          const ticketType = await firebase
            .getCourse(courseId)
            .get()
            .then(snapshot => {
              const data = snapshot.data();
              const ticketType = data?.tier?.ticketType;
              return ticketType;
            });
          setTicketType(ticketType);
          if (planType === 'voiceTraining' && ticketType === 'addTicket') {
            setAvailableVoiceTrainingNumberOfClasses(numberOfClasses);
            Console.log('numberOfClasses', numberOfClasses);
          }
          await firebase
            .getCourse(courseId)
            .get()
            .then(snapshot => {
              const data = snapshot.data();
              const period = data?.tier?.period;
              let price = '';
              if (period === 1) {
                price = '137500';
              } else if (period === 3) {
                price = '130625';
              } else if (period === 6) {
                price = '123750';
              }
              setPlusVoiceOne(
                getTiers.filter(
                  t => t.ticketType === 'addTicket' && t.price === price,
                ),
              );
            });

          // チケットの表示を制御しているif文
          if (
            status === 'ongoing' &&
            mode === 'payment' &&
            enrolledAt.getTime() < now.getTime() &&
            tier.planType === 'voiceTraining' &&
            ticketType === 'regularTicket'
          ) {
            // ボイトレの契約チケット
            setCurrentVoiceTrainingTier(tier);
            setCurrentVoiceTrainingPlan(courseData);
            setPlanType(tier.planType);
            setCoursePeriod(tier.period);
            Console.log('currentVoiceTrainingTier', tier);
          } else if (
            status === 'ongoing' &&
            mode === 'payment' &&
            enrolledAt.getTime() < now.getTime() &&
            tier.planType === 'voiceTraining' &&
            ticketType === 'addTicket'
          ) {
            // ボイトレの追加チケット
            setAvailableVoiceTrainingTicketTier(tier);
            setAvailableVoiceTrainingTicketData(courseData);
            setPlanType(tier.planType);
            Console.log('availableVoiceTrainingTicketTier', tier);
          } else if (
            status === 'ongoing' &&
            completedAt === null &&
            mode === 'subscription' &&
            enrolledAt.getTime() < now.getTime() &&
            tier.planType === 'voiceTraining'
          ) {
            // ボイトレの分割プラン
            setCurrentVoiceTrainingInstallmentTier(tier);
            setCurrentVoiceTrainingInstallmentPlan(courseData);
            setPlanType(tier.planType);
            setCoursePeriod(tier.period);
            Console.log('currentVoiceTrainingInstallmentTier', tier);
          } else if (
            status === 'ongoing' &&
            completedAt === null &&
            mode === 'subscription' &&
            enrolledAt.getTime() < now.getTime() &&
            (tier.planType === 'ondai' || tier.planType === 'basic')
          ) {
            // ①契約中のサブスクプラン (単)
            setCurrentTier(tier);
            setCurrentPlan(courseData);
            setPlanType(tier.planType);
            setPlusOne(
              getTiers.filter(
                t => t.mode === 'payment' && t.planType === tier.planType,
              ),
            );
            Console.log('currentTier', tier);
          } else if (
            status === 'canceled' &&
            mode === 'subscription' &&
            completedAt !== null &&
            completedAt.getTime() > now.getTime()
          ) {
            // ②契約期間中だけど解約したサブスクプラン (単)
            setCurrentCanceledTier(tier);
            setCurrentCanceledPlan(courseData);
            setPlanType(tier.planType);
            setPlusOne(
              getTiers.filter(
                t => t.mode === 'payment' && t.planType === tier.planType,
              ),
            );
            Console.log('currentCanceledTier', tier);
          } else if (
            mode === 'payment' &&
            completedAt.getTime() > now.getTime() &&
            tier.planType !== 'instrumentTrial' &&
            numberOfClasses === 0 &&
            latestDate.getTime() < enrolledAt.getTime()
          ) {
            // ③-0有効期限内の0枚チケット (単)
            setAvailableZeroTicketTier(tier);
            setAvailableZeroTicketData(courseData);
            latestDate = enrolledAt;
            Console.log('availableZeroTicketTier', tier);
          } else if (
            mode === 'payment' &&
            completedAt.getTime() > now.getTime() &&
            tier.planType !== 'instrumentTrial' &&
            numberOfClasses > 0 &&
            (tier.planType === 'ondai' || tier.planType === 'basic')
          ) {
            // ③-1有効期限内の1枚チケット (単)
            setAvailableTicketTier(tier);
            setAvailableTicketData(courseData);
            Console.log('availableTicketTier', tier);
          } else if (
            mode === 'payment' &&
            completedAt.getTime() > now.getTime() &&
            tier.planType === 'instrumentTrial' &&
            numberOfClasses === 0
          ) {
            // ③-2有効期限内の0枚トライアルチケット (単)
            setAvailableZeroTrialTier(tier);
            setAvailableZeroTrialData(courseData);
            Console.log('availableZeroTrialTier', tier);
          } else if (
            mode === 'payment' &&
            completedAt.getTime() > now.getTime() &&
            tier.planType === 'instrumentTrial' &&
            numberOfClasses > 0
          ) {
            // ③-2有効期限内の1枚トライアルチケット (単)
            setAvailableTrialTier(tier);
            setAvailableTrialData(courseData);
            Console.log('availableTrialTier', tier);
          } else if (
            mode === 'payment' &&
            completedAt.getTime() > now.getTime() &&
            tier.planType === 'voiceTrial' &&
            numberOfClasses > 0
          ) {
            setAvailableVoiceTrainingTrialTier(tier);
            setAvailableVoiceTrainingTrialData(courseData);
            Console.log('availableVoiceTrainingTrialTier', tier);
          } else if (
            status === 'ongoing' &&
            enrolledAt.getTime() > now.getTime() &&
            mode === 'subscription'
          ) {
            // ④次の更新日から有効になるプラン (単)
            setNextTier(tier);
            setNextPlan(courseData);
            Console.log('nextTier', tier);
          } else if (
            status === 'canceled' &&
            completedAt.getTime() < now.getTime()
          ) {
            // ⑤解約して有効期限もきれたプラン (単)
            setCanceledTier(tier);
            setCanceledPlan(courseData);
            Console.log('canceledTier', tier);
          }
        });
        setIsRendering(true);
      }
    })();
    if (currentUser === null) {
      history.push('/');
      return;
    }
  }, [currentUser, history]);

  return (
    <IonPage>
      <Header title="料金プラン" />
      {isRendering ? (
        <>
          <PlanChild
            isEmailVerify={isEmailVerify}
            tiers={tiers}
            currentTier={currentTier}
            currentPlan={currentPlan}
            currentVoiceTrainingTier={currentVoiceTrainingTier}
            currentVoiceTrainingPlan={currentVoiceTrainingPlan}
            currentVoiceTrainingInstallmentTier={
              currentVoiceTrainingInstallmentTier
            }
            currentVoiceTrainingInstallmentPlan={
              currentVoiceTrainingInstallmentPlan
            }
            currentCanceledTier={currentCanceledTier}
            currentCanceledPlan={currentCanceledPlan}
            availableZeroTicketTier={availableZeroTicketTier}
            availableZeroTicketData={availableZeroTicketData}
            availableTicketTier={availableTicketTier}
            availableTicketData={availableTicketData}
            availableVoiceTrial={availableVoiceTrial}
            availableVoiceTrainingTicketTier={availableVoiceTrainingTicketTier}
            availableVoiceTrainingTicketData={availableVoiceTrainingTicketData}
            availableTrial={availableTrial}
            availableTrialTier={availableTrialTier}
            availableTrialData={availableTrialData}
            availableVoiceTrainingTrialTier={availableVoiceTrainingTrialTier}
            availableVoiceTrainingTrialData={availableVoiceTrainingTrialData}
            nextTier={nextTier}
            nextPlan={nextPlan}
            instrumentTrial={instrumentTrial}
            voiceTrial={voiceTrial}
            plusOne={plusOne}
            plusVoiceOne={plusVoiceOne}
            availableVoiceTrainingNumberOfClasses={
              availableVoiceTrainingNumberOfClasses
            }
            planType={planType}
            ticketType={ticketType}
            canceledTier={canceledTier}
            canceledPlan={canceledPlan}
            hasPurchasedVoiceTraining={hasPurchasedVoiceTraining}
            history={history}
            coursePeriod={coursePeriod}
          />
          <IonToast
            isOpen={toastIsShown}
            onDidDismiss={() => setToastIsShown(false)}
            message={
              'Emailの認証が完了してない為、Emailをお送りしました。受信箱をご確認ください。'
            }
            duration={5000}
          />
        </>
      ) : (
        <IonSpinner name="bubbles" />
      )}
    </IonPage>
  );
};

export default withRouter(Plans);
