import firebase from 'firebase';
import axios from 'axios';
import 'firebase/analytics';
import https from 'https';
import Console from './console';
import dotenv from 'dotenv';
dotenv.config();

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
const auth = firebase.auth();
export const firestore = firebase.firestore();
export const timestamp = firebase.firestore.Timestamp;
const functions = firebase.functions();
// functions.useEmulator('localhost', 5001); // ローカル動作確認用
const studentsRef = firestore.collection('students');
const instructorsRef = firestore.collection('instructors');
export const fieldValue = firebase.firestore.FieldValue;

// we need to make the changes in API endpoints based on environments
// process.env.NODE_ENV === 'development')

// let envVar;
// if (process.env.NODE_ENV === 'production') {
//   envVar = 'prod';
// } else if (process.env.NODE_ENV === 'staging') {
//   envVar = 'stg';
// } else {
//   envVar = 'dev';
// }

// const domain = `https://api.endpoints.${envVar}-langdemy.cloud.goog/`;
// const clientCert = require(`../config/${envVar}/client.pem`);

let envVar;
if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'prod-langdemy') {
  // envVar = 'apis'; // GKE
  envVar = 'prod-langdemy';
} else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'stg-langdemy') {
  // envVar = 'stg-apis'; // GKE
  envVar = 'stg-langdemy';
} else {
  // envVar = 'dev-apis'; // GKE
  envVar = 'dev-langdemy';
}

// const domain = `https://${envVar}.langdemy.com/`; // GKE
const domain = `https://asia-northeast1-${envVar}.cloudfunctions.net/`; // CloudFunctions
const httpsAgent = new https.Agent({
  // rejectUnauthorized: true,
  // ca: clientCert
});

const options = {
  httpsAgent: httpsAgent,
};

// emailとpasswordを引数に受け取ってサインイン出来たかどうかを返す関数（Boolean）
const login = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

// googleログインをするための関数（Boolean）
const googleLogin = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return await firebase
    .auth()
    .signInWithPopup(provider)
    .then(result => {
      Console.log(result);
      return result.user;
    })
    .catch(e => { });
};

// ログアウト関数（Boolean）
const logout = () => {
  return firebase.auth().signOut();
};

// 会員登録
const signUp = async (email, password) => {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

// studentsコレクションにuid,id,planIdの初期化したアカウントを登録
const setFirestoreUser = async (user, email, fullName) => {
  const setData = {
    profile: {
      uid: user.uid,
      name: user.displayName || fullName,
      pic: {
        profile: user.photoURL,
        thumbnail: user.photoURL,
      },
    },
    address: {
      add1: '',
      add2: '',
      city: '',
      state: '',
      zip: '',
    },
    contacts: {
      email: user.email || email,
      mobile: user.phoneNumber,
      lineUid: '',
    },
    setting: {
      enrollment: {
        hasPurchasedInstrument: false,
        hasPurchasedVoiceTraining: false,
      },
      notification: {
        isEmail: 1,
      },
    },
    schedule: {},
    courseEnrolled: {},
    createdAt: new Date(),
    createdBy: user.uid,
    updatedAt: new Date(),
    updatedBy: user.uid,
  };

  return await studentsRef
    .doc(user.uid)
    .set(setData)
    .then(() => { })
    .catch(error => { });
};

// studentsコレクションにuid,id,planIdの初期化したアカウントを登録
const updateFirestoreUser = async user => {
  if (user) {
    const uid = user.uid;
    return await studentsRef
      .doc(user.uid)
      .update({
        updatedAt: new Date(),
        updatedBy: user.uid,
      })
      .then(() => { })
      .catch(error => { });
  }
  return false;
};

const userExists = async user => {
  if (user) {
    return await studentsRef
      .doc(user.uid)
      .get()
      .then(user => user.exists)
      .catch(e => { });
  }
  return false;
};

// ユーザーにパスワードの再設定メールを送信する関数
const sendPasswordResetEmail = email => {
  return firebase.auth().sendPasswordResetEmail(email);
};

const verifyEmail = email => {
  return firebase.auth().currentUser.sendEmailVerification();
};

// 退会（アカウントを削除する）
const deleteUser = async uid => {
  return await studentsRef
    .doc(uid)
    .delete()
    .then(() => {
      currentUser()
        .delete()
        .then(() => { })
        .catch(e => { });
    })
    .catch(error => { });
};

// 現在ログインしているユーザーのプロパティを返す関数
const currentUser = () => {
  return firebase.auth().currentUser;
};

// studentsコレクションから全生徒のデータを取得する
const searchStudents = () => {
  return firebase.firestore().collection('students');
};

// studentsコレクションから生徒のデータを取得する
const getStudent = uid => {
  return firebase.firestore().collection('students').doc(uid);
};

// instructorsコレクションから全講師のデータを取得する
const searchInstructors = () => {
  return firebase.firestore().collection('instructors');
};

// instructorsコレクションから講師のデータを取得する
const getInstructor = uid => {
  return firebase.firestore().collection('instructors').doc(uid);
};

// agenciesコレクションから代理店ユーザーのデータを取得する
const getAgency = uid => {
  return firebase.firestore().collection('agencies').doc(uid);
};

// agencyコレクションから全講師のデータを取得する
const searchAgencies = () => {
  return firebase.firestore().collection('agencies');
};

// agencyUsersコレクションから代理店ユーザーのデータを取得する
const getAgencyUser = uid => {
  return firebase.firestore().collection('agencyUsers').doc(uid);
};

// agencyUserコレクションから全講師のデータを取得する
const searchAgencyUser = () => {
  return firebase.firestore().collection('agencyUser');
};

// directorsコレクションから全ディレクターのデータを取得する
const searchDirectors = () => {
  return firebase.firestore().collection('directors');
};

// directorsコレクションからディレクターのデータを取得する
const getDirector = uid => {
  return firebase.firestore().collection('directors').doc(uid);
};

// classroomsコレクションから全クラスルームのデータを取得する
const searchClassrooms = () => {
  return firebase.firestore().collection('classrooms');
};

// classroomsコレクションからクラスルームのデータを取得する
const getClassroom = uid => {
  return firebase.firestore().collection('classrooms').doc(uid);
};

// classroomsコレクションからクラスのデータを新規作成して取得する
const newClassroomDoc = () => {
  return firebase.firestore().collection('classrooms').doc();
};

// coursesコレクションから全コースのデータを取得する
const searchCourses = () => {
  return firebase.firestore().collection('courses');
};

// coursesコレクションからコースのデータを取得する
const getCourse = courseId => {
  return firebase.firestore().collection('courses').doc(courseId);
};

// chatroomsコレクションから全コースのデータを取得する
const searchChatrooms = () => {
  return firebase.firestore().collection('chatrooms');
};

// chatroomsコレクションからコースのデータを取得する
const getChatroom = courseId => {
  return firebase.firestore().collection('chatrooms').doc(courseId);
};

// operatorsコレクションからコースのデータを取得する
const getOperator = uid => {
  return firebase.firestore().collection('operators').doc(uid);
};

// operatorsコレクションからコースのデータを取得する
const isOperatorUid = async id => {
  return await firebase
    .firestore()
    .collection('operators')
    .doc(id)
    .get()
    .then(snapshot => snapshot.exists)
    .catch(e => false);
};

// classroomsコレクションから選択された講師のクラスルームを取得する
const filterClassroomOf = uid => {
  const invitees = {
    instructors: [uid],
  };
  return firebase
    .firestore()
    .collection('classrooms')
    .where('invitees.instructors', 'array-contains', uid);
};

// plansコレクションから全プランのデータを取得する
const getPlans = () => {
  return firebase.firestore().collection('plans');
};

// 講師であればtrue, 講師でなければfalseを返す関数 (引数:uid)
const isInstructorUid = async uid => {
  return await getInstructor(uid)
    .get()
    .then(res => {
      return res.exists;
    })
    .catch(e => {
      Console.error(e);
      return false;
    });
};

// 代理店ユーザーであればtrue, 代理店ユーザーでなければfalseを返す関数 (引数:uid)
const isAgencyUserUid = async uid => {
  return await getAgencyUser(uid)
    .get()
    .then(res => {
      return res.exists;
    })
    .catch(e => {
      Console.error(e);
      return false;
    });
};

// 講師であればtrue, 講師でなければfalseを返す関数 (引数:uid)
const isDirectorUid = async uid => {
  return await getDirector(uid)
    .get()
    .then(res => {
      return res.exists;
    })
    .catch(e => {
      Console.error(e);
      return false;
    });
};

// const request = axios.create({
//   baseURL: domain,
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false,
//   }),
// });

// classroomであればtrue, classroomでなければfalseを返す関数 (引数:uid)
const isClassroomsId = async uid => {
  return await getClassroom(uid)
    .get()
    .then(res => {
      return res.exists;
    })
    .catch(e => {
      Console.error(e);
      return false;
    });
};

// GKEのcreateSubscription関数を実行
const createSubscription = async data => {
  // return functions.httpsCallable('createSubscription');
  return await axios
    .post(`${domain}createSubscription`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcancelSubscription関数を実行
const cancelSubscription = async data => {
  // return functions.httpsCallable('cancelSubscription');
  return await axios
    .post(`${domain}cancelSubscription`, data, options)
    .then(res => {
      // return JSON.parse(res.data)
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのupdateSubscription関数を実行
const updateSubscription = async data => {
  // return functions.httpsCallable('updateSubscription');
  return await axios
    .post(`${domain}updateSubscription`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcreateTransfer関数を実行
const createTransfer = async data => {
  return await axios
    .post(`${domain}createTransfer`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcreatePaymentIntent関数を実行
const createPaymentIntent = async data => {
  // return functions.httpsCallable('createPaymentIntent');
  return await axios
    .post(`${domain}createPaymentIntent`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcreateStripeConnect関数を実行
const createStripeConnect = async data => {
  // return functions.httpsCallable('createStripeConnect');
  return await axios
    .post(`${domain}createStripeConnect`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcreateCheckoutSession関数を実行
const createCheckoutSession = async data => {
  return await axios
    .post(`${domain}createCheckoutSession`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのcreateMeeting関数を実行
const createMeeting = async data => {
  return await axios
    .post(`${domain}createMeeting`, data, options)
    .then(res => {
      Console.dir(res);
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのnewMeeting関数を実行
const newMeeting = async data => {
  return await axios
    .post(`${domain}newMeeting`, data, options)
    .then(res => {
      if (res.data.statusCode === 200) {
        return JSON.parse(res.data.data);
      } else {
        return {};
      }
    })
    .catch(e => { });
};

// GKEのsendEmail関数を実行
const sendEmail = async emailData => {
  return await axios
    .post(`${domain}sendEmail`, emailData, options)
    .then(res => { })
    .catch(e => { });
};

// realtimeDBのmessagesを参照する
export const messagesRef = database.ref('messages');

// realtimeDBのmessagesにnameとtextプロパティを保存
// const pushMessage = ({ name, text }) => {
//   messagesRef.push({ name, text });
// };

// firestoreのcoursesに購入されたコースの情報を追加する
const setCourses = async (data, uid) => {
  const studentsRef = firebase.firestore().collection('students').doc(uid);

  return studentsRef
    .update(data)
    .then(() => { })
    .catch(e => { });
};

// subscriptionのデータをfirestoreにset
const setSubscription = async (data, uid) => {
  return await setCourses(data, uid);
};

// subscriptionのデータをfirestoreにset
const setPayment = async (data, uid) => {
  return await setCourses(data, uid);
};

// 講師の報酬割合を返す関数
const instructorRate = async uid => {
  const instructorData = await firestore
    .collection('instructors')
    .doc(uid)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      } else {
      }
    })
    .catch(e => { });
  const lessons = Object.keys(instructorData.schedule).filter(
    id =>
      instructorData.schedule[id].status === 'reservation' ||
      instructorData.schedule[id].status === 'present',
  );
  // if (lessons.length < 500) {
  //   return 0.6;
  // } else if (lessons.length < 1000) {
  //   return 0.65;
  // } else {
  //   return 0.7;
  // }

  // レッスン累積回数による謝礼の増加を廃止し、一律以下の割合とする
  return 0.7;
};

// startTimeから始まるuidのクラスルームを取得する startTime: Date, uid: string
const yourClassroomIdFrom = async (startTime, uid) => {
  const schedule = await getStudent(uid)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data().schedule;
      } else {
        Console.log("can't get user data in yourClassroomIdFrom");
        return {};
      }
    })
    .catch(e => {
      Console.error('error in yourClassroomIdFrom: ' + e);
      return {};
    });
  const reservationScheduleIds = Object.keys(schedule)
    .filter(id => schedule[id].status === 'reservation')
    .map(id => id.slice(11)); // 予約だけにして、"classrooms/" をカット
  return await reservationScheduleIds.find(async id => {
    return await getClassroom(id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          const date = snapshot.data().date;
          const time = snapshot.data().time.start;
          const classroomDate = new Date(
            date.year,
            date.month,
            date.day,
            time.hour,
            time.minutes,
          );
          return classroomDate === startTime;
        } else {
          return false;
        }
      })
      .catch(e => {
        Console.log('error in reserbationSchedule: ' + e);
        return false;
      });
  });
};

// プランの詳細データを連想配列で返す関数
const tiers = async () => {
  const allCourses = [];
  await searchCourses()
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = doc.data();
        allCourses.push(data);
      });
    })
    .catch(e => Console.error(e));
  return allCourses.map(courseData => courseData.tier);
};

const findTier = async productId => {
  return await getCourse(productId)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data().tier;
      } else {
        Console.log(`course id = ${productId} is not found`);
        return {};
      }
    })
    .catch(e => {
      Console.error('error in findTier: ' + e);
      return {};
    });
};

// プランデータを一括更新する
// const setNewPlan = (id, data) => {
//   const setData = {
//     title: data.title,
//     description: data.description[0],
//     duration: data.mode === "payment" ? "1 month" : "until withdrawal",
//     fee: {
//       period: data.mode === "payment" ? "one time" : "monthly",
//       times: data.times,
//       amount: data.price,
//     },
//     type: {
//       class: "individual/group",
//       maxCount: null,
//     },
//     // createdBy: "ucoticGND0fJP8PJWbUbw9yYQUu1",
//     // createdAt: new Date(),
//     updatedBy: "ucoticGND0fJP8PJWbUbw9yYQUu1",
//     updatedAt: new Date(),
//     tier: data
//   }
//   // searchCourses().doc(id).set(setData).then(() => Console.log("success")).catch(Console.error);
//   searchCourses().doc(id).update(setData).then(() => Console.log("success")).catch(Console.error);
// }

const getEmailCredential = (email, password) => {
  return firebase.auth.EmailAuthProvider.credential(email, password);
};

const getCourseEnrolledData = async uid => {
  try {
    const studentDoc = await getStudent(uid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        } else {
          return {};
        }
      })
      .catch(e => {
        Console.error(e);
        return {};
      });
    const allTiers = await tiers();
    const instrumentTrialPlan = allTiers.find(tier => tier.planType === 'instrumentTrial');
    const voiceTrialPlan = allTiers.find(tier => tier.planType === 'voiceTrial');
    if (instrumentTrialPlan) {
      const instrumentTrialId = 'courses/' + instrumentTrialPlan.id;
      const voiceTrialId = 'courses/' + voiceTrialPlan.id;
      const courseEnrolled = studentDoc ? studentDoc.courseEnrolled : {};
      const availableTrial = !Object.keys(courseEnrolled).includes(instrumentTrialId);
      const availableVoiceTrial = !Object.keys(courseEnrolled).includes(voiceTrialId);
      if (Object.keys(courseEnrolled).length !== 0) {
        return {
          availableTrial: availableTrial,
          availableVoiceTrial: availableVoiceTrial,
          courseIdList: Object.keys(courseEnrolled),
          courseEnrolled: courseEnrolled,
        };
      }
    }
    return {
      availableTrial: true,
      availableVoiceTrial: true,
      courseIdList: [],
      courseEnrolled: {},
    };
  } catch (e) {
    Console.error(e);
    return {
      availableTrial: true,
      availableVoiceTrial: true,
      courseIdList: [],
      courseEnrolled: {},
    };
  }
};

const pushMessage = async ({ text, classroomsId, uid }) => {
  // const classData = await firebase
  //   .getClassroom()
  //   .get()
  //   .then(snapshot => {
  //     if (snapshot.exists) {
  //       return snapshot.data();
  //     }
  //   });
  // const studentRef = classData.invitees.students[0];
  // const studentData = await studentRef.get().then(snapshot => {
  //   if (snapshot.exists) {
  //     return snapshot.data();
  //   }
  // });
  // const instructorRef = classData.invitees.instructors[0];
  // const instructorData = await instructorRef.get().then(snapshot => {
  //   if (snapshot.exists) {
  //     return snapshot.data();
  //   }
  // });
  const myName = await getStudent(uid)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        const data = snapshot.data();
        return data.profile.name;
      } else {
        return '';
      }
    })
    .catch(e => {
      Console.error(e);
      return '';
    });

  getChatroom(classroomsId)
    .set(
      {
        messages: firebase.firestore.FieldValue.arrayUnion({
          name: myName,
          text,
          date: new Date(),
        }),
      },
      { merge: true },
    )
    .then(() => Console.log('success update chatroom document'))
    .catch(e => Console.error(e));
};

const sendMail = async (to, recipient_name, templateId) => {
  const emailData = {
    to: to,
    from: 'support@langdemy.com',
    cc: '',
    bcc: '',
    replyTo: recipient_name,
    templateId: templateId,
    dynamic_template_data: '',
  };
  sendEmail(emailData);
};

// uidの引数がチャット相手のuidになるべき
const emailNotification = async (classroomsId, uid) => {
  // if (await isInstructorUid(uid)) {
  //   const instructorRef = getInstructor(uid);
  //   const instructorInfo = await instructorRef.get().then(snapshot => {
  //     if (snapshot.exists) {
  //       return snapshot.data();
  //     }
  //     const instructorName = instructorInfo?.profile?.name;
  //     const instructorEmail = instructorInfo?.contacts?.email;
  //     sendMail(
  //       instructorEmail,
  //       instructorName,
  //       'd-6b3229dbac4c43198f5b96ea57b315ef',
  //     );
  //   });
  // } else {
  //   const studentRef = getStudent(uid);
  //   const studentInfo = await studentRef.get().then(snapshot => {
  //     if (snapshot.exists) {
  //       return snapshot.data();
  //     }
  //     const studentName = studentInfo?.profile?.name;
  //     const studentEmail = studentInfo?.contacts?.email;
  //     sendMail(studentEmail, studentName, 'd-6b3229dbac4c43198f5b96ea57b315ef');
  //   });
  // }
  const classData = await getClassroom(classroomsId)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
  if (await isInstructorUid(uid)) {
    const studentRef = classData.invitees.students[0];
    const studentInfo = await studentRef.get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
    const studentName = studentInfo?.profile?.name;
    const studentEmail = studentInfo?.contacts?.email;
    sendMail(studentEmail, studentName, 'd-6b3229dbac4c43198f5b96ea57b315ef');
    // Console.log('生徒', studentName);
    // Console.log(studentEmail);
  } else {
    const instructorRef = classData.invitees.instructors[0];
    const instructorInfo = await instructorRef.get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
    const instructorName = instructorInfo?.profile?.name;
    const instructorEmail = instructorInfo?.contacts?.email;
    sendMail(
      instructorEmail,
      instructorName,
      'd-6b3229dbac4c43198f5b96ea57b315ef',
    );
    // Console.log('講師', instructorName, instructorEmail);
    // Console.log(instructorEmail);
  }
};

const beforeReservationEmailNotification = async (classroomsId, uid) => {
  const chatroomData = await getChatroom(classroomsId)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
  if (await isInstructorUid(uid)) {
    const studentUid = chatroomData.studentsUid;
    const studentInfo = await getStudent(studentUid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        }
      });
    const studentName = studentInfo?.profile?.name;
    const studentEmail = studentInfo?.contacts?.email;
    sendMail(studentEmail, studentName, 'd-6b3229dbac4c43198f5b96ea57b315ef');
    // Console.log('生徒', studentName);
    // Console.log(studentEmail);
    Console.log('生徒');
  } else {
    const teacherUid = chatroomData.teacherUid;
    Console.log('teacherUid', teacherUid);
    const instructorInfo = await getInstructor(teacherUid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        }
      });
    Console.log('instructorInfo', instructorInfo);
    const instructorName = instructorInfo?.profile?.name;
    const instructorEmail = instructorInfo?.contacts?.email;
    sendMail(
      instructorEmail,
      instructorName,
      'd-6b3229dbac4c43198f5b96ea57b315ef',
    );
    Console.log('講師', instructorName);
    Console.log('instructorEmail', instructorEmail);
  }
};

const emailNotifySwitch = async (checked, uid) => {
  const setData = { setting: { notification: { isEmail: checked } } };
  Console.log(setData);
  if (await isInstructorUid(uid)) {
    return await firebase
      .firestore()
      .collection('instructors')
      .doc(uid)
      .set(setData, { merge: true });
  } else {
    return await firebase
      .firestore()
      .collection('students')
      .doc(uid)
      .set(setData, { merge: true });
  }
};

export default {
  fb: firebase,
  login,
  googleLogin,
  logout,
  signUp,
  setFirestoreUser,
  updateFirestoreUser,
  sendPasswordResetEmail,
  verifyEmail,
  deleteUser,
  currentUser,
  searchStudents,
  getStudent,
  searchInstructors,
  getInstructor,
  getAgency,
  searchAgencies,
  getAgencyUser,
  searchAgencyUser,
  searchDirectors,
  getDirector,
  searchClassrooms,
  getClassroom,
  searchChatrooms,
  getChatroom,
  filterClassroomOf,
  searchCourses,
  getCourse,
  getPlans,
  getOperator,
  isOperatorUid,
  cancelSubscription,
  createSubscription,
  updateSubscription,
  createMeeting,
  createCheckoutSession,
  createPaymentIntent,
  createStripeConnect,
  createTransfer,
  newMeeting,
  // pushMessage,
  setSubscription,
  setPayment,
  sendEmail,
  userExists,
  instructorRate,
  isInstructorUid,
  isAgencyUserUid,
  isDirectorUid,
  isClassroomsId,
  newClassroomDoc,
  yourClassroomIdFrom,
  tiers,
  findTier,
  getEmailCredential,
  getCourseEnrolledData,
  // setNewPlan,
  pushMessage,
  emailNotification,
  beforeReservationEmailNotification,
  emailNotifySwitch,
};
