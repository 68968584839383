import React, { useContext } from 'react';
import { IonButton } from '@ionic/react';
import firebase, { firestore } from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import Console from "../hooks/console";

type SubscriptionProps = {
  courseId: string;
  firestoreData: any;
  history: any;
};

const SubscriptionButton = (props: SubscriptionProps) => {
  const { courseId, firestoreData, history } = props;
  const { currentUser } = useContext(AuthContext);

  const handleClick = async () => {
    const enrolledAt: Date = firestoreData.enrolledAt.toDate();
    const now = new Date();
    let maturity: Date;
    if (enrolledAt.getDate() <= now.getDate()) {
      maturity = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        enrolledAt.getDate() - 1,
        23,
        59,
        59,
      );
    } else {
      maturity = new Date(
        now.getFullYear(),
        now.getMonth(),
        enrolledAt.getDate() - 1,
        23,
        59,
        59,
      );
    }

    const courseEnrolledId = 'courses/' + courseId;
    const userRef = firebase.getStudent(currentUser?.uid);
    const userDoc: any = await userRef.get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
    const courseEnrolledData = userDoc.courseEnrolled;
    const stripeData = userDoc.stripeData;
    const data = {
      id: stripeData.subscription,
    };
    const result: any = await firebase
      .cancelSubscription(data)
      .then(res => {
        Console.dir("cancelSubscription res:", res);
        return res.result;
      })
      .catch(error => {
        Console.error(error);
      });;

    const setData = {
      courseEnrolled: {
        ...courseEnrolledData,
        [courseEnrolledId]: {
          ...firestoreData,
          completedAt: maturity,
          status: 'canceled', // 'only-this-month',
        },
      },
      stripeData: { ...stripeData, subscription: '' },
    };
    userRef
      .set(setData, { merge: true })
      .then(() => { history.push('/successcancel') })
      .catch(e => { });
  };

  return <IonButton color="danger" onClick={handleClick}>このプランを解約する</IonButton>;
};

export default SubscriptionButton;
