export type TierProps = {
  priceId: string;
  id: string;
  title: string;
  price: string;
  mode: string;
  times: number;
  minute: number;
  planType: string; // 'trial' | 'takaraduka' | 'ondai' | 'basic';
  benefit: string;
  description: any;
  status: string;
}

export const initTier: TierProps = {
  priceId: '',
  id: '',
  title: 'トライアルプラン',
  price: '2980',
  mode: 'payment',
  times: 1,
  minute: 60,
  planType: 'instrumentTrial',
  benefit: '',
  description: [''],
  status: 'active',
}
