import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  cashOutline,
  cashSharp,
  informationCircleOutline,
  informationCircleSharp,
  logInOutline,
  logInSharp,
  personCircleOutline,
  personCircleSharp,
  searchOutline,
  searchSharp,
} from 'ionicons/icons';
import './Menu.css';
import Console from '../hooks/console';
import firebase from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}
const appPages: AppPage[] = [
  {
    title: 'ランデミーこどもの音楽教室について',
    url: '/about',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp,
  },
  {
    title: 'ログイン',
    url: '/Login',
    iosIcon: logInOutline,
    mdIcon: logInSharp,
  },
];
const loggedInPages: AppPage[] = [
  {
    title: 'ランデミーこどもの音楽教室について',
    url: '/about',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp,
  },
  {
    title: '料金プラン',
    url: '/plans',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: '講師検索',
    url: '/Teachers',
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  },
  {
    title: 'マイページ(レッスン開始)',
    url: '/profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
];

const operatorPage: AppPage = {
  title: '講師予約枠作成',
  url: '/operatorTeachers',
  iosIcon: searchOutline,
  mdIcon: searchSharp,
};

const operatorPages: AppPage[] = [
  {
    title: 'ランデミーこどもの音楽教室について',
    url: '/about',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp,
  },
  {
    title: '料金プラン',
    url: '/plans',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: '講師検索',
    url: '/Teachers',
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  },
  {
    title: 'マイページ(レッスン開始)',
    url: '/profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
  {
    title: '講師予約枠作成',
    url: '/operatorTeachers',
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  },
];

const directorPage: AppPage = {
  title: 'ディレクション',
  url: '/direction',
  iosIcon: searchOutline,
  mdIcon: searchSharp,
};

const agencyPages: AppPage[] = [
  {
    title: 'ランデミーこどもの音楽教室について',
    url: '/about',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp,
  },
  {
    title: '料金プラン',
    url: '/plans',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: '代理店契約講師一覧',
    url: '/agencies',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: '代理店契約講師予約状況一覧',
    url: '/agencyProfile',
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: '講師検索',
    url: '/Teachers',
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  },
  {
    title: 'マイページ(レッスン開始はこちら)',
    url: '/profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
];

const Menu: React.FC = (props: any) => {
  const location = useLocation();
  const [pages, setPages] = useState<AppPage[]>(appPages);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    Console.log('currentUser:', currentUser?.uid);
    if (currentUser) {
      (async () => {
        const uid = currentUser.uid;
        // let pages = loggedInPages;
        if (
          (await firebase.isOperatorUid(uid)) &&
          !pages.includes(operatorPage)
        ) {
          Console.log('operator login');
          // pages.push(operatorPages);
          setPages(operatorPages);
        } else {
          Console.log('not operator login');
          // pages = loggedInPages;
          setPages(loggedInPages);
        }
        // if (await firebase.isDirectorUid(uid)) {
        //   pages.push(directorPage);
        // }
        // Console.log('pages:', pages);
        if (await firebase.isAgencyUserUid(uid)) {
          setPages(agencyPages);
          Console.log('agencyPages');
        }
      })();
    } else {
      setPages(appPages);
    }
  }, [currentUser]);
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>ランデミーこどもの音楽教室</IonListHeader>
          {pages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
export default Menu;
