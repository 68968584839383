import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  CardMedia,
  CardContent,
  Typography,
  Fab,
  Switch,
  Card,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState, useContext, useEffect } from 'react';
import './Schedule.css';
import Reservation from './Reservation';
import CreateAvailability from './CreateAvailability';
import { Box } from '@material-ui/core';
import Copyright from './Copyright';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../hooks/Auth';
import firebase from '../hooks/firebase';
import { firestore } from '../hooks/firebase';
import LineAddFriends from './LineAddFriends';
import Chat from './Chat';
import moment from 'moment';
import Console from '../hooks/console';

type ScheduleProps = {
  closeSchedule: React.Dispatch<React.SetStateAction<boolean>>;
  selectTeacherUid: string;
  history: any;
  specialization: string[];
  teacherName: string;
  authCode: string;
  image: any;
  workInfo: any;
  isOperator: boolean;
  agencyId: string;
  isBeforeReserveChat: boolean;
  createChatroom: any;
  chatShowModal: boolean;
  setChatShowModal: any;
  classroomsId: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      width: '10%',
      margin: theme.spacing(1),
      paddingTop: '10%', // 4:3
    },
    flex: {
      display: 'flex',
    },
    workInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(4),
    },
    m1: {
      margin: theme.spacing(1),
    },
    guide: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
    fabButton: {
      height: '50px',
      fontSize: theme.typography.pxToRem(24),
    },
  }),
);

const Schedule = (props: ScheduleProps) => {
  const classes = useStyles();
  const {
    closeSchedule,
    selectTeacherUid,
    history,
    specialization,
    teacherName,
    authCode,
    image,
    workInfo,
    isOperator,
    agencyId,
    isBeforeReserveChat,
    createChatroom,
    chatShowModal,
    setChatShowModal,
    classroomsId,
  } = props;
  const { currentUser } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(false);
  const [teacherUid, setTeacherUid] = useState<string>(''); // currentUserが講師ならuid, 講師でないなら空文字
  const [checked, setChecked] = useState<any>(1);

  // スマホかどうかの判定
  const isSmartPhone = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(max-device-width: 768px)').matches
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleChange = async () => {
    let checked;
    if (await firebase.isInstructorUid(currentUser?.uid)) {
      checked = await firebase
        .getInstructor(currentUser?.uid)
        .get()
        .then(snapshot => {
          const data = snapshot.data();
          return data?.setting?.notification?.isEmail;
        });
      if (checked === undefined) {
        checked = 1;
      } else if (checked === 0) {
        checked = 1;
      } else if (checked === 1) {
        checked = 0;
      }
    } else {
      checked = await firebase
        .getStudent(currentUser?.uid)
        .get()
        .then(snapshot => {
          const data = snapshot.data();
          return data?.setting?.notification?.isEmail;
        });
      if (checked === undefined) {
        checked = 1;
      } else if (checked === 0) {
        checked = 1;
      } else if (checked === 1) {
        checked = 0;
      }
    }
    await firebase.emailNotifySwitch(checked, currentUser?.uid);
    Console.log('checked', checked);

    setChecked(checked);
  };

  useEffect(() => {
    Console.log('ScheduleのselectTeacherUid', selectTeacherUid);
    Console.log('ScheduleのstudentUid', currentUser?.uid);
    isSmartPhone();
    (async () => {
      if (currentUser?.uid) {
        const uid: string = currentUser?.uid;
        let checked;
        if (await firebase.isInstructorUid(uid)) {
          setTeacherUid(uid);
          checked = await firebase
            .getInstructor(currentUser?.uid)
            .get()
            .then(snapshot => {
              const data = snapshot.data();
              return data?.setting?.notification?.isEmail;
            });
          if (checked === undefined) {
            checked = 1;
          }
        } else {
          checked = await firebase
            .getStudent(currentUser?.uid)
            .get()
            .then(snapshot => {
              const data = snapshot.data();
              return data?.setting?.notification?.isEmail;
            });
          if (checked === undefined) {
            checked = 1;
          }
        }
        setChecked(checked);
      }
    })();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {isOperator ||
          selectTeacherUid === currentUser?.uid ||
          (agencyId === workInfo.reportingTo && agencyId) ||
          ('' && workInfo) ||
          '' ? (
            <IonTitle>予約枠を作成する</IonTitle>
          ) : (
            <>{isMobile ? <></> : <IonTitle>スケジュール</IonTitle>}</>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonButton
          size="large"
          fill="clear"
          onClick={() => closeSchedule(false)}
        >
          ←
        </IonButton>
        <CardContent>講師: {teacherName}</CardContent>

        <div className={classes.flex}>
          {!!image && (
            <CardMedia
              className={classes.media}
              image={image}
              title={teacherName}
            />
          )}
          <div className={classes.workInfo}>
            {!!workInfo.lessonOutline ? (
              <>
                <IonLabel>◎レッスン概要</IonLabel>
                <IonText>{workInfo.lessonOutline}</IonText>
              </>
            ) : (
              <></>
            )}
            <div className={classes.m1}></div>
            {workInfo.textbook !== undefined && workInfo.textbook.length > 0 ? (
              <>
                <IonLabel>◎教材</IonLabel>
                {workInfo.textbook.map((book: string) => (
                  <>
                    <IonText>{book}</IonText>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* isOperator || selectTeacherUid === currentUser?.uid */}
        {isOperator ||
        selectTeacherUid === currentUser?.uid ||
        (agencyId === workInfo.reportingTo && agencyId) ||
        ('' && workInfo) ||
        '' ? (
          <React.Fragment>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">予約枠を作成する</IonTitle>
              </IonToolbar>
            </IonHeader>
            <CreateAvailability selectTeacherUid={selectTeacherUid} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <IonHeader collapse="condense">
              <IonToolbar>
                {isMobile ? <></> : <IonTitle>スケジュール</IonTitle>}
              </IonToolbar>
            </IonHeader>
            <Card>
              <CardContent>
                <Typography className={classes.guide}>
                  {isMobile
                    ? '予約可能時間が - の場合は、'
                    : 'カレンダーに緑色の枠がある場合、レッスンのご予約が可能です。緑色の枠がない場合は、'}
                  {!isBeforeReserveChat ? (
                    <>
                      <br />
                      <br />
                      ① <LineAddFriends />
                      　のメニューから「講師紹介」をタップ。
                      <br />
                      <br />
                      ②
                      ご希望の講師をお選びいただき、「お申し込みはこちら」をタップ。
                      <br />
                      　レッスンのご希望日時の登録をお願いします。
                      <br />
                    </>
                  ) : (
                    <>
                      <br />
                      講師と直接チャットをお願いします。チャット開始ボタンを押してください。
                      <br />
                    </>
                  )}
                  {Console.log('isBeforeReserveChat', isBeforeReserveChat)}
                  {isBeforeReserveChat && (
                    <>
                      <span>チャットメール通知</span>
                      <Switch
                        checked={checked}
                        color="primary"
                        onChange={handleChange}
                      />
                      <Fab
                        variant="extended"
                        size="large"
                        color="primary"
                        className={classes.fabButton}
                        onClick={() => {
                          createChatroom();
                          setChatShowModal(true);
                        }}
                      >
                        チャット開始
                      </Fab>
                      <Chat
                        classroomsId={classroomsId}
                        isOpen={chatShowModal}
                        setChatShowModal={setChatShowModal}
                        checked={checked}
                      />
                      <br />
                    </>
                  )}
                </Typography>
              </CardContent>
            </Card>
            <Reservation
              selectTeacherUid={selectTeacherUid}
              history={history}
              specialization={specialization}
              teacherName={teacherName}
              authCode={authCode}
            />
          </React.Fragment>
        )}
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <Box mt={8}>
              <Copyright />
            </Box>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Schedule);
