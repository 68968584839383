import { makeStyles, TextField, Theme } from '@material-ui/core';
import { IonButton, IonContent, IonModal, IonLabel } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import firebase from '../hooks/firebase';
import fb from 'firebase';
import Console from "../hooks/console";
import { User } from '@firebase/auth-types';
import { FpxBankElementComponent } from '@stripe/react-stripe-js';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  text: {
    color: '#cc1f00',
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
    fontSize: '24px',
  },
}));

const EmailPasswordLoginModal = (props: any) => {
  const { yesFunc, NoFunc, setMessage, setToastIsShown, modalProps } = props;
  const currentUser: User = props.currentUser;
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isComposed, setIsComposed] = useState(false);

  useEffect(() => {
    const disabled = email === '' || password === '';
    setDisabled(disabled);
  }, [email, password]);

  return (
    <IonModal isOpen={true}>
      <IonContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={(e: any) => {
                  if (isComposed) return;
                  if (e.key === 'Enter') {
                    setEmail(e.target.value);
                    e.preventDefault();
                  }
                }}
                onCompositionStart={() => setIsComposed(true)}
                onCompositionEnd={() => setIsComposed(false)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyDown={(e: any) => {
                  if (isComposed) return;
                  if (e.key === 'Enter') {
                    setPassword(e.target.value);
                    e.preventDefault();
                  }
                }}
              />
              <IonButton
                type="button"
                disabled={disabled}
                onClick={() => {
                  const credential: fb.auth.AuthCredential = firebase
                    .getEmailCredential(email, password);
                  currentUser
                    .linkWithCredential(credential)
                    .then((usercred) => {
                      const user = usercred.user;
                      Console.log("Account linking success", user);
                      setMessage('Emailとパスワードのログインの有効処理に成功しました。');
                      setToastIsShown(true);
                      yesFunc();
                    })
                    .catch(async (error) => {
                      Console.log("Account linking error", error);
                      switch (error.code) {
                        case 'auth/requires-recent-login':
                          await firebase
                            .googleLogin()
                            .then(() => {
                              currentUser
                                .linkWithCredential(credential)
                                .then((usercred) => {
                                  const user = usercred.user;
                                  Console.log("Account linking success", user);
                                  setMessage('Emailとパスワードのログインの有効処理に成功しました。');
                                  setToastIsShown(true);
                                  yesFunc();
                                })
                                .catch(async (error) => {
                                  setMessage('連携に失敗しました。');
                                  setToastIsShown(true);
                                  NoFunc();
                                });
                            })
                          break;
                        default:
                          setMessage('不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。');
                          setToastIsShown(true);
                      }

                    });
                }}
              >
                Emailとパスワードのログインを有効にする
              </IonButton>
              <IonButton type="button" onClick={() => NoFunc()}>
                キャンセル
              </IonButton>
            </form>
          </div>
        </Container>
      </IonContent>
    </IonModal>
  );
};

export default EmailPasswordLoginModal;
