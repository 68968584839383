import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { IonButton } from '@ionic/react';
import './LessonCancel'

type EditModalProps = {
  decidedData: any;
  editModalClose: any;
  deleteFunc: any;
  isEdited: boolean;
}

const LessonCancel = (props: EditModalProps) => {
  const { decidedData, editModalClose, deleteFunc, isEdited } = props;

  const [data, setData] = useState({
    title: '',
    start: '',
    end: new Date(),
    id: '',
    duration: 0,
  });
  const now = new Date();
  let determineTime: any;

  const createTime = () => {
    let yearAndMonth: any = moment(data.start, 'YYYY-MM').format('YYYY-MM');
    let yesterdayDateTime: any;
    if (new Date(data.start).getDate() === 1) {
      yesterdayDateTime = new Date(data.start).getDate();
    } else {
      yesterdayDateTime = new Date(data.start).getDate() - 1;
    }
    determineTime = moment(`${yearAndMonth}-${yesterdayDateTime} 23:59:59`);
  };

  //予定を削除する関数です
  const deleteFunction = () => {
    // createTime();
    // if (moment(now).isSameOrBefore(new Date(determineTime))) {
    deleteFunc();
    editModalClose();
    // } else if (!moment(now).isSameOrBefore(new Date(determineTime))) {
    //   alert('予約の変更・削除は前日の23時59分59秒までです');
    //   editModalClose();
    // }
  };


  useEffect(() => {
    setData({
      title: decidedData?.title,
      start: decidedData?.start,
      end: new Date(decidedData?.end),
      id: decidedData?.id,
      duration: decidedData?.duration,
    });
    //  }
  }, []);

  return (
    <div className="modalbodywrapper">
      <h2>この予約をキャンセルしてもよろしいですか？</h2>
      <p>予約をキャンセルすると、チケットまたはコースの回数は一回分復活します。</p>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ margin: 'auto' }}>
          <IonButton onClick={() => deleteFunction()}>キャンセルする</IonButton>
        </div>
        <div style={{ margin: 'auto' }}>
          <IonButton onClick={() => editModalClose()}>戻る</IonButton>
        </div>
      </div>
    </div>
  );
};

export default LessonCancel;
