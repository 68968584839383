import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Card, CardHeader, CardContent, CardMedia } from '@material-ui/core';
import { card } from 'ionicons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    question: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
    answer: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
    card: {
      paddingBottom: '0px',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
  }),
);

export default function Instructions() {
  const classes = useStyles();

  return (
    <div id="instructions" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>使い方</h2>
        </div>
        <div className={classes.root}>
          <img
            src="./img/instructions/instructions_trial.png"
            width="760px"
            alt="..."
          />
        </div>
      </div>
    </div>
  );
}
