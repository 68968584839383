import { Grid, Link, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../hooks/Auth';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import LineAddFriends from './LineAddFriends';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    text: {
      marginTop: 10,
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

const Copyright = () => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <LineAddFriends />
          <Typography className={classes.text}>
            ご不明点等ございましたら、こちらのLINE公式アカウントよりお問い合わせください。
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/Terms"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </Link>
        </Grid>
        <Grid item xs={4}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/Privacy"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </Link>
        </Grid>
        <Grid item xs={4}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/law"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引法に基づく表記
          </Link>
        </Grid>
      </Grid>
      {'© '}
      {new Date().getFullYear()}{' '}
      <a
        color="inherit"
        href="https://langdemy.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Langdemy Inc.
      </a>
      {' All Rights Reserved.'}
    </Typography>
  );
};

export default Copyright;
