import React, { useContext, useEffect } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import { Link } from '@material-ui/core';
import Copyright from '../components/Copyright';
import Header from '../components/Header';

const SuccessCancel = () => {
  return (
    <IonPage>
      <Header title="Thank you" />
      <IonContent>
        <IonTitle>
          <IonCard>
            <IonCardContent>
              レッスンの解約が完了しました。
              <br />
              <br />
              <br />
              残りのチケットがございましたら、次回の更新日の前日までご利用可能のままでございます。
              <br />
              <br />
              講師検索より、お好きな講師をお選びいただき、
              <br />
              レッスンをご予約ください。
              <br />
              <br />
              <br />
              またのご利用お待ちしております。
              <br />
              <Link href="/teachers">{'講師検索へ進む'}</Link>
            </IonCardContent>
          </IonCard>
        </IonTitle>
        <img src="./img/success_stripe_background.svg" alt="" />
        <Copyright />
      </IonContent>
    </IonPage>
  );
};

export default SuccessCancel;
