import React, { useState, useEffect, useContext } from 'react';
import TablePaginationComponent from './TablePaginationComponent';
import { AuthContext } from '../hooks/Auth';
import firebase from '../hooks/firebase';
import moment from 'moment';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import '../pages/Profile.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { cogSharp } from 'ionicons/icons';
import Console from '../hooks/console';

type data = {
  title?: string;
  start?: string;
  end?: any;
  id?: string;
  duration?: any;
  uid?: string;
  courseId?: string;
  agencyUserUid?: string;
  teacherName?: string;
  status?: string;
};

export default function AgencyAttendedStatus(props: any) {
  const { agencyUserUid, currentUser } = props;
  const [agencyUserData, setAgencyUserData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [eventList, setEventList] = useState<any>([]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // if (currentUser) {
    (async () => {
      const agencyUserData: any = await firebase
        .getAgencyUser(currentUser?.uid)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          } else {
            Console.log('schedule not found');
            return {};
          }
        })
        .catch(e => Console.error('error in getAgencyUser'));
      const classRoomsData: any[] = [];
      const list: any[] = [];
      firebase.searchInstructors().onSnapshot(async (snapshot: any) => {
        const rows = await snapshot.docs.map((doc: any) => {
          if (
            doc !== null &&
            doc.exists &&
            doc.data().hasOwnProperty('profile')
          ) {
            return doc.data();
          }
        });
        Console.log('講師のデータ', rows);
        const agencyTeachersrow = rows.filter(
          (row: any) =>
            (agencyUserData.agencyId === row?.workInfo.reportingTo &&
              agencyUserData.agencyId) ||
            ('' && row?.workInfo?.reportingTo) ||
            '',
        );
        agencyTeachersrow.map((row: any) => {
          Console.log('row?.profile?.uid', row?.profile?.uid);
          firebase
            .searchClassrooms()
            .get()
            .then(async snapshot => {
              snapshot.forEach(async snapshot => {
                const data = snapshot.data();
                if (
                  // agencyUserData.agencyId === row.workInfo.reportingTo
                  data.invitees?.instructors[0].path.slice(12) ===
                  row?.profile?.uid
                  //   ||
                  // data.invitees?.students[0].path.slice(9) === row?.profile?.uid
                ) {
                  classRoomsData.push(data);
                }
              });
              async function createData() {
                await Promise.all(
                  classRoomsData.map(async (item: any) => {
                    let start: any;
                    let end: any;
                    let studentUid: any;
                    let teacherName = '';
                    let instructorUid = '';
                    let status = '';
                    async function setData(item: any) {
                      await item.invitees?.instructors[0]
                        .get()
                        .then((snapshot: any) => {
                          if (snapshot.exists) {
                            const data = snapshot.data();
                            teacherName = data.profile?.name;
                            instructorUid = data.profile.uid;
                            if (data.profile.uid === row?.profile?.uid) {
                              status =
                                data.schedule[`classrooms/${item.id}`]?.status;
                            }
                          }
                        });
                      await item.invitees?.students[0]
                        .get()
                        .then((snapshot: any) => {
                          if (snapshot.exists) {
                            const data = snapshot.data();
                            studentUid = data.profile.uid;
                            if (data.profile.uid === currentUser?.uid) {
                              status =
                                data.schedule[`classrooms/${item.id}`]?.status;
                            }
                          }
                        });
                      if (item.date !== undefined) {
                        start = moment(
                          `${item.date?.year}${item?.date.month}${item?.date.day}${item?.time.start.hour}${item?.time.start.minutes}`,
                          'YYYY-MM-DDTHH:mm',
                        ).format('YYYY-MM-DD HH:mm');

                        end = moment(
                          `${item?.date.year}${item?.date.month}${item?.date.day}${item?.time.end.hour}${item?.time.end.minutes}`,
                          'YYYY-MM-DDTHH:mm',
                        ).format('YYYY-MM-DDTHH:mm');
                      }
                    }
                    await setData(item);

                    let courseTitle: any;
                    const tiers = await firebase.tiers();
                    tiers.map((tiersItem: any, index: any) => {
                      const customCourseTitle = String(item.courseId).substr(8);

                      if (tiersItem.id === customCourseTitle) {
                        courseTitle = tiersItem.title;
                      }
                    });

                    let data: data = {
                      title: '',
                      start: '',
                      end: '',
                      teacherName: '',
                      id: '',
                      duration: '',
                      courseId: '',
                      uid: '',
                      status: '',
                    };
                    async function pushData() {
                      if (
                        instructorUid === row?.profile?.uid
                        // studentUid === currentUser?.uid ||
                        // instructorUid === agencyUserUid
                      ) {
                        data = {
                          title: item.title,
                          start: start,
                          end: end,
                          id: item.id,
                          duration: item.duration,
                          courseId: courseTitle,
                          teacherName: teacherName,
                          status: status,
                        };
                        const endTime = new Date(end);
                        if (new Date().getTime() > endTime.getTime()) {
                          list.push(data);
                        }
                        list.sort(function (a: any, b: any) {
                          if (a.start < b.start) {
                            return 1;
                          } else {
                            return -1;
                          }
                        });
                      }
                    }
                    await pushData();
                  }),
                );
              }
              await createData();
              async function setList() {
                setEventList(list);
              }
              await setList();
              // Console.log('受講履歴eventList', eventList);
            });
        });
        Console.log('受講履歴agencyTeachersrow', agencyTeachersrow);

        // });
      });
    })();
    // }
  }, [agencyUserUid, currentUser]);

  return (
    <TableContainer component={Paper}>
      <Table className="table" aria-label="custom pagination table">
        <TableHead style={{ width: 'auto' }}>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell align="center">受講コース</TableCell>
            <TableCell align="center">講師</TableCell>
            <TableCell align="center">受講時間</TableCell>
            <TableCell align="right">ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Console.log('受講履歴eventList', eventList)}
          {(rowsPerPage > 0
            ? eventList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : eventList
          ).map((row: any) => (
            <TableRow key={row.id}>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                component="th"
                size="small"
                scope="row"
              >
                {row.title}
              </TableCell>
              <TableCell
                className="tableCell_large add_height_for_tableCell"
                align="center"
              >
                {row.courseId}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="center"
              >
                {row.teacherName}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="center"
              >
                {row.start}
              </TableCell>
              <TableCell
                className="tableCell_noraml add_height_for_tableCell"
                align="right"
              >
                {row.status === 'present'
                  ? '出席'
                  : row.status === 'canceled'
                  ? '事前キャンセル'
                  : row.status === 'reservation'
                  ? '欠席'
                  : ''}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              {eventList.length === 0 && (
                <TableCell
                  className="tableCell_text_center"
                  colSpan={6}
                  align="center"
                  style={{ verticalAlign: 'center !important' }}
                >
                  予約はありません
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              // colSpan={12}
              count={eventList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'event per page' },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationComponent}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
