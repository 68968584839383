import React, { useState, useEffect, useContext } from 'react';
// import { CalendarContext } from './Reservation';
import { TierProps, initTier } from '../data/planData';
import { AuthContext } from '../hooks/Auth';
import firebase from '../hooks/firebase';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Console from "../hooks/console";


const EditedModal = (props: {
  decidedData: any;
  changeFunc: any;
  editModalClose: any;
  eventList: any;
  useAvailabilityTime: any;
  editId: string;
}) => {


  const {
    decidedData,
    changeFunc,
    editModalClose,
    eventList,
    useAvailabilityTime,
    editId,
  } = props;
  const { currentUser } = useContext(AuthContext);
  const [start, setStart] = useState(
    moment(decidedData.start).format('YYYY-MM-DDTHH:mm'),
  );
  const [duration, setDuration] = useState<number>(30);
  const [course, setCourse] = useState<any>(initTier);
  const [useTitle, setUseTitle] = useState<string>('');
  const [editClassroom, setEditClassroom] = useState<any>(null);
  const durationValue = [30, 45, 60];

  const datapickerHandleChange = (e: any) => {
    setStart(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
  };

  // const courseChange = (e: any) => {
  //   setUseTitle(e.title);
  //   setDuration(e.duration);
  // };

  //変えられたデータを親要素に送信する関数
  const clickFunc = async () => {
    const newEnd = moment(start).add(duration, 'm').format('YYYY-MM-DDTHH:mm');
    const chilData = {
      title: decidedData.title,
      start: start,
      end: newEnd,
      id: await firebase.yourClassroomIdFrom(new Date(start), currentUser?.uid),
      duration: duration,
    };

    await eventList.map((item: any) => {
      if (
        chilData.id !== item.id &&
        new Date(item.start) < new Date(newEnd) &&
        new Date(item.end) > new Date(start)
      ) {
        alert('すでに予約が入っています');
      }
    });

    //現在の時間より過去に予約を設定出来ないようにする関数です
    const now = new Date();
    let isOut = true;
    if (moment(now).isSameOrBefore(start)) {
      // 今より先の時間かどうかをチェック
      useAvailabilityTime.map((item: any) => {
        if (
          new Date(item.end) <= new Date(newEnd) &&
          new Date(item.start) >= new Date(start)
        ) {
          // 予約枠に含まれているものがあればfalseにしてalertを出させない
          isOut = false;
        }
      });
      if (isOut) {
        changeFunc(chilData);
      } else if (isOut) {
        alert('予約枠外です');
      }
    } else if (!moment(now).isSameOrBefore(start)) {
      alert('現在時刻より前には予約をすることはできません。');
    }
    editModalClose();
  };

  useEffect(() => {
    setStart(moment(decidedData.start).format('YYYY-MM-DDTHH:mm'));
    if (!editClassroom) {
      (async () => {
        await firebase.getClassroom(editId).get().then(snapshot => {
          if (snapshot.exists) {
            setEditClassroom(snapshot.data());
          } else {
            Console.log("nothing this classroom");
          }
        }).catch(e => Console.error("error in editedModal useEffect: " + e));
      })();
    } else {
      (async () => {
        const courseId = editClassroom.courseId.slice(8);
        const editCourse = await firebase.findTier(courseId) || {};
        setCourse(editCourse);
        setDuration(editCourse.minute || 60);
        setUseTitle(editCourse.title || "");
      })();
    }
  }, [editClassroom]);
  Console.log(start)
  return (
    <div className="modalbodywrapper">
      <div className="icon-box">
        <Tooltip title="Close">
          <IconButton aria-label="close" onClick={() => editModalClose()}>
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className="editmodalbody">
        <p className="text-center">予約者：{decidedData.title}</p>
        <div className="margin-top-small text-center">
          <TextField
            id="datetime-local"
            className="text-center"
            style={{ width: '190px' }}
            label="Next appointment"
            type="datetime-local"
            defaultValue={start}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={datapickerHandleChange}
          />
        </div>
        <div className="margin-top-small flex_center text-center">
          <p className="margin-top-small  text-center">{useTitle}</p>
        </div>
        <p className="margin-top-small text-center">受講時間： {duration}分</p>
        <div className="margin-top-small text-center">
          <Button variant="outlined" onClick={clickFunc}>
            変更
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditedModal;
