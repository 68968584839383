import React, { useState, useEffect, useContext, createContext } from 'react';
// import TeacherEditModalJudgement from './_OLD_TeacherEditModalJudgement';
import TablePaginationComponent from './TablePaginationComponent';
// import { eventChange, changeStatus } from '../hooks/_OLD_calendar';
import '../pages/Profile.css';
import { AuthContext } from '../hooks/Auth';
import firebase, { firestore } from '../hooks/firebase';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { Fab, Modal } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import TeacherEditedModal from './_OLD_TeacherEditedModal';
// import TeacherEditModal from './_OLD_TeacherEditModal';
import EditedModal from './EditedModal';
import EditModal from './EditModal';
// import Chat from './Chat';
import Console from '../hooks/console';

export const ReservationStatusContext = createContext<any>({});

type data = {
  title?: string;
  start?: string | Date;
  end?: any;
  id?: string;
  uid?: string;
  duration?: any;
  courseTitle?: string;
  studentUid?: string;
  studentName?: string;
  agencyUserUid?: any;
  teacherName?: any;
  isClick?: boolean;
};

const changeStatus = async (
  id: string,
  uid: string | undefined,
  agencyUserUid: string | undefined,
) => {
  await runDelete(id);
  const batch = firestore.batch();
  const classroomsId = `classrooms/${id}`;
  const studentsRef = firebase.getStudent(uid);
  const instructorRef = firebase.getAgencyUser(agencyUserUid);
  const userInfo = await studentsRef.get().then(snapshot => {
    if (snapshot.exists) {
      return snapshot.data();
    }
  });

  const schedule = userInfo?.schedule;
  const pushSchedule = {
    schedule: {
      ...schedule,
      [classroomsId]: { status: 'canceled' },
    },
  };
  batch.update(studentsRef, pushSchedule); // 生徒のスケジュールを更新
  batch.update(instructorRef, pushSchedule); // 講師のスケジュールを更新
  batch
    .commit()
    .then(() => {})
    .catch(err => {});
};

const eventChange = async (start: any, end: any, id: any, duration: any) => {
  const dateObj = initDate(start, end);
  firebase.getClassroom(String(id)).update({
    ...dateObj,
    duration: duration,
  });
};

const initDate = (start: moment.MomentInput, end: moment.MomentInput) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return {
    date: {
      year: startMoment.format('YYYY'),
      month: startMoment.format('MM'),
      day: startMoment.format('DD'),
    },
    time: {
      start: {
        hour: startMoment.format('HH'),
        minutes: startMoment.format('mm'),
      },
      end: {
        hour: endMoment.format('HH'),
        minutes: endMoment.format('mm'),
      },
    },
  };
};

const runDelete = async (id: string) => {
  try {
    await firebase.getClassroom(String(id)).delete();
  } catch (err) {}
};

const AgencyTeacherReservationStatus = (props: any) => {
  const { agencyUserUid, setOpenLessonReady, setClassroomData, currentUser } =
    props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [eventList, setEventList] = useState<any>([]);
  const [eventsList, setEventsList] = useState<any>([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false); //予定を変更・削除するモーダルを制御する値です
  const [editModalJudgementNum, setEditModalJudgementNum] = useState<number>(0); //予定がクリックされた時のモーダルを管理する値です。1に変更された時に、編集画面に遷移します。
  const [teacherDecidedData, setDecidedData] = useState<any>({}); //予定を変更・削除するモーダルに表示する値です
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState('');
  const [classroomsId, setClassroomsId] = useState('');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  // const goToLessonReady = (data: any) => {
  //   setOpenLessonReady(true);
  //   setClassroomData(data);
  // };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //予定を変更・削除するモーダルを開ける関数です
  const editModalOpen = (data: any) => {
    setEditModalIsOpen(true);
    setDecidedData(data);
    setId(data.id);
  };

  //予定を変更・削除するモーダルを閉める関数です
  const teacherEditModalClose = () => {
    setEditModalIsOpen(false);
    setEditModalJudgementNum(0);
  };

  //発火時に編集画面に遷移します
  const teacherMoveEdited = () => {
    setEditModalJudgementNum(1);
  };

  //子要素からデータを受け取り、変換したデータをカレンダーに反映、Firestoreに送信する関数です
  const changeFunc = (item: any) => {
    let changedEventData = eventList.filter((data: any) => data.id !== item.id);
    const data: data = {
      title: item.title,
      start: moment(item.start).format('YYYY-MM-DD HH:mm'),
      end: item.end,
      id: item.id,
      // duration: item.duration,
      uid: currentUser?.uid,
      agencyUserUid: item.agencyUserUid,
      teacherName: item.teacherName,
      courseTitle: item.courseTitle,
      duration: item.duration,
    };
    changedEventData.push(data);
    setEventList(changedEventData);
    eventChange(item.start, item.end, item.id, item.duration);
  };

  //子要素から送られてくる子要素のidを受け取り、子要素を除いたデータを返し、子要素から送られてきたidを持つデータをFireStoreから消去する関数です
  const teacherDeleteFunc = (sentStudentUid: any) => {
    const data = eventList.filter((item: any) => item.id !== id);
    setEventList(data);
    const agencyUserUid = currentUser?.uid;
    changeStatus(id, sentStudentUid, agencyUserUid);
  };

  useEffect(() => {
    Console.log('useEffect agencyUserUid: ' + agencyUserUid);
    if (agencyUserUid && currentUser) {
      (async () => {
        const agencyUserData: any = await firebase
          .getAgencyUser(agencyUserUid)
          .get()
          .then(snapshot => {
            if (snapshot.exists) {
              return snapshot.data();
            } else {
              Console.log('schedule not found');
              return {};
            }
          })
          .catch(e => Console.error('error in getAgencyUser'));
        Console.log('agencyUserData', agencyUserData);
        firebase.searchInstructors().onSnapshot(async (snapshot: any) => {
          const rows = await snapshot.docs.map((doc: any) => {
            if (
              doc !== null &&
              doc.exists &&
              doc.data().hasOwnProperty('profile')
            ) {
              return doc.data();
            }
          });
          Console.log('講師のデータ', rows);
          const agencyTeachersrow = rows.filter(
            (row: any) =>
              (agencyUserData.agencyId === row?.workInfo.reportingTo &&
                agencyUserData.agencyId) ||
              ('' && row?.workInfo?.reportingTo) ||
              '',
          );
          Console.log('agencyTeachersrow', agencyTeachersrow);
          const setLists = agencyTeachersrow.map(async (row: any) => {
            Console.log('row', row);
            const myScheduleId: string[] = Object.keys(row.schedule).map(id =>
              id.slice(11),
            );
            const getClass = async (id: string) => {
              return await firebase
                .getClassroom(id)
                .get()
                .then(snapshot => {
                  if (snapshot.exists) {
                    Console.log(`get success classroomsId = ${id}`);
                    return snapshot.data();
                  } else {
                    Console.log(`data of classroomsId = ${id} is not found`);
                    return null;
                  }
                })
                .catch(e => {
                  Console.error('error in myClassrooms get ' + e);
                  return null;
                });
            };
            const promises: any[] = myScheduleId.map((id: string) =>
              getClass(id),
            );
            Console.log('promises', promises);
            const myClassrooms: any[] = await Promise.all(promises)
              .then(value => {
                // Console.log("Promise.all value: " + value);
                return value;
              })
              .catch(e => {
                Console.error('error in Promise.all' + e);
                return [];
              });
            Console.log('myClassrooms', myClassrooms);
            const filterClassrooms: any[] = myClassrooms.filter(
              (classroom: any) => classroom !== null,
            );
            Console.log('filterClassrooms', filterClassrooms);
            // Console.dir("myClassrooms: " + myClassrooms[0].courseId);
            const getEvent = async (classroomData: any) => {
              const studentData = await classroomData.invitees?.students[0]
                .get()
                .then((snapshot: any) => {
                  // Console.log("studentData get: " + snapshot.data());
                  return snapshot.data().profile;
                })
                .catch((e: any) =>
                  Console.error('error in studentData get: ' + e),
                );
              const instructorData =
                await classroomData.invitees?.instructors[0]
                  .get()
                  .then((snapshot: any) => {
                    // Console.log("studentData get: " + snapshot.data());
                    return snapshot.data().profile;
                  })
                  .catch((e: any) =>
                    Console.error('error in instructorData get: ' + e),
                  );
              const tier: any = await firebase.findTier(
                classroomData.courseId.slice(8),
              );
              return {
                title: studentData.name,
                start: moment(
                  `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.start.hour}${classroomData.time.start.minutes}`,
                  'YYYY-MM-DDTHH:mm',
                ).format('YYYY-MM-DD HH:mm'),
                end: moment(
                  `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.end.hour}${classroomData.time.end.minutes}`,
                  'YYYY-MM-DDTHH:mm',
                ).format('YYYY-MM-DDTHH:mm'),
                id: classroomData.id,
                duration: classroomData.zoomResponse.duration,
                courseTitle: tier.title,
                studentName: studentData.name,
                studentUid: studentData.uid,
                teacherName: instructorData.name,
                isClick: !moment(new Date()).isSame(
                  moment(
                    `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.start.hour}${classroomData.time.start.minutes}`,
                    'YYYY-MM-DDTHH:mm',
                  ).format('YYYY-MM-DD HH:mm'),
                  'day',
                ),
                // agencyUserData
                connectId: row.bankAccount.connectId,
                productId: tier.id,
                start_url: classroomData.zoomResponse.start_url,
                join_url: classroomData.zoomResponse.join_url,
              };
            };
            // Console.log('classroomData', classroomData);
            const eventPromises: any[] = filterClassrooms.map(
              (classroomData: any) => getEvent(classroomData),
            );
            Console.log('eventPromises', eventPromises);
            const events: any[] = await Promise.all(eventPromises)
              .then(value => {
                return value;
              })
              .catch(e => {
                Console.error(e);
                return [];
              });
            Console.log('events', events);
            const now = new Date();
            const setList = events.filter((event: any) => {
              const endTime = new Date(event.end);
              return now.getTime() < endTime.getTime();
            });

            setList.sort((a: any, b: any) => {
              if (a.start < b.start) {
                return -1;
              } else {
                return 1;
              }
            });
            // Console.log('setList', setList);
            // }
            Console.log('setList', setList);

            const agencyRow = setList.map(row => {
              Console.log('setListの中のrow', row);
              eventList.push(row);
              Console.log('agencyRowの中のlist', eventList);
              setEventList(eventList);
              return eventList;
            });

            return agencyRow;
           
          });
          Console.log('setLists', setLists);
          const setsLists: any[] = await Promise.all(setLists)
            .then(value => {
              // Console.log("Promise.all value: " + value);
              return value;
            })
            .catch(e => {
              Console.error('error in Promise.all' + e);
              return [];
            });
          Console.log('setsLists', setsLists);
          Console.log('1つのeventList', eventList);
          setEventsList(eventList);
        });
      })();
    }
  }, [agencyUserUid, currentUser]);

  return (
    <>
      {Console.dir(eventList)}
      <Modal open={editModalIsOpen} onClose={teacherEditModalClose}>
        <div>
          {editModalJudgementNum === 0 ? (
            <EditModal
              decidedData={teacherDecidedData}
              editModalClose={teacherEditModalClose}
              deleteFunc={teacherDeleteFunc}
              isEdited={false}
            />
          ) : (
            <EditedModal
              decidedData={teacherDecidedData}
              changeFunc={changeFunc}
              editModalClose={teacherEditModalClose}
              eventList={eventList}
              editId={id}
              useAvailabilityTime
            />
          )}
        </div>
      </Modal>
      <TableContainer component={Paper}>
        <Table className="table" aria-label="custom pagination table">
          <TableHead style={{ width: 'auto' }}>
            <TableRow>
              {/* <TableCell padding="checkbox"></TableCell> */}
              {/* <TableCell>名前</TableCell> */}
              <TableCell align="left">生徒</TableCell>
              <TableCell align="center">受講コース</TableCell>
              <TableCell align="right">講師</TableCell>
              <TableCell align="right">受講開始時間</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Console.log('2個目のeventList', eventList)}
            {(rowsPerPage > 0
              ? eventList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : eventsList
            ).map((row: any) => (
              <TableRow key={row.id}>
                <TableCell align="left" className="tableCell_noraml">
                  {/* 生徒名 */}
                  {row.title}
                </TableCell>
                <TableCell className="tableCell_noraml">
                  <div className="tableCell_minWidth flex flex_center">
                    {/* 受講コース */}
                    <p>{row.courseTitle}</p>
                  </div>
                </TableCell>
                <TableCell align="center" className="tableCell_noraml">
                  {/* 先生名 */}
                  {/* teacherの名前を表示させる
                   */}
                  {row.teacherName}
                </TableCell>
                <TableCell align="right" className="tableCell_noraml">
                  {/* 受講開始時間 */}
                  {row.start} ({row.duration}分)
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                {eventList.length === 0 && (
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ verticalAlign: 'center !important' }}
                  >
                    予約はありません
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={eventList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'event per page' },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationComponent}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default withRouter(AgencyTeacherReservationStatus);
