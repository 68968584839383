import React, { useContext, useEffect, useState } from 'react';
import {
  IonTitle,
  IonDatetime,
  IonLabel,
  IonList,
  IonItem,
  IonText,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCard,
  IonInfiniteScroll,
  IonIcon,
} from '@ionic/react';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import { makeStyles, Theme } from '@material-ui/core';
import firebase from "../hooks/firebase";
import Console from "../hooks/console";
import { AuthContext } from "../hooks/Auth";
import { nextTick } from 'process';

type StartEnd = {
  start: Date;
  end: Date;
};

type StartEndString = {
  start: string;
  end: string;
};

type AllAvailType = {
  date: string;
  avail: StartEndString[];
};

const useStyles = makeStyles((theme: Theme) => ({
  component: {
    marginTop: '10',
  },
  itemComponent: {
    marginTop: '10',
  },
  oneLine: {
    display: 'flex'
  },
  card: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    padding: '3%',
  },
  hyphen: {
    marginLeft: '5%',
  },
  time: {
    marginLeft: '5%',
  },
  cardTop: {
    display: 'flex',
  },
}));

type PropsType = {
  selectTeacherUid: string;
  createMeeting: Function;
}

const initAvail: AllAvailType[] = [
  { date: '', avail: [] },
  { date: '', avail: [] },
  { date: '', avail: [] },
  { date: '', avail: [] },
  { date: '', avail: [] },
]

const MobileReservation = (props: any) => {
  const { selectTeacherUid, createMeeting } = props;
  const now = new Date();
  const classes = useStyles();
  const initDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 10, 0, 0);
  const minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 10 + 9, 0, 0);
  const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 22, 21 + 9, 0, 0);
  const [reservationTime, setReservationTime] = useState<string>(initDate.toISOString());
  const [teacherData, setTeacherData] = useState<any>(null); // 予約する先生のオブジェクト
  const [availTimeList, setAvailTimeList] = useState<any>(null); // 予約可能な時間の配列
  const [allAvailTimeList, setAllAvailTimeList] = useState<AllAvailType[]>([]); // 予約可能な時間の配列
  const [fiveAvailTimeList, setFiveAvailTimeList] = useState<AllAvailType[]>(initAvail); // 予約可能な時間の配列
  const [courseList, setCourseList] = useState<any[]>([]); // 予約可能なコースの配列
  const [selectCourse, setSelectCourse] = useState<any>(null); // 選択中のコースのオブジェクト
  const [selectDuration, setSelectDuration] = useState<number>(75); // 選択中のコースの受講時間
  const [isButton, setIsButton] = useState<boolean>(true); // 予約ボタンが押せるかどうか
  const [otherReservations, setOtherReservations] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<Date>(new Date());
  const { currentUser } = useContext(AuthContext);

  // 選択中の時間が予約可能かどうかを判定する
  const buttonJudge = (duration: number = selectDuration, time: string = reservationTime) => {
    const startTime = new Date(time);
    const endTime = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), startTime.getHours(), startTime.getMinutes() + duration);
    setEnd(endTime);
    if (availTimeList) {
      const availTime = availTimeList.find((avail: any) => {
        const availStart = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), Number(avail.start.slice(0, 2)), Number(avail.start.slice(2, 4)));
        const availEnd = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), Number(avail.end.slice(0, 2)), Number(avail.end.slice(2, 4)));
        return availStart.getTime() <= startTime.getTime() && availEnd.getTime() >= endTime.getTime();
      })
      if (availTime === undefined) {
        setIsButton(true);
      } else {
        setIsButton(false);
      }
    }
  }

  // 
  const setAvailability = (strDate: string = reservationTime, data: any = teacherData, classrooms: any = otherReservations) => {
    try {
      const d = new Date(strDate);
      const classroomDateList = classrooms.filter( // 同じ日付
        (classroom: any) => {
          // Console.log("Number(classroom.date.year): " + Number(classroom.date.year));
          // Console.log("d.getFullYear(): " + d.getFullYear());
          // Console.log("Number(classroom.date.month): " + Number(classroom.date.month));
          // Console.log("d.getMonth(): " + d.getMonth());
          // Console.log("Number(classroom.date.day): " + Number(classroom.date.day));
          // Console.log("d.getDate(): " + d.getDate());
          return Number(classroom.date.year) === d.getFullYear() &&
            Number(classroom.date.month) === d.getMonth() + 1 &&
            Number(classroom.date.day) === d.getDate()
        }
      ).map((classroom: any) => {
        return {
          start: new Date(
            Number(classroom.date.year),
            Number(classroom.date.month) - 1,
            Number(classroom.date.day),
            Number(classroom.time.start.hour),
            Number(classroom.time.start.minutes),
          ),
          end: new Date(
            Number(classroom.date.year),
            Number(classroom.date.month) - 1,
            Number(classroom.date.day),
            Number(classroom.time.end.hour),
            Number(classroom.time.end.minutes),
          ),
        }
      }); // 同じ日付にある別の生徒のレッスン時間のオブジェクトを作成
      // Console.dir("classrooms: " + JSON.stringify(classrooms));
      // Console.dir("classroomDateList: " + JSON.stringify(classroomDateList));

      const y: string = String(d.getFullYear());
      const m: string = d.getMonth() < 9 ? "0" + String(d.getMonth() + 1) : String(d.getMonth() + 1);
      const D: string = d.getDate() < 10 ? "0" + String(d.getDate()) : String(d.getDate());
      const availList = data.availability[y] ?
        (data.availability[y][m] ?
          (data.availability[y][m][D]) : []) : [];
      const availTimeList = availList.map((avail: any) => {
        return {
          start: new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            Number(avail.start.slice(0, 2)),
            Number(avail.start.slice(2, 4)),
          ),
          end: new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            Number(avail.end.slice(0, 2)),
            Number(avail.end.slice(2, 4)),
          ),
        }
      });

      let list: any[] = [];
      const millisecondsOfThirtyMinutes = 1800000 - 1; // 30分のミリ秒 - 1

      // 予約されたレッスンを考慮した予約枠を設定する
      availTimeList.forEach((avail: StartEnd) => {
        let otherList: StartEnd[] = [];
        classroomDateList.forEach((other: StartEnd) => {
          if (avail.start.getTime() <= other.start.getTime() && avail.end.getTime() >= other.end.getTime()) {
            otherList.push({
              start: other.start,
              end: other.end
            })
          }
        });
        const number0fOthers = otherList.length;

        if (number0fOthers === 0) {
          list.push({
            start: ("00" + avail.start.getHours()).slice(-2) + ("00" + avail.start.getMinutes()).slice(-2),
            end: ("00" + avail.end.getHours()).slice(-2) + ("00" + avail.end.getMinutes()).slice(-2)
          }); // avail.start 〜 avail.end
        }
        const compareStart = (a: StartEnd, b: StartEnd) => {
          return a.start.getTime() - b.start.getTime();
        }
        otherList.sort(compareStart);
        // Console.dir("otherList: " + JSON.stringify(otherList))
        let lastTime = avail.start;

        for (let i = 0; i < number0fOthers; i++) {
          if (i === number0fOthers - 1) { // lastTime 〜 otherList[i] 〜 avail.endで予約できるか見る
            // Console.log("lastTime.getTime() + millisecondsOfThirtyMinutes: " + lastTime.getTime() + millisecondsOfThirtyMinutes);
            // Console.log("otherList[i].start.getTime(): " + otherList[i].start.getTime());
            // Console.log("otherList[i].end.getTime() + millisecondsOfThirtyMinutes: " + otherList[i].end.getTime() + millisecondsOfThirtyMinutes);
            // Console.log("avail.end.getTime(): " + avail.end.getTime());
            if (lastTime.getTime() + millisecondsOfThirtyMinutes < otherList[i].start.getTime()) {
              list.push({
                start: ("00" + lastTime.getHours()).slice(-2) + ("00" + lastTime.getMinutes()).slice(-2),
                end: ("00" + otherList[i].start.getHours()).slice(-2) + ("00" + otherList[i].start.getMinutes()).slice(-2)
              }); // lastTime 〜 otherList[i].start
            }
            if (otherList[i].end.getTime() + millisecondsOfThirtyMinutes < avail.end.getTime()) {
              list.push({
                start: ("00" + otherList[i].end.getHours()).slice(-2) + ("00" + otherList[i].end.getMinutes()).slice(-2),
                end: ("00" + avail.end.getHours()).slice(-2) + ("00" + avail.end.getMinutes()).slice(-2)
              }); // otherList[i].end 〜 avail.end
            }
          } else { // lastTime 〜 otherList[i] 予約できるかみて、lastTimeにotherList[i].endをセット
            // Console.log("lastTime.getTime() + millisecondsOfThirtyMinutes: " + lastTime.getTime() + millisecondsOfThirtyMinutes);
            // Console.log("otherList[i].start.getTime(): " + otherList[i].start.getTime());
            if (lastTime.getTime() + millisecondsOfThirtyMinutes < otherList[i].start.getTime()) {
              list.push({
                start: ("00" + lastTime.getHours()).slice(-2) + ("00" + lastTime.getMinutes()).slice(-2),
                end: ("00" + otherList[i].start.getHours()).slice(-2) + ("00" + otherList[i].start.getMinutes()).slice(-2)
              }); // lastTime 〜 otherList[i].start
            }
            lastTime = otherList[i].end;
          }
        }
      })


      if (list) {
        setAvailTimeList(list);
      } else {
        setAvailTimeList(null);
      }
      buttonJudge();

    } catch (e) {
      setAvailTimeList(null);
      buttonJudge();
      Console.error(e);
    }
  }

  const setAllAvailability = (strDate: string = reservationTime, data: any = teacherData, classrooms: any = otherReservations) => {
    try {
      const dateList = [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
      const d = new Date(strDate);
      const oneMonthDateList = dateList.map(num => new Date(d.setDate(d.getDate() + num)));
      const allAvailability = oneMonthDateList.map(oneDay => {
        const classroomDateList = classrooms.filter( // 同じ日付
          (classroom: any) => {
            // Console.log("Number(classroom.date.year): " + Number(classroom.date.year));
            // Console.log("d.getFullYear(): " + d.getFullYear());
            // Console.log("Number(classroom.date.month): " + Number(classroom.date.month));
            // Console.log("d.getMonth(): " + d.getMonth());
            // Console.log("Number(classroom.date.day): " + Number(classroom.date.day));
            // Console.log("d.getDate(): " + d.getDate());
            return Number(classroom.date.year) === oneDay.getFullYear() &&
              Number(classroom.date.month) === oneDay.getMonth() + 1 &&
              Number(classroom.date.day) === oneDay.getDate()
          }
        ).map((classroom: any) => {
          return {
            start: new Date(
              Number(classroom.date.year),
              Number(classroom.date.month) - 1,
              Number(classroom.date.day),
              Number(classroom.time.start.hour),
              Number(classroom.time.start.minutes),
            ),
            end: new Date(
              Number(classroom.date.year),
              Number(classroom.date.month) - 1,
              Number(classroom.date.day),
              Number(classroom.time.end.hour),
              Number(classroom.time.end.minutes),
            ),
          }
        }); // 同じ日付にある別の生徒のレッスン時間のオブジェクトを作成
        // Console.dir("classrooms: " + JSON.stringify(classrooms));
        // Console.dir("classroomDateList: " + JSON.stringify(classroomDateList));
        const y: string = String(oneDay.getFullYear());
        const m: string = oneDay.getMonth() < 9 ? "0" + String(oneDay.getMonth() + 1) : String(oneDay.getMonth() + 1);
        const d: string = oneDay.getDate() < 10 ? "0" + String(oneDay.getDate()) : String(oneDay.getDate());
        const availList = data.availability[y] ?
          (data.availability[y][m] ?
            (data.availability[y][m][d]) : []) : [];
        if (availList) {
          const availTimeList = availList.map((avail: any) => {
            return {
              start: new Date(
                oneDay.getFullYear(),
                oneDay.getMonth(),
                oneDay.getDate(),
                Number(avail.start.slice(0, 2)),
                Number(avail.start.slice(2, 4)),
              ),
              end: new Date(
                oneDay.getFullYear(),
                oneDay.getMonth(),
                oneDay.getDate(),
                Number(avail.end.slice(0, 2)),
                Number(avail.end.slice(2, 4)),
              ),
            }
          });



          let list: any[] = [];
          const millisecondsOfThirtyMinutes = 1800000 - 1; // 30分のミリ秒 - 1

          // 予約されたレッスンを考慮した予約枠を設定する
          availTimeList.forEach((avail: StartEnd) => {
            let otherList: StartEnd[] = [];
            classroomDateList.forEach((other: StartEnd) => {
              if (avail.start.getTime() <= other.start.getTime() && avail.end.getTime() >= other.end.getTime()) {
                otherList.push({
                  start: other.start,
                  end: other.end
                })
              }
            });
            const number0fOthers = otherList.length;

            if (number0fOthers === 0) {
              list.push({
                start: ("00" + avail.start.getHours()).slice(-2) + ("00" + avail.start.getMinutes()).slice(-2),
                end: ("00" + avail.end.getHours()).slice(-2) + ("00" + avail.end.getMinutes()).slice(-2)
              }); // avail.start 〜 avail.end
            }
            const compareStart = (a: StartEnd, b: StartEnd) => {
              return a.start.getTime() - b.start.getTime();
            }
            otherList.sort(compareStart);
            // Console.dir("otherList: " + JSON.stringify(otherList))
            let lastTime = avail.start;

            for (let i = 0; i < number0fOthers; i++) {
              if (i === number0fOthers - 1) { // lastTime 〜 otherList[i] 〜 avail.endで予約できるか見る
                // Console.log("lastTime.getTime() + millisecondsOfThirtyMinutes: " + lastTime.getTime() + millisecondsOfThirtyMinutes);
                // Console.log("otherList[i].start.getTime(): " + otherList[i].start.getTime());
                // Console.log("otherList[i].end.getTime() + millisecondsOfThirtyMinutes: " + otherList[i].end.getTime() + millisecondsOfThirtyMinutes);
                // Console.log("avail.end.getTime(): " + avail.end.getTime());
                if (lastTime.getTime() + millisecondsOfThirtyMinutes < otherList[i].start.getTime()) {
                  list.push({
                    start: ("00" + lastTime.getHours()).slice(-2) + ("00" + lastTime.getMinutes()).slice(-2),
                    end: ("00" + otherList[i].start.getHours()).slice(-2) + ("00" + otherList[i].start.getMinutes()).slice(-2)
                  }); // lastTime 〜 otherList[i].start
                }
                if (otherList[i].end.getTime() + millisecondsOfThirtyMinutes < avail.end.getTime()) {
                  list.push({
                    start: ("00" + otherList[i].end.getHours()).slice(-2) + ("00" + otherList[i].end.getMinutes()).slice(-2),
                    end: ("00" + avail.end.getHours()).slice(-2) + ("00" + avail.end.getMinutes()).slice(-2)
                  }); // otherList[i].end 〜 avail.end
                }
              } else { // lastTime 〜 otherList[i] 予約できるかみて、lastTimeにotherList[i].endをセット
                // Console.log("lastTime.getTime() + millisecondsOfThirtyMinutes: " + lastTime.getTime() + millisecondsOfThirtyMinutes);
                // Console.log("otherList[i].start.getTime(): " + otherList[i].start.getTime());
                if (lastTime.getTime() + millisecondsOfThirtyMinutes < otherList[i].start.getTime()) {
                  list.push({
                    start: ("00" + lastTime.getHours()).slice(-2) + ("00" + lastTime.getMinutes()).slice(-2),
                    end: ("00" + otherList[i].start.getHours()).slice(-2) + ("00" + otherList[i].start.getMinutes()).slice(-2)
                  }); // lastTime 〜 otherList[i].start
                }
                lastTime = otherList[i].end;
              }
            }
          });
          return { date: oneDay.getMonth() + 1 + '月' + oneDay.getDate() + '日', avail: list };
        }
        return { date: oneDay.getMonth() + 1 + '月' + oneDay.getDate() + '日', avail: [] };
      });
      setAllAvailTimeList(allAvailability);
      setFiveAvailTimeList(allAvailability.slice(0, 5))
    } catch (e) {
      setAvailTimeList(null);
      Console.error(e);
    }
  }

  const onChangeDatetime = (value: string | null | undefined) => {
    if (value) {
      setReservationTime(value);
      setAvailability(value);
      buttonJudge(selectDuration, value)
    }
  }

  const setCourse = (course: any) => {
    Console.dir(course);
    setSelectCourse(course);
    buttonJudge(course?.tier.minute);
  }

  type SetDataType = {
    title: string;
    start: Date;
    end: Date;
    duration: number;
    courseId: string;
  };

  const reserve = async () => {
    const setData: SetDataType = {
      title: currentUser?.displayName || "",
      start: new Date(reservationTime),
      end: end,
      duration: selectDuration,
      courseId: selectCourse.tier.id,
    }
    Console.log(setData)
    await createMeeting(setData);
  }

  const next = () => {
    const nextStart = start + 5;
    setFiveAvailTimeList(allAvailTimeList.slice(nextStart, nextStart + 5));
    setStart(nextStart);
  }

  const back = () => {
    const nextStart = start - 5;
    setFiveAvailTimeList(allAvailTimeList.slice(nextStart, nextStart + 5));
    setStart(nextStart);
  }

  useEffect(() => {
    (async () => {
      const teacherDoc: any = await firebase.getInstructor(selectTeacherUid).get().then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        } else {
          Console.log("snapshot not exists");
        }
      }).catch(e => Console.error(e));

      const { courseEnrolled, courseIdList } = await firebase.getCourseEnrolledData(currentUser?.uid);

      const availCourseId = courseIdList.filter((id: string) => courseEnrolled[id].status === "ongoing" && courseEnrolled[id].numberOfClasses > 0);
      const availCourseList: any[] = await Promise.all(availCourseId.map(async (id: string) => {
        return {
          ...courseEnrolled[id],
          tier: await firebase.findTier(id.slice(8))
        }
      }));

      const teacherSchedule = teacherDoc.schedule;
      const scheduleIdList = Object.keys(teacherSchedule).filter(id => teacherSchedule[id].status === "reservation").map(id => id.slice(11));
      const reservationClassroomsList = await Promise.all(scheduleIdList.map(async id => {
        return await firebase.getClassroom(id).get().then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          } else {
            Console.log("done firebase.getClasssroom(id).get() but not exist");
          }
        }).catch(e => Console.error(e));
      }))

      if (teacherDoc) {
        // Console.dir("teacherDoc: " + JSON.stringify(teacherDoc.availability));
        setTeacherData(teacherDoc);
        setAvailability(initDate.toISOString(), teacherDoc, reservationClassroomsList);
        setAllAvailability(initDate.toISOString(), teacherDoc, reservationClassroomsList);
      }

      setOtherReservations(reservationClassroomsList);
      setCourseList(availCourseList);
      setSelectCourse(availCourseList[0]);
      Console.log(availCourseList[0]);
      setSelectDuration(availCourseList[0]?.tier.minute);
      buttonJudge(availCourseList[0]?.tier.minute);
      setIsLoading(false);
    })();
    // 予約可能なコースのデータのリスト
  }, []);

  return (
    <div className={classes.component}>
      <IonCard className={classes.card}>
        <div className={classes.cardTop}>
          <p style={{}}>予約可能時間</p>
          <div style={{ position: 'absolute', right: '10px' }}>
            <IconButton
              disabled={start === 0}
              onClick={() => back()}
            ><ChevronLeftRoundedIcon /></IconButton>
            <IconButton
              disabled={start === 25}
              onClick={() => next()}
            ><ChevronRightRoundedIcon /></IconButton>
          </div>
        </div>
        <IonInfiniteScroll>
          {fiveAvailTimeList.map((obj: AllAvailType) => (
            <>
              <div className={classes.oneLine}>
                <div>
                  <IonLabel>{obj.date}</IonLabel>
                </div>
                {obj.avail.length === 0 ? (
                  <div className={classes.hyphen}>
                    <IonText> - </IonText>
                  </div>
                ) : (
                    <div className={classes.time}>
                      {obj.avail.map((avail: StartEndString) => (
                        <div>
                          <IonText>{avail.start.slice(0, 2) + ":" + avail.start.slice(2, 4) + "〜" + avail.end.slice(0, 2) + ":" + avail.end.slice(2, 4)}</IonText>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
              <br />
            </>
          ))}
        </IonInfiniteScroll>
      </IonCard>
      <div className={classes.itemComponent}>
        <IonLabel>{reservationTime.slice(5, 7) + " / " + reservationTime.slice(8, 10)} の予約可能時間</IonLabel>
        {availTimeList ?
          <>
            {availTimeList.map((avail: any) => <>
              <IonList>
                <IonText>{avail.start.slice(0, 2) + ":" + avail.start.slice(2, 4) + "〜" + avail.end.slice(0, 2) + ":" + avail.end.slice(2, 4)}</IonText>
              </IonList>
            </>)}

          </> :
          <>
            <IonList>
              <IonText>この日は予約可能な時間がありません。</IonText>
            </IonList>
          </>
        }

      </div>
      <div>
        <IonItem>
          <IonLabel>予約日を選択</IonLabel>
          <IonDatetime
            value={reservationTime}
            min={minDate.toISOString()}
            max={maxDate.toISOString()}
            displayFormat={"YYYY年 M / D"}
            onIonChange={e => onChangeDatetime(e.detail.value!)}
          />
        </IonItem>
        <IonItem>
          <IonLabel>予約時間を選択</IonLabel>
          <IonDatetime
            value={reservationTime}
            min={minDate.toISOString()}
            max={maxDate.toISOString()}
            minuteValues="0,15,30,45"
            displayFormat={"HH:mm"}
            onIonChange={e => onChangeDatetime(e.detail.value!)}
          />
        </IonItem>
      </div>
      <div>
        <IonItem>
          <IonLabel>プランを選択</IonLabel>
          {courseList.length === 0 ?
            <>
              <IonText>予約可能なプランがありません。</IonText>
            </> :
            <>
              <IonSelect value={selectCourse} onIonChange={e => setCourse(e.detail.value)} >
                {courseList.map(course => <IonSelectOption
                  value={course}
                >{course?.tier.title}</IonSelectOption>)}
              </IonSelect>
            </>
          }
        </IonItem>
      </div>
      <div>
        <IonButton disabled={isButton} onClick={() => reserve()}>予約する</IonButton>
      </div>
    </div>
  );
};

export default MobileReservation;
