import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Container, Fab } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { IonContent } from '@ionic/react';

export const Terms = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [open]);

  return (
    <div>
      <IonContent>
        {/* <Fab variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}>プロフィールを見る ></Fab> */}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'ランデミーこどもの音楽教室サービス利用規約'}
          </DialogTitle>
          <DialogContent>
            <Container>
              <ReactMarkdown>
                {`
本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当社と利用者の皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。


第1条（適用）

1　本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。

2　当社が当社ウェブサイト上で掲載する本サービス利用に関するルール（https://langdemy.com）その他において配布、配信する文書等に規定する内容（以下「個別規約」といいます。）は、当該利用者との間で本規約の一部を構成するものとします。

3　本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。

第2条（定義）


本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。

⑴　「サービス利用契約」とは、本規約を契約条件として当社と利用者の間で締結される、本サービスの利用契約を意味します。

⑵　「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。

⑶　「コンテンツ」とは、当社が本サービスにおいて配信その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。

⑷　「当社」とは、Langdemy株式会社を意味します。

⑸　「当社ウェブサイト」とは、そのドメインが「https://langdemy.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。

⑹　「利用者」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。

⑺　「本サービス」とは、当社が提供する「ランデミーこどもの音楽教室」という名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。


第3条（登録）

1　本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。

2　当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の利用者としての登録は、当社が本項の通知を行ったことをもって完了したものとします。

3　前項に定める登録の完了時に、サービス利用契約が利用者と当社の間に成立し、利用者は本サービスを本規約に従い利用することができるようになります。

4　当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。

⑴　当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合

⑵　未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合

⑶　反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合

⑷　過去当社との契約に違反した者またはその関係者であると当社が判断した場合

⑸　第11条に定める措置を受けたことがある場合

⑹　その他、登録を適当でないと当社が判断した場合



第4条（登録事項の変更）

利用者は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。


第5条（パスワード及びユーザーIDの管理）

1　利用者は、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。

2　パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は利用者が負うものとします。


第6条（利用料金及び支払方法）

1　利用者は、本サービス利用の対価として、別途当社が定め、当社ウェブサイトに表示する利用料金を、当社が指定する支払方法（受講用電子チケット（以下、単に「チケット」といいます。）を購入する方法によります。）により当社に支払うものとします。

2　チケットの有効期限は、購入日から有効期限までとします。当社は、有効期限切れのチケットについて理由を問わず払戻しいたしません。ただし、甲の責めに帰すべき事由により本サービスが提供されなかった場合にはこの限りではありません。

3　当社は、いつでも本サービスの利用料金の価格を変更することができるものとします。利用料金の価格を変更する場合、当社は、利用者に対し、変更後の価格を適用する日の1か月前までにこれを通知するものとし、変更後価格の適用日後に利用者が本サービスを利用したときには、利用者は当該変更後の価格に同意したものとみなします。


第7条（レッスン予約及びキャンセルポリシー）

1　レッスンの予約は、当社ウェブサイトで予約確定が反映された時点で成立し完了するものとします。

2　利用者は、予約を完了したレッスンの受講を、レッスン開始時刻の前日までに、当社ウェブサイトでキャンセルすることができます。この場合、利用チケットは消費されません（ただし、前項第2項のチケットの有効期限には影響を与えません。）。

3　利用者がキャンセル可能時間を超えてキャンセル手続を行った場合、または無断でレッスンを受けなかった場合は、当社はレッスンを利用したものとみなします。


第8条（禁止事項）

当社は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為を禁止します。

⑴　法令、裁判所の判決、決定若しくは命令、または法令上拘束力のある行政措置に違反する行為及びこれらを助長する行為

⑵　公序良俗に反しまたは善良な風俗を害する行為

⑶　当社、講師その他の第三者の知的財産権またはプライバシーポリシーその他の権利若しくは利益を侵害する行為

⑷　当社または他の利用者その他の第三者に対する詐欺または脅迫行為

⑸　本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること

・過度に暴力的または残虐な表現を含む情報

・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報

・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報

・過度にわいせつな表現を含む情報

・差別を助長する表現を含む情報

・自殺、自傷行為を助長する表現を含む情報

・薬物の不適切な利用を助長する表現を含む情報

・反社会的な表現を含む情報

・チェーンメール等の第三者への情報の拡散を求める情報


・他人に不快感を与える表現を含む情報

⑹　本サービスのネットワークまたはシステム等に過度な負荷をかける行為

⑺　当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為

⑻　本サービスの運営を妨害するおそれのある行為

⑼　当社のネットワークまたはシステム等への不正アクセス

⑽　第三者に成りすます行為

⑾　本サービスの他の利用者のIDまたはパスワードを利用する行為

⑿　本サービスのIDまたはパスワードを他の利用者に利用させる行為

⒀　当社を介さずに講師と直接契約を結び、本サービス外でレッスンを受講する行為、講師と個人的に接触しようとする行為（電子メール・LINE等の送受信を含みますがこれに限りません。）、及び、講師に対する嫌がらせ、不良行為、その他レッスンの進行を妨げる等のハラスメント行為

⒀　当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為

⒁　本サービスの講師及び他の利用者の情報の収集

⒂　当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為

⒃　当社ウェブサイト上で掲載する本サービス利用に関するルール（https://langdemy.com）に抵触する行為

⒄　反社会的勢力等への利益供与

⒅　面識のない異性との出会いを目的とした行為

⒆　前各号の行為を直接または間接に惹起し、または容易にする行為

⒇　その他、当社が不適切と判断する行為


第9条（本サービスの停止等）

当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。

⑴　本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合

⑵　コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合

⑶　地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合

⑷　その他、やむを得ない事由により、当社が停止または中断を必要と判断した場合



第10条（権利帰属）

1　当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。

2　当社は、利用者に対し、本サービス及びコンテンツにつき、本サービスの利用に必要な範囲における非独占的な利用を許諾します。ただし、かかる利用許諾は、第三者に対し再使用許諾する権利を含むものではなく、本規約で明示する場合を除き、利用者に対し、各コンテンツについての知的財産権、所有権類似の権利または自由に処分しうる権利の譲渡または付与を意味するものではありません。

3　本サービス上、当社の商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、利用者その他の第三者に対し、商標等を譲渡し、または本規約で明示する以外の使用を許諾するものではありません。



第11条（登録抹消等）

1　当社は、利用者が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、本サービスの利用の一時停止若しくは制限、アカウントの削除等の措置（以下「利用停止等」といいます。）を講じることができるものとします。

⑴本規約のいずれかの条項に違反した場合

⑵登録事項に虚偽の事実があることが判明した場合

⑶支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合

⑷12か月以上本サービスの利用がない場合

⑸当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合

⑹第3条第4項各号の登録拒絶事由に該当する場合

⑺第8条各号の禁止行為を行ったとき

⑻その他、当社が本サービスの利用または利用者としての登録の継続を適当でないと判断した場合

2　前項各号のいずれかの事由に該当した場合、利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。



第12条（退会）

1　利用者は、当社所定の手続の完了により、本サービスから退会し、自己の利用者としての登録を抹消することができます。

2　退会にあたり、当社に対して負っている債務が有る場合は、利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。

3　退会後の利用者情報の取扱いについては、第16条の規定に従うものとします。


第13条（本サービスの内容の変更、終了）

1　当社は、利用者に事前の通知をすることなく、本サービスの内容の全部または一部を変更または追加することができるものとします。

2　当社は、当社の判断により本サービスの全部または一部の提供・運営を終了することができるものとします。当社は、当社の判断により本サービスの全部または一部の提供・運営を終了する場合、当社が適当と判断する方法で利用者にその旨通知いたします。ただし、緊急の場合は利用者への通知を行わない場合があります。



第14条（保証の否認及び免責）

1　当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示または黙示を問わず何ら保証するものではありません。

2　当社は、当社の故意または重過失により本サービスに関して利用者が被った損害につき、過去６か月間に利用者が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。

3　当社は、利用者またはその他の第三者から本サービスにかかるアプリケーションその他のソフトウェアの開発等を請け負う立場にはなく、本サービスにかかるアプリケーションその他のソフトウェアの開発が遅延、一時停止、または中止された場合であっても、利用者またはその他の第三者に対し、一切の責任を負いません。

4　当社は、本サービスが全ての端末に対応していることを保証するものではなく、また、仮に本サービスの利用開始時に対応していた場合でも、本サービスの利用に供する端末のOSのバージョンアップ等に伴い本サービスの動作に不具合が生じる可能性があることにつき、利用者は予め了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により当該不具合が解消されることを保証するものではありません。

5　利用者は、Google、Facebook、LINE等の第三者サービスの利用規約及びプライバシーポリシーの変更等に伴って、本サービスの一部または全部の利用が制限される可能性があることをあらかじめ了承するものとします。

6　本サービスまたは当社ウェブサイトに関連して利用者と他の利用者または第三者との間において生じた取引、連絡、紛争等については、利用者が自己の責任によって解決するものとします。



第15条（秘密保持）
利用者は、本サービスに関連して当社が利用者に対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。


第16条（利用者情報の取扱い）

1　当社による利用者の利用者情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、利用者はこのプライバシーポリシーに従って当社が利用者の利用者情報を取扱うことについて同意するものとします。

2　当社は、利用者が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、利用者はこれに異議をとなえないものとします。


第17条（本規約等の変更）

1　当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または利用者に通知します。本規約を変更した場合には、当社が別途定める場合を除いて、本サイトに掲示された時点より効力を生じるものとします。ただし、法令上利用者の同意が必要となるような内容の変更の場合は、当社所定の方法で利用者の同意を得るものとします。

2　当該変更内容を本サイトに掲示した後、利用者が本サービスを利用した場合または当社の定める期間内に解約手続をとらなかった場合には、利用者は、規約変更に同意したものとみなします。利用者は、随時、本規約の最新の内容を確認の上、本サービスをご利用ください。


第18条（連絡・通知）

1　本サービスに関する問い合わせその他利用者から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から利用者に対する連絡または通知は、当社の定める方法で行うものとします。

2　当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、利用者は当該連絡または通知を受領したものとみなします。


第19条（サービス利用契約上の地位の譲渡等）

1　利用者は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。

2　当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。


第20条（分離可能性）

本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。


第21条（準拠法及び管轄裁判所）

1　本規約及びサービス利用契約の準拠法は日本法とします。

2　本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。


`}
              </ReactMarkdown>
            </Container>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              閉じる
            </Button>
          </DialogActions> */}
        </Dialog>
      </IonContent>
    </div>
  );
};
