import React from 'react';

const LineAddFriends = () => (
  <a
    href="https://line.me/ti/p/%40187gljwu"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
      alt="友だち追加"
      height="48"
    />
  </a>
);

export default LineAddFriends;
