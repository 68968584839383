import React, { useContext, useEffect, useState } from 'react';
import {
  createTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Copyright from '../components/Copyright';
import firebase from '../hooks/firebase';
import Schedule from '../components/Schedule';
import './Teachers.css';
import { AuthContext } from '../hooks/Auth';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonSkeletonText,
  IonCol,
  IonButton,
  IonFooter,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import Console from '../hooks/console';
import Header from '../components/Header';
// import { tiers } from "../data/planData";

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [customTheme.breakpoints.down('sm')]: {
        maxWidth: 345,
        // height: 769,
      },
      [customTheme.breakpoints.up('sm')]: {
        height: 845,
        maxWidth: 345,
      },
    },
    myCard: {
      // 講師がログインしているとき自分のカードの色を変える
      [customTheme.breakpoints.down('sm')]: {
        // height: 769,
        maxWidth: 345,
        backgroundColor: 'eeeeee',
      },
      [customTheme.breakpoints.up('sm')]: {
        height: 845,
        maxWidth: 345,
        backgroundColor: 'eeeeee',
      },
    },
    grid: {
      [customTheme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
      },
      [customTheme.breakpoints.up('sm')]: {
        margin: theme.spacing(1),
      },
    },
    card: {
      [customTheme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginRight: '0px',
        paddingTop: '0px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        marginBottom: '5px',
      },
      [customTheme.breakpoints.up('sm')]: {
        marginBottom: '5px',
      },
    },
    media: {
      [customTheme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: 0,
        paddingTop: '130%',
      },
      [customTheme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: 0,
        paddingTop: '130%',
      },
    },
    button: {
      [customTheme.breakpoints.down('sm')]: {
        color: '#ffffff',
        backgroundColor: '#FF9A00',
        fontWeight: 'bold',
        fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
      },
      [customTheme.breakpoints.up('sm')]: {
        color: '#ffffff',
        backgroundColor: '#FF9A00',
        fontWeight: 'bold',
        fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
      },
    },
    expand: {
      [customTheme.breakpoints.down('sm')]: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      [customTheme.breakpoints.up('sm')]: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    },
    expandOpen: {
      [customTheme.breakpoints.down('sm')]: {
        transform: 'rotate(180deg)',
      },
      [customTheme.breakpoints.up('sm')]: {
        transform: 'rotate(180deg)',
      },
    },
    avatar: {
      [customTheme.breakpoints.down('sm')]: {
        backgroundColor: red[500],
      },
      [customTheme.breakpoints.up('sm')]: {
        backgroundColor: red[500],
      },
    },
    category: {
      [customTheme.breakpoints.down('sm')]: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
      },
      [customTheme.breakpoints.up('sm')]: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
      },
    },
    center: {
      [customTheme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '13',
      },
      [customTheme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
    box: {
      [customTheme.breakpoints.down('sm')]: {
        height: '10px',
      },
      [customTheme.breakpoints.up('sm')]: {
        height: '10px',
      },
    },
    mobileProfile: {
      width: '60%',
    },
    mobilePlan: {
      width: '40%',
      padding: '0px',
    },
    planCard: {
      [customTheme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '10px',
        width: '100%',
        paddingTop: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
      [customTheme.breakpoints.up('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    bottom: {
      [customTheme.breakpoints.up('sm')]: {
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'flex-end',
        width: '95.14%',
        position: 'absolute',
        bottom: 0,
        // display: 'block',
        // margin: theme.spacing('auto', 1, 2, 1),
        // padding: theme.spacing('auto', 1, 2, 1),
        // height: '100%',
        // marginTop: 'auto',
        // marginBottom: '10px',
      },
    },
  }),
);

const planName: any = {
  ondai: '楽器(プロフェッショナル)プラン',
  takaraduka: '宝塚受験プラン',
  basic: '楽器(ベーシック)プラン',
  voiceTraining: 'ボイスマスタープログラム',
};

const Agencies = (props: any) => {
  const { history } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [teacherUid, setTeacherUid] = useState<string>(''); // currentUserが講師ならuid, 講師でないなら空文字
  const [agencyId, setAgencyId] = useState<string>('');
  const [scheduleIsShown, setScheduleIsShown] = useState<boolean>(false);
  const [selectTeacherUid, setSelectTeacherUid] = useState<string>(''); // 選択された講師
  const [specialization, setSpecialization] = useState<string[]>([]);
  const [teacherName, setTeacherName] = useState<string>('');
  const [authCode, setAuthCode] = useState<string | null>('');
  const [image, setImage] = useState<string>('');
  const [workInfo, setWorkInfo] = useState<any>({});
  const [toastIsShown, setToastIsShown] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [isRendar, setIsRendar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [isSkelton, setIsSkelton] = useState(true);

  const { currentUser } = useContext(AuthContext);

  const [rows, setRows] = useState([{}]);

  const showSchedule = (card: any) => {
    setSelectTeacherUid(card.profile.uid || '');
    setImage(card.profile?.pic?.profile);
    setWorkInfo(card.workInfo);
    setSpecialization(Object.keys(card.workInfo.specialization) || []);
    setTeacherName(card.profile.name || '');
    setScheduleIsShown(true);
  };

  const getParam = (name: string) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  // スマホかどうかの判定
  const isSmartPhone = () => {
    if (
      window.matchMedia &&
      window.matchMedia('(max-device-width: 768px)').matches
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const redirectOAuth = () => {
    Console.log('redirectOAuth');
    const host = window.location.host;
    const headText = host.slice(0, 5);
    let endpoint;
    if (headText === 'local') {
      // localhost`
      // endpoint = `https://zoom.us/oauth/authorize?response_type=code&client_id=XCgf7y8ORSpkCadZYxnTw&redirect_uri=http://localhost:8100/Teachers&state=${currentUser?.uid}`;
      endpoint = `https://zoom.us/oauth/authorize?response_type=code&client_id=XCgf7y8ORSpkCadZYxnTw&redirect_uri=https://dev-langdemy.web.app/Teachers&state=${currentUser?.uid}`;
      Console.log(endpoint, currentUser?.uid);
      // return;
    } else if (headText === 'langd' || headText === 'music') {
      // 本番環境
      endpoint = `https://zoom.us/oauth/authorize?response_type=code&client_id=XCgf7y8ORSpkCadZYxnTw&redirect_uri=https://langdemy.com/Teachers&state=${currentUser?.uid}`;
    } else {
      endpoint = `https://zoom.us/oauth/authorize?response_type=code&client_id=XCgf7y8ORSpkCadZYxnTw&redirect_uri=https://${headText}-langdemy.web.app/Teachers&state=${currentUser?.uid}`;
    }
    Console.log(endpoint);
    window.location.href = endpoint;
  };

  const createBankAccount = () => {
    let url;
    const host = window.location.host;
    const headText = host.slice(0, 5);
    const STATE_VALUE = currentUser?.uid;
    const email = currentUser?.email;
    let envVar;
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'prod-langdemy') {
      // envVar = 'apis'; // GKE
      envVar = 'prod-langdemy';
    } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'stg-langdemy') {
      // envVar = 'stg-apis'; // GKE
      envVar = 'stg-langdemy';
    } else {
      // envVar = 'dev-apis'; // GKE
      envVar = 'dev-langdemy';
    }

    //const domain = `https://${envVar}.langdemy.com/`; //GKE
    const domain = `https://asia-northeast1-${envVar}.cloudfunctions.net/`; // CloudFunctions

    if (headText === 'langd' || headText === 'music' || headText === 'stg-l') {
      url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHsbDMIv1L1S1WroseuAV1ZEgS67br&state=${STATE_VALUE}&stripe_user[email]=${email}`; // 本番環境
    } else {
      url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHFDZkLhOwDM7cL3P7SiQcoi4VPm4h&state=${STATE_VALUE}&stripe_user[email]=${email}`; // テスト環境
    }
    window.open(url);
  };

  useEffect(() => {
    if (currentUser === null) {
      history.push('/');
      return;
    }
    isSmartPhone();
    (async () => {
      if (currentUser?.uid) {
        const authCode = getParam('code');
        const state = getParam('state');
        // Console.log('authCode', authCode);
        // Console.log('state', state);

        if (authCode === null && state === null) {
          // redirectOAuth();
        } else {
          setAuthCode(authCode);
        }

        const uid: string = currentUser?.uid;
        if (await firebase.isInstructorUid(uid)) {
          setTeacherUid(uid);
        }
        if (await firebase.isAgencyUserUid(uid)) {
          const agencyId = await firebase
            .getAgencyUser(uid)
            .get()
            .then(snapshot => {
              return snapshot.data()?.agencyId;
            });
          setAgencyId(agencyId);
          Console.log('agencyId', agencyId);
        }
      }

      firebase.searchInstructors().onSnapshot(async (snapshot: any) => {
        const rows = await snapshot.docs.map((doc: any) => {
          if (
            doc !== null &&
            doc.exists &&
            doc.data().hasOwnProperty('profile')
          ) {
            return doc.data();
          }
        });
        rows.sort((a: any, b: any) => {
          if (a.sortKey < b.sortKey) return -1;
          if (a.sortKey > b.sortKey) return 1;
          return 0;
        });
        rows.forEach((row: any) => {
          if (
            row.profile.uid === currentUser?.uid &&
            !row.bankAccount.connectId
          ) {
            setMessage(
              '決済会社Stripeで銀行口座登録がされていないか、決済会社の承認がされていない可能性がございます。銀行口座登録がまだの場合は、ご対応をお願いいたします。\n既にご対応済みの場合や、ご質問等ございましたら、弊社LINE公式アカウントまでお問い合わせください。',
            );
            setToastIsShown(true);
            setDisabled(true);
          }
        });
        setRows(rows);
        setIsSkelton(false);
      });
      setIsRendar(true);
    })();
  }, [currentUser, history]);

  return (
    <IonPage>
      <Header title="代理店契約講師一覧" />
      {isRendar ? (
        <>
          {!scheduleIsShown ? (
            <>
              <IonContent>
                {isSkelton ? (
                  <>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated />
                    </div>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </div>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: '88%' }} />
                      <IonSkeletonText animated style={{ width: '70%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </div>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: '88%' }} />
                      <IonSkeletonText animated style={{ width: '70%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </div>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: '88%' }} />
                      <IonSkeletonText animated style={{ width: '70%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </div>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: '60%' }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: '88%' }} />
                      <IonSkeletonText animated style={{ width: '70%' }} />
                      <IonSkeletonText animated style={{ width: '60%' }} />
                    </div>
                  </>
                ) : (
                  <>
                    <Grid
                      className={classes.grid}
                      direction="row"
                      justify="flex-start"
                      alignItems="stretch"
                    >
                      <Grid container item xs={12} spacing={1}>
                        {rows.map((card: any, key) => {
                          if (card.workInfo.reportingTo === agencyId)
                            return (
                              <>
                                {isMobile ? ( // スマホの先生カード
                                  <IonCol
                                    size="12"
                                    key={key}
                                    className="custom-column-padding"
                                  >
                                    <Card className={classes.root}>
                                      <CardHeader
                                        title={card.profile?.name}
                                        subheader={card.workInfo?.career}
                                        align="center"
                                        className={classes.card}
                                      />

                                      <div style={{ display: 'flex' }}>
                                        <div className={classes.mobileProfile}>
                                          <CardMedia
                                            className={classes.media}
                                            image={card.profile?.pic?.profile}
                                            title={card.profile?.name}
                                          />
                                        </div>
                                        <div className={classes.mobilePlan}>
                                          <CardContent className={classes.card}>
                                            {card.workInfo?.specialization &&
                                              Object.keys(
                                                card.workInfo?.specialization,
                                              ).length >= 1 ? (
                                              Object.keys(
                                                card.workInfo?.specialization,
                                              ).map((planType: string) => {
                                                return (
                                                  <Card
                                                    className={classes.planCard}
                                                  >
                                                    <p
                                                      className={classes.center}
                                                    >
                                                      {planName[planType]}
                                                    </p>
                                                    {card.workInfo
                                                      ?.specialization[
                                                      planType
                                                    ] &&
                                                      card.workInfo
                                                        ?.specialization[planType]
                                                        .length >= 2
                                                      ? card.workInfo?.specialization[
                                                        planType
                                                      ].map(
                                                        (
                                                          categoryArray: any,
                                                        ) => {
                                                          return (
                                                            <h4
                                                              className={
                                                                classes.center
                                                              }
                                                            >
                                                              {categoryArray ||
                                                                '  '}
                                                            </h4>
                                                          );
                                                        },
                                                      )
                                                      : card.workInfo?.specialization[
                                                        planType
                                                      ].map(
                                                        (
                                                          categoryArray: any,
                                                        ) => {
                                                          return (
                                                            <>
                                                              <h4
                                                                className={
                                                                  classes.center
                                                                }
                                                              >
                                                                {categoryArray ||
                                                                  '  '}
                                                              </h4>
                                                              <Box
                                                                className={
                                                                  classes.box
                                                                }
                                                              ></Box>
                                                            </>
                                                          );
                                                        },
                                                      )}
                                                  </Card>
                                                );
                                              })
                                            ) : (
                                              <>
                                                <Box
                                                  className={classes.box}
                                                ></Box>
                                                <Box
                                                  className={classes.box}
                                                ></Box>
                                              </>
                                            )}
                                          </CardContent>
                                        </div>
                                      </div>

                                      <CardContent className={classes.card}>
                                        {currentUser ? (
                                          <>
                                            {disabled ? (
                                              <>
                                                <Button
                                                  className={classes.button}
                                                  fullWidth
                                                  onClick={() =>
                                                    createBankAccount()
                                                  }
                                                >
                                                  銀行口座登録をする
                                                </Button>
                                              </>
                                            ) : (
                                              <>
                                                <Button
                                                  className={classes.button}
                                                  fullWidth
                                                  onClick={() =>
                                                    showSchedule(card)
                                                  }
                                                >
                                                  予約可能時間を調整
                                                </Button>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <IonButton
                                            size="small"
                                            color="primary"
                                            routerLink="/login"
                                          >
                                            ログイン
                                          </IonButton>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </IonCol>
                                ) : (
                                  // PCの先生カード
                                  <IonCol
                                    size="6"
                                    size-sm="2"
                                    key={key}
                                    className="custom-column-padding"
                                  >
                                    <Card
                                      className={
                                        // teacherUid === card.profile.uid
                                        //   ? classes.myCard
                                        //   :
                                        classes.root
                                      }
                                    >
                                      {/* カードの上の部分 */}
                                      <CardHeader
                                        title={card.profile?.name}
                                        subheader={card.workInfo?.career}
                                        align="center"
                                        className={classes.card}
                                      />
                                      {/* カードの中身、プランなど */}
                                      <CardContent className={classes.card}>
                                        {card.workInfo?.specialization &&
                                          Object.keys(
                                            card.workInfo?.specialization,
                                          ).length >= 1 ? (
                                          Object.keys(
                                            card.workInfo?.specialization,
                                          ).map((planType: string) => {
                                            return (
                                              <Card
                                                className={classes.planCard}
                                              >
                                                <h4 className={classes.center}>
                                                  {planName[planType]}
                                                </h4>
                                                {card.workInfo?.specialization[
                                                  planType
                                                ] &&
                                                  card.workInfo?.specialization[
                                                    planType
                                                  ].length >= 2
                                                  ? card.workInfo?.specialization[
                                                    planType
                                                  ].map(
                                                    (categoryArray: any) => {
                                                      return (
                                                        <p
                                                          className={
                                                            classes.center
                                                          }
                                                        >
                                                          {categoryArray ||
                                                            '  '}
                                                        </p>
                                                      );
                                                    },
                                                  )
                                                  : card.workInfo?.specialization[
                                                    planType
                                                  ].map(
                                                    (categoryArray: any) => {
                                                      return (
                                                        <>
                                                          <p
                                                            className={
                                                              classes.center
                                                            }
                                                          >
                                                            {categoryArray ||
                                                              '  '}
                                                          </p>

                                                          <Box
                                                            className={
                                                              classes.box
                                                            }
                                                          ></Box>
                                                        </>
                                                      );
                                                    },
                                                  )}
                                              </Card>
                                            );
                                          })
                                        ) : (
                                          <>
                                            <Box className={classes.box}></Box>
                                            <Box className={classes.box}></Box>
                                          </>
                                        )}
                                      </CardContent>
                                      {/* カードの下の部分、講師の写真と名前 */}
                                      <div className={classes.bottom}>
                                        {/* 講師の写真と名前 */}
                                        <CardMedia
                                          className={classes.media}
                                          image={card.profile?.pic?.profile}
                                          title={card.profile?.name}
                                        />
                                        <CardContent className={classes.card}>
                                          {currentUser ? (
                                            <>
                                              {disabled ? (
                                                <>
                                                  <Button
                                                    className={classes.button}
                                                    fullWidth
                                                    onClick={() =>
                                                      createBankAccount()
                                                    }
                                                  >
                                                    銀行口座登録をする
                                                  </Button>
                                                </>
                                              ) : (
                                                <>
                                                  {/* 予約可能時間を調整できる */}
                                                  <Button
                                                    className={classes.button}
                                                    fullWidth
                                                    onClick={() =>
                                                      showSchedule(card)
                                                    }
                                                  >
                                                    予約可能時間を調整
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <IonButton
                                              size="small"
                                              color="primary"
                                              routerLink="/login"
                                            >
                                              ログイン
                                            </IonButton>
                                          )}
                                        </CardContent>
                                      </div>
                                    </Card>
                                  </IonCol>
                                )}
                              </>
                            );
                        })}
                      </Grid>
                    </Grid>
                  </>
                )}
                {/* プランデータを一つずつ更新する */}
                {/* <IonContent>
              {tiers.map(tier => <IonButton onClick={() => firebase.setNewPlan(tier.id, tier)}>{tier.title}</IonButton>)}
            </IonContent> */}
                <IonFooter className="ion-no-border">
                  <IonToolbar>
                    <Box mt={8}>
                      <Copyright />
                    </Box>
                  </IonToolbar>
                </IonFooter>
                <IonToast
                  isOpen={toastIsShown}
                  onDidDismiss={() => setToastIsShown(false)}
                  message={message}
                  duration={100000}
                />
              </IonContent>
            </>
          ) : (
            <>
              <Schedule
                selectTeacherUid={selectTeacherUid}
                history={history}
                specialization={specialization}
                teacherName={teacherName}
                authCode={authCode}
                image={image}
                closeSchedule={setScheduleIsShown}
                workInfo={workInfo}
                isOperator={false}
                agencyId={agencyId}
              />
            </>
          )}
        </>
      ) : (
        <>
          <IonSpinner name="bubbles" />
        </>
      )}
    </IonPage>
  );
};

export default withRouter(Agencies);
