import React, { useContext, useEffect, useState } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonPage,
  IonButton,
  IonToast,
} from '@ionic/react';
import { Switch, Avatar } from '@material-ui/core';
import './Profile.css';
import Copyright from '../components/Copyright';
import firebase from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import DeleteUserModal from '../components/DeleteUserModal';
import EmailPasswordLoginModal from '../components/EmailPasswordLoginModal';
import Header from '../components/Header';
import ReservationStatus from '../components/ReservationStatus';
import AttendedStatus from '../components/AttendedStatus';
import TeacherReservationStatus from '../components/TeacherReservationStatus';
import { withRouter } from 'react-router-dom';
import Console from '../hooks/console';
import LessonReady from '../components/LessonReady';
import Chat from '../components/Chat';
import moment from 'moment';

type data = {
  title?: string;
  start?: string | Date;
  end?: any;
  id?: string;
  uid?: string;
  duration?: any;
  courseId?: string;
  teacherUid?: string;
  studentUid?: string;
  teacherName?: string;
  isClick?: boolean;
  start_url?: string;
  join_url?: string;
  connectId?: string;
  productId?: string;
  isOnline?: string;
};

const Profile = (props: any) => {
  const { history } = props;
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [chatShowModal, setChatShowModal] = useState(false);
  const [teacherUid, setTeacherUid] = useState<string>(''); // currentUserが講師ならuid, 講師でないなら空文字
  const [agencyUserUid, setAgencyUserUid] = useState<string>('');
  const [directorUid, setDirectorUid] = useState<string>('');
  const [reportingTo, setReportingTo] = useState<string>('');
  const [isBeforeReserveChat, setIsBeforeReserveChat] =
    useState<boolean>(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const [message, setMessage] = useState('');
  const [lessonMessage, setLessonMessage] = useState('');
  const [linkedProvider, setLinkedProvider] = useState('');
  const [openLessonReady, setOpenLessonReady] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState('');
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [classroomData, setClassroomData] = useState<any>({});
  const [checked, setChecked] = useState<any>(1);
  const [chatClassroomsIdRow, setChatClassroomsIdRow] = useState<any>([]);
  const [classroomsId, setClassroomsId] = useState('');
  const [eventList, setEventList] = useState<any>([]);
  const [teacherEventList, setTeacherEventList] = useState<any>([]);
  const [beforeMinute, setBeforeMinute] = useState<number>(0);
  const googleProvider = 'google.com';
  const passwordProvider = 'password';

  const uid = currentUser?.uid;
  const modal = () => {
    if (showModal)
      return (
        <DeleteUserModal
          yesFunc={async () => {
            await firebase.deleteUser(uid);
            // await cancellationCourse();
            alert('正常に削除されました。');
            history.push('/login');
          }}
          NoFunc={() => setShowModal(false)}
          setToastIsShown={setToastIsShown}
          setMessage={setMessage}
          uid={uid || ''}
          provider={linkedProvider}
        ></DeleteUserModal>
      );
    else return <></>;
  };

  const loginModal = () => {
    if (openLoginModal) {
      return (
        <EmailPasswordLoginModal
          yesFunc={async () => {
            setOpenLoginModal(false);
            setLinkedProvider('');
          }}
          NoFunc={() => setOpenLoginModal(false)}
          setToastIsShown={setToastIsShown}
          setMessage={setMessage}
          currentUser={currentUser}
        />
      );
    }
  };

  const submitTeacherUid = () => {
    history.push('/Teachers');
  };

  const createBankAccount = async () => {
    if (await firebase.isAgencyUserUid(currentUser?.uid)) {
      const agencyUserData: any = await firebase
        .getAgencyUser(currentUser?.uid)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          } else {
            Console.log('schedule not found');
            return {};
          }
        });
      const agencyId = agencyUserData?.agencyId;
      firebase.searchAgencies().onSnapshot(async (snapshot: any) => {
        const agencyAllData = await snapshot.docs.map((doc: any) => {
          return doc.data();
        });
        const agency = agencyAllData.find((doc: any) => {
          return doc.profile.uid === agencyId;
        });
        Console.log('agency?.profile?.uid', agency?.profile?.uid);
        let url;
        const host = window.location.host;
        const headText = host.slice(0, 5);
        const STATE_VALUE = agency?.profile?.uid;
        const email = currentUser?.email;
        let envVar;
        if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'prod-langdemy') {
          // envVar = 'apis'; // GKE
          envVar = 'prod-langdemy';
        } else if (
          process.env.REACT_APP_FIREBASE_PROJECT_ID === 'stg-langdemy'
        ) {
          // envVar = 'stg-apis'; // GKE
          envVar = 'stg-langdemy';
        } else {
          // envVar = 'dev-apis'; // GKE
          envVar = 'dev-langdemy';
        }
        //const domain = `https://${envVar}.langdemy.com/`; //GKE
        const domain = `https://asia-northeast1-${envVar}.cloudfunctions.net/`; // CloudFunctions

        if (
          headText === 'langd' ||
          headText === 'music' ||
          headText === 'stg-l'
        ) {
          url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHsbDMIv1L1S1WroseuAV1ZEgS67br&state=${STATE_VALUE}&stripe_user[email]=${email}`; // 本番環境
        } else {
          url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHFDZkLhOwDM7cL3P7SiQcoi4VPm4h&state=${STATE_VALUE}&stripe_user[email]=${email}`; // テスト環境
        }
        window.open(url);
      });
    } else {
      let url;
      const host = window.location.host;
      const headText = host.slice(0, 5);
      const STATE_VALUE = currentUser?.uid;
      const email = currentUser?.email;
      let envVar;
      if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'prod-langdemy') {
        // envVar = 'apis'; // GKE
        envVar = 'prod-langdemy';
      } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'stg-langdemy') {
        // envVar = 'stg-apis'; // GKE
        envVar = 'stg-langdemy';
      } else {
        // envVar = 'dev-apis'; // GKE
        envVar = 'dev-langdemy';
      }

      //const domain = `https://${envVar}.langdemy.com/`; //GKE
      const domain = `https://asia-northeast1-${envVar}.cloudfunctions.net/`; // CloudFunctions
      if (
        headText === 'langd' ||
        headText === 'music' ||
        headText === 'stg-l'
      ) {
        url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHsbDMIv1L1S1WroseuAV1ZEgS67br&state=${STATE_VALUE}&stripe_user[email]=${email}`; // 本番環境
      } else {
        url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${domain}stripeConnect&client_id=ca_DEuHFDZkLhOwDM7cL3P7SiQcoi4VPm4h&state=${STATE_VALUE}&stripe_user[email]=${email}`; // テスト環境
      }
      window.open(url);
    }
  };

  const showPricingTable = () => {
    history.push('/pricing-table');
  };

  const cancellationCourse = async () => {
    const userDoc: any = await firebase
      .getStudent(currentUser?.uid)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        }
      })
      .catch(e => {});

    const courseEnrolled = userDoc.courseEnrolled || {};
    Object.keys(courseEnrolled).map(id => {
      courseEnrolled[id].status = 'cancel';
      courseEnrolled[id].completedAt = new Date();
    });
    const stripeData = userDoc.stripeData;
    const data = {
      id: stripeData.subscription,
    };
    const result: any = await firebase.cancelSubscription(data);
    const setData = {
      courseEnrolled: {
        ...courseEnrolled,
      },
      stripeData: {
        ...stripeData,
        subscription: '',
      },
      updatedAt: new Date(),
      updatedBy: currentUser?.uid,
    };
    firebase
      .getStudent(currentUser?.uid)
      .update(setData)
      .then(() => {})
      .catch(e => {});
  };

  const handleChange = async () => {
    let checkedNumber = 1;
    if (checked === 0) {
      checkedNumber = 1;
    }
    if (checked === 1) {
      checkedNumber = 0;
    }
    setChecked(checkedNumber);
    await firebase.emailNotifySwitch(checkedNumber, uid);
    // Console.log(checkedNumber);
  };
  const windowOpen = (link: string) => {
    if (isOnline === 'No') {
      return;
    } else if (!window.open(link)) {
      window.location.href = link;
    } else {
      window.open(link, 'Lesson Start !');
    }
  };
  const voice1MonthPlan = async () => {
    return await firebase
      .searchCourses()
      .where('tier.period', '==', 1)
      .where('tier.ticketType', '==', 'regularTicket')
      .where('tier.times', '==', 2)
      .get();
  };

  const takeLesson = async () => {
    setLessonMessage('レッスンを開始します。');
    setToastIsShown(true);
    const scheduleId = `classrooms/${classroomData.id}`;
    Console.log('scheduleId: ' + scheduleId);
    // 講師の場合
    if (teacherUid) {
      const instructorData: any = await firebase
        .getInstructor(teacherUid)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          } else {
            Console.error('snapshot.data() not found');
          }
        })
        .catch(e => Console.error(e));

      const reportingTo = instructorData?.workInfo?.reportingTo;
      const isTaxIncludes = instructorData?.reward?.isTaxIncludes;
      const rewardRation = instructorData?.reward?.rewardRation;
      Console.log('reportingTo', reportingTo);
      Console.log('isTaxIncludes', isTaxIncludes);
      Console.log('rewardRation', rewardRation);

      const planType: any = await firebase
        .getClassroom(classroomData.id)
        .get()
        .then(async snapshot => {
          const classDoc: any = snapshot.data();
          const courseId = classDoc.courseId.replace('courses/', '');
          Console.log('courseId', courseId);
          return await firebase
            .getCourse(courseId)
            .get()
            .then(snapshot => {
              const courseData = snapshot.data();
              const planType = courseData?.tier?.planType;
              // Console.log('planType', planType);
              return planType;
            });
        });
      Console.log('planType', planType);
      // 代理店のボイトレ
      if (
        instructorData.schedule[scheduleId]?.status === 'reservation' &&
        reportingTo &&
        planType === 'voiceTraining'
      ) {
        firebase.searchAgencies().onSnapshot(async (snapshot: any) => {
          const agencyAllData = await snapshot.docs.map((doc: any) => {
            return doc.data();
          });
          Console.log('agencyAllData', agencyAllData);
          const agency = agencyAllData.find((doc: any) => {
            Console.log('filterの中のreportingTo', reportingTo);
            Console.log('filterの中のdoc?.uid', doc.profile.uid);
            return reportingTo === doc.profile.uid;
          });
          Console.log('agency', agency);
          const tier: any = await firebase.findTier(classroomData.productId);
          // 条件文検討
          voice1MonthPlan().then((snapshot: any) => {
            snapshot.forEach(async (doc: any) => {
              const priceAll = Number(doc.data().tier.price);
              let amount = null;
              if (isTaxIncludes) {
                amount = Math.floor(
                  // 「ボイスマスタープログラム 60分 1ヶ月 月2回」の価格 / 「ボイスマスタープログラム 60分 1ヶ月 月2回」のひと月あたりの回数 * 講師の報酬比率
                  (priceAll / doc.data().tier.times) * rewardRation,
                );
              } else {
                // 「ボイスマスタープログラム 60分 1ヶ月 月2回」の価格 / 「ボイスマスタープログラム 60分 1ヶ月 月2回」のひと月あたりの回数 * 講師の報酬比率 / チケットは税込みなので税抜の値に変更する (小数の計算だと値がずれるため10をかけている)
                amount = Math.floor(
                  ((priceAll / doc.data().tier.times) * rewardRation * 10) / 11,
                );
              }
              const agencyData = {
                amount: String(amount),
                uid: agency.profile.uid, // agenciesのuid
                priceId: tier.priceId,
                connectId: agency.bankAccount.connectId, // agenciesのbankaccount
              };
              const agencyAccount: any = await firebase
                .createPaymentIntent(agencyData)
                .then(res => {
                  Console.dir(res);
                  return res.result;
                })
                .catch(error => {
                  Console.error(error);
                });
              Console.log('get account: ' + JSON.stringify(agencyAccount));
              const agencyTransfer = await firebase
                .createTransfer(agencyData)
                .then(res => {
                  Console.dir(res);
                  const setData = {
                    schedule: {
                      [scheduleId]: {
                        status: 'present',
                      },
                    },
                  };
                  firebase
                    .getInstructor(teacherUid)
                    .set(setData, { merge: true })
                    .then(() => {
                      windowOpen(classroomData.start_url);
                    })
                    .catch(e => Console.error(e));
                  return res.result;
                })
                .catch(error => {
                  Console.error(error);
                });
              Console.log('get transfer: ' + agencyTransfer);
            });
          });
        });
      } else if (
        // 通常講師のボイトレ
        instructorData.schedule[scheduleId]?.status === 'reservation' &&
        planType === 'voiceTraining'
      ) {
        const tier: any = await firebase.findTier(classroomData.productId);

        voice1MonthPlan().then((snapshot: any) => {
          snapshot.forEach(async (doc: any) => {
            const priceAll = Number(doc.data().tier.price);
            let amount = null;
            if (isTaxIncludes) {
              amount = Math.floor(
                // 「ボイスマスタープログラム 60分 1ヶ月 月2回」の価格 / 「ボイスマスタープログラム 60分 1ヶ月 月2回」のひと月あたりの回数 * 講師の報酬比率
                (priceAll / doc.data().tier.times) * rewardRation,
              );
            } else {
              // 「ボイスマスタープログラム 60分 1ヶ月 月2回」の価格 / 「ボイスマスタープログラム 60分 1ヶ月 月2回」のひと月あたりの回数 * 講師の報酬比率 / チケットは税込みなので税抜の値に変更する(小数の計算だと値がずれるため10をかけている)
              amount = Math.floor(
                ((priceAll / doc.data().tier.times) * rewardRation * 10) / 11,
              );
            }
            const data = {
              amount: String(amount),
              uid: currentUser?.uid,
              priceId: tier.priceId,
              connectId: classroomData.connectId,
            };
            const account: any = await firebase
              .createPaymentIntent(data)
              .then(res => {
                Console.dir(res);
                return res.result;
              })
              .catch(error => {
                Console.error(error);
              });
            Console.log('get account: ' + JSON.stringify(account));

            if (
              account?.business_type === 'individual' ||
              account?.business_profile?.name === null
            ) {
              // 個人事業主なら
              const taxWithholding = Math.floor(amount * 0.1021); // 源泉徴収額
              data.amount = String(Number(data.amount) - taxWithholding); // 源泉徴収額を引く
              Console.log(
                `priceAll: ${priceAll}, amount: ${amount}, taxWithholding: ${taxWithholding}, data.amount: ${data.amount}`,
              );
            }
            Console.log('送金data', data);
            const transfer = await firebase
              .createTransfer(data)
              .then(res => {
                Console.dir(res);
                const setData = {
                  schedule: {
                    [scheduleId]: {
                      status: 'present',
                    },
                  },
                };
                firebase
                  .getInstructor(teacherUid)
                  .set(setData, { merge: true })
                  .then(() => {
                    windowOpen(classroomData.start_url);
                  })
                  .catch(e => Console.error(e));
                return res.result;
              })
              .catch(error => {
                Console.error(error);
              });
            Console.log('get transfer: ' + transfer);
          });
        });
      } else if (
        // 代理店講師のベーシック、プロフェッショナル
        instructorData.schedule[scheduleId]?.status === 'reservation' &&
        reportingTo &&
        (planType === 'basic' || planType === 'ondai')
      ) {
        firebase.searchAgencies().onSnapshot(async (snapshot: any) => {
          const agencyAllData = await snapshot.docs.map((doc: any) => {
            return doc.data();
          });
          Console.log('agencyAllData', agencyAllData);
          const agency = agencyAllData.find((doc: any) => {
            return reportingTo === doc.profile.uid;
          });
          Console.log('agency', agency);
          const tier: any = await firebase.findTier(classroomData.productId);
          const priceAll: number = Number(tier.price);
          // const amount: number = Math.floor((priceAll * 0.7) / tier.times);
          let amount = null;
          if (isTaxIncludes) {
            // それぞれの楽器チケットの価格 * 講師の報酬割合
            amount = Math.floor((priceAll / tier.times) * rewardRation);
          } else {
            // それぞれの楽器チケットの価格 * 講師の報酬割合 /
            amount = Math.floor(
              ((priceAll / tier.times) * rewardRation * 10) / 11,
            );
          }
          const agencyData = {
            amount: String(amount),
            uid: agency.profile.uid, // agenciesのuid
            priceId: tier.priceId,
            connectId: agency.bankAccount.connectId, // agenciesのbankaccount
          };
          Console.log('agencyData', agencyData);
          const agencyAccount: any = await firebase
            .createPaymentIntent(agencyData)
            .then(res => {
              Console.dir(res);
              return res.result;
            })
            .catch(error => {
              Console.error(error);
            });
          Console.log('get agencyAccount: ' + JSON.stringify(agencyAccount));
          const agencyTransfer = await firebase
            .createTransfer(agencyData)
            .then(res => {
              Console.dir(res);
              const setData = {
                schedule: {
                  [scheduleId]: {
                    status: 'present',
                  },
                },
              };
              firebase
                .getInstructor(teacherUid)
                .set(setData, { merge: true })
                .then(() => {
                  windowOpen(classroomData.start_url);
                })
                .catch(e => Console.error(e));
              return res.result;
            })
            .catch(error => {
              Console.error(error);
            });
          Console.log('get agencyransfer: ' + agencyTransfer);
        });
      } else if (
        // 代理店でもボイトレでもない通常のベーシック、プロフェッショナル
        instructorData.schedule[scheduleId]?.status === 'reservation'
      ) {
        const tier: any = await firebase.findTier(classroomData.productId);
        const priceAll: number = Number(tier.price);
        let amount = null;
        if (isTaxIncludes) {
          // それぞれの楽器チケットの価格 * 講師の報酬割合
          amount = Math.floor((priceAll / tier.times) * rewardRation);
        } else {
          // それぞれの楽器チケットの価格 * 講師の報酬割合 / チケットは税込みなので税抜の値に変更する(小数の計算だと値がずれるため10をかけている)
          amount = Math.floor(
            ((priceAll / tier.times) * rewardRation * 10) / 11,
          );
        }
        const data = {
          amount: String(amount),
          uid: currentUser?.uid,
          priceId: tier.priceId,
          connectId: classroomData.connectId,
        };
        const account: any = await firebase
          .createPaymentIntent(data)
          .then(res => {
            Console.dir(res);
            return res.result;
          })
          .catch(error => {
            Console.error(error);
          });
        Console.log('get account: ' + JSON.stringify(account));
        if (
          account?.business_type === 'individual' ||
          account?.business_profile?.name === null
        ) {
          // 個人事業主なら
          const taxWithholding = Math.floor(amount * 0.1021); // 源泉徴収額
          data.amount = String(Number(data.amount) - taxWithholding); // 源泉徴収額を引く
          Console.log(
            `priceAll: ${priceAll}, amount: ${amount}, taxWithholding: ${taxWithholding}, data.amount: ${data.amount}`,
          );
        }
        const transfer = await firebase
          .createTransfer(data)
          .then(res => {
            Console.dir(res);
            const setData = {
              schedule: {
                [scheduleId]: {
                  status: 'present',
                },
              },
            };
            firebase
              .getInstructor(teacherUid)
              .set(setData, { merge: true })
              .then(() => {
                windowOpen(classroomData.start_url);
              })
              .catch(e => Console.error(e));
            return res.result;
          })
          .catch(error => {
            Console.error(error);
          });
        Console.log('get transfer: ' + transfer);
      } else if (instructorData.schedule[scheduleId]?.status === 'present') {
        windowOpen(classroomData.start_url);
      }
      // 生徒の場合
    } else {
      const studentData: any = await firebase
        .getStudent(currentUser?.uid)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          } else {
            Console.error('snapshot.data() not found');
          }
        })
        .catch(e => Console.error(e));
      if (studentData.schedule[scheduleId].status === 'reservation') {
        const setData = {
          schedule: {
            [scheduleId]: {
              status: 'present',
            },
          },
        };
        firebase
          .getStudent(currentUser?.uid)
          .set(setData, { merge: true })
          .then(() => {
            windowOpen(classroomData.join_url);
          })
          .catch(e => Console.error(e));
      } else {
        windowOpen(classroomData.join_url);
      }
    }
  };

  useEffect(() => {
    currentUser === null && history.push('/login');
    const row: any[] = [];
    if (currentUser) {
      (async () => {
        if (await firebase.isInstructorUid(currentUser?.uid)) {
          setTeacherUid(currentUser?.uid);
        }
        if (await firebase.isAgencyUserUid(currentUser?.uid)) {
          setAgencyUserUid(currentUser?.uid);
        }
        if (await firebase.isDirectorUid(currentUser?.uid)) {
          setDirectorUid(currentUser?.uid);
        }
        if (
          currentUser.providerData.length === 1 &&
          currentUser.providerData[0]?.providerId === googleProvider
        ) {
          setLinkedProvider(googleProvider);
        } else if (
          currentUser.providerData.length === 1 &&
          currentUser.providerData[0]?.providerId === passwordProvider
        ) {
          setLinkedProvider(passwordProvider);
        }
        if (currentUser?.uid) {
          const uid: string = currentUser?.uid;
          const classRoomsData: any[] = [];
          const list: any[] = [];

          firebase
            .searchClassrooms()
            .get()
            .then(async snapshot => {
              snapshot.forEach(async snapshot => {
                const data = snapshot.data();
                if (
                  data.invitees?.instructors[0].path.slice(12) ===
                    currentUser?.uid ||
                  data.invitees?.students[0].path.slice(9) === currentUser?.uid
                ) {
                  classRoomsData.push(data);
                }
              });
              async function createData() {
                await Promise.all(
                  classRoomsData.map(async (item: any) => {
                    let start: any;
                    let end: any;
                    const uid = currentUser?.uid;
                    let teacherName = '';
                    let teacherUid = '';
                    let connectId = '';
                    async function setData(item: any) {
                      await item.invitees?.instructors[0]
                        .get()
                        .then((snapshot: any) => {
                          teacherName = snapshot.data()?.profile?.name;
                          teacherUid = snapshot.data()?.profile?.uid;
                          connectId = snapshot.data()?.bankAccount.connectId;
                        });

                      if (item.date !== undefined) {
                        start = moment(
                          `${item.date.year}${item.date.month}${item.date.day}${item.time.start.hour}${item.time.start.minutes}`,
                          'YYYY-MM-DDTHH:mm',
                        ).format('YYYY-MM-DD HH:mm');
                        end = moment(
                          `${item.date.year}${item.date.month}${item.date.day}${item.time.end.hour}${item.time.end.minutes}`,
                          'YYYY-MM-DDTHH:mm',
                        ).format('YYYY-MM-DDTHH:mm');
                      }
                    }
                    await setData(item);

                    let data: data = {
                      title: '',
                      start: '',
                      end: '',
                      teacherName: '',
                      id: '',
                      duration: '',
                      courseId: '',
                      uid: '',
                      start_url: '',
                      join_url: '',
                      connectId: '',
                      productId: '',
                      studentUid: '',
                      isOnline: '',
                    };
                    async function pushData() {
                      if (uid === currentUser?.uid) {
                        let isClick = true;
                        if (moment(new Date()).isSame(start, 'day')) {
                          isClick = false;
                        }

                        let courseTitle;
                        const tiers = await firebase.tiers();
                        tiers.map((tiersItem: any, index: any) => {
                          const customCourseTitle = String(
                            item.courseId,
                          ).substr(8);

                          if (tiersItem.id === customCourseTitle) {
                            courseTitle = tiersItem.title;
                          }
                        });

                        data = {
                          title: item.title,
                          start: start,
                          end: end,
                          id: item.id,
                          duration:
                            item.duration || item.zoomResponse === undefined
                              ? null
                              : item.zoomResponse.duration,
                          courseId: courseTitle,
                          teacherName: teacherName,
                          teacherUid: teacherUid,
                          studentUid: item.createdBy,
                          isClick: isClick,
                          start_url:
                            item.zoomResponse === undefined
                              ? null
                              : item.zoomResponse.start_url,
                          join_url:
                            item.zoomResponse === undefined
                              ? null
                              : item.zoomResponse.join_url,
                          connectId: connectId,
                          productId:
                            item.courseId === undefined
                              ? ''
                              : item.courseId.slice(8),
                          isOnline: item?.location?.online,
                        };
                        const endTime = new Date(end);
                        const now = new Date();
                        if (now.getTime() < endTime.getTime()) {
                          list.push(data);
                        }
                        list.sort(function (a: any, b: any) {
                          if (a.start < b.start) {
                            return -1;
                          } else {
                            return 1;
                          }
                        });
                      }
                    }
                    await pushData();
                  }),
                );
              }
              Console.log(list);
              await createData();
              async function setList() {
                setEventList(
                  list.filter(
                    (row: any) => row.studentUid === currentUser?.uid,
                  ),
                );
              }
              await setList();
            });
        }
        if (await firebase.isInstructorUid(currentUser?.uid)) {
          (async () => {
            const myData: any = await firebase
              .getInstructor(currentUser?.uid)
              .get()
              .then(snapshot => {
                if (snapshot.exists) {
                  return snapshot.data();
                } else {
                  Console.log('schedule not found');
                  return {};
                }
              })
              .catch(e => Console.error('error in getInstructor'));
            const myScheduleId: string[] = Object.keys(myData.schedule).map(
              id => id.slice(11),
            );
            Console.log('myData.schdule', myData.schedule);
            const getClass = async (id: string) => {
              return await firebase
                .getClassroom(id)
                .get()
                .then(snapshot => {
                  if (snapshot.exists) {
                    Console.log(`get success classroomsId = ${id}`);
                    return snapshot.data();
                  } else {
                    Console.log(`data of classroomsId = ${id} is not found`);
                    return null;
                  }
                })
                .catch(e => {
                  Console.error('error in myClassrooms get ' + e);
                  return null;
                });
            };
            const promises: any[] = myScheduleId.map((id: string) =>
              getClass(id),
            );
            Console.log('promises', promises);
            const myClassrooms: any[] = await Promise.all(promises)
              .then(value => {
                // Console.log("Promise.all value: " + value);
                return value;
              })
              .catch(e => {
                Console.error('error in Promise.all' + e);
                return [];
              });
            Console.log('myclassrooms', myClassrooms);
            const filterClassrooms: any[] = myClassrooms.filter(
              (classroom: any) => classroom !== null,
            );
            // Console.dir("myClassrooms: " + myClassrooms[0].courseId);
            const getEvent = async (classroomData: any) => {
              const studentData = await classroomData.invitees?.students[0]
                .get()
                .then((snapshot: any) => {
                  // Console.log("studentData get: " + snapshot.data());
                  return snapshot.data().profile;
                })
                .catch((e: any) =>
                  Console.error('error in studentData get: ' + e),
                );
              const tier: any = await firebase.findTier(
                classroomData.courseId.slice(8),
              );

              return {
                title: studentData.name,
                start: moment(
                  `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.start.hour}${classroomData.time.start.minutes}`,
                  'YYYY-MM-DDTHH:mm',
                ).format('YYYY-MM-DD HH:mm'),
                end: moment(
                  `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.end.hour}${classroomData.time.end.minutes}`,
                  'YYYY-MM-DDTHH:mm',
                ).format('YYYY-MM-DDTHH:mm'),
                id: classroomData.id,
                duration: classroomData.zoomResponse.duration,
                courseTitle: tier.title,
                studentName: studentData.name,
                studentUid: studentData.uid,
                isClick: !moment(new Date()).isSame(
                  moment(
                    `${classroomData.date.year}${classroomData.date.month}${classroomData.date.day}${classroomData.time.start.hour}${classroomData.time.start.minutes}`,
                    'YYYY-MM-DDTHH:mm',
                  ).format('YYYY-MM-DD HH:mm'),
                  'day',
                ),
                connectId: myData.bankAccount.connectId,
                productId: tier.id,
                start_url: classroomData.zoomResponse.start_url,
                join_url: classroomData.zoomResponse.join_url,
                isOnline: classroomData?.location?.online,
              };
            };
            const eventPromises: any[] = filterClassrooms.map(
              (classroomData: any) => getEvent(classroomData),
            );
            Console.log('eventPromises', eventPromises);
            const events: any[] = await Promise.all(eventPromises)
              .then(value => {
                return value;
              })
              .catch(e => {
                Console.error(e);
                return [];
              });
            Console.log('events', events);
            const now = new Date();
            const setList = events.filter((event: any) => {
              const endTime = new Date(event.end);
              return now.getTime() < endTime.getTime();
            });

            setList.sort((a: any, b: any) => {
              if (a.start < b.start) {
                return -1;
              } else {
                return 1;
              }
            });
            setTeacherEventList(setList);
            Console.log('setList', setList);
          })();
          const reportingTo = await firebase
            .getInstructor(currentUser?.uid)
            .get()
            .then(snapshot => {
              return snapshot.data()?.workInfo?.reportingTo;
            });
          setReportingTo(reportingTo);
          Console.log('reportingTo', reportingTo);
          const isBeforeReserveChat = await firebase
            .getInstructor(currentUser?.uid)
            .get()
            .then(snapshot => {
              return snapshot.data()?.setting?.chat?.isBeforeReserveChat;
            });
          setIsBeforeReserveChat(isBeforeReserveChat);
          Console.log('isBeforeReserveChat', isBeforeReserveChat);
          await firebase
            .searchClassrooms()
            .where(
              'invitees.instructors',
              'array-contains',
              firebase.getInstructor(currentUser?.uid),
            )
            .get()
            .then(async snapshot => {
              // if (new Date().getTime())
              const allClassroomsData = snapshot.docs.map((doc: any) => {
                if (doc.exists) {
                  const data = doc.data();
                  Console.log('classroomsData', data);
                  const id = data.id;
                  return id;
                } else {
                  return {};
                }
              });
              await firebase
                .searchChatrooms()
                .where('teacherUid', '==', uid)
                .get()
                .then(async snapshot => {
                  Console.log('allClassroomsData', allClassroomsData);
                  const allChatroomsData = snapshot.docs.map((doc: any) => {
                    if (doc.exists) {
                      const id = doc.id;
                      return id;
                    } else {
                      return {};
                    }
                  });
                  const classroomChatroomRow = [
                    ...allClassroomsData,
                    ...allChatroomsData,
                  ];
                  Console.log('classroomChatroomRow', classroomChatroomRow);
                  const beforeReservationChatroom = allChatroomsData.filter(
                    (val: any) => !allClassroomsData.includes(val),
                  );
                  Console.log('beforeReservationChatroom', [
                    ...beforeReservationChatroom,
                  ]);
                  setChatClassroomsIdRow(beforeReservationChatroom);
                });
            });
        }
      })();
    }
  }, [currentUser, history]);

  return (
    <>
      {!openLessonReady ? (
        <>
          <IonPage>
            <Header title="マイページ" />
            <IonContent>
              <IonCard>
                <IonCardHeader>
                  {/* 未予約の生徒からのチャット */}
                  {chatClassroomsIdRow.length > 0 && isBeforeReserveChat && (
                    <>
                      <h1>予約を検討中の生徒とチャット</h1>
                      {chatClassroomsIdRow.map((id: any, index: any) => (
                        <div key={index} style={{ display: 'inline-block' }}>
                          <Avatar
                            onClick={() => {
                              setChatShowModal(true);
                              setClassroomsId(id);
                            }}
                          />
                          <Chat
                            classroomsId={classroomsId}
                            isOpen={chatShowModal}
                            setChatShowModal={setChatShowModal}
                            checked={checked}
                          />
                        </div>
                      ))}
                    </>
                  )}
                  <h1>
                    {teacherUid
                      ? '今後のレッスン予定（講師として）'
                      : '今後のレッスン予定'}
                  </h1>
                  <span>チャットメール通知</span>
                  <Switch
                    defaultChecked
                    color="primary"
                    onChange={handleChange}
                  />
                </IonCardHeader>
                <IonCardContent>
                  {Console.log(teacherUid)}
                  {teacherUid ? (
                    <TeacherReservationStatus
                      teacherUid={teacherUid}
                      setOpenLessonReady={setOpenLessonReady}
                      setClassroomData={setClassroomData}
                      currentUser={currentUser}
                      checked={checked}
                      setIsOnline={setIsOnline}
                      teacherEventList={teacherEventList}
                      setTeacherEventList={setTeacherEventList}
                    />
                  ) : (
                    <ReservationStatus
                      setOpenLessonReady={setOpenLessonReady}
                      setClassroomData={setClassroomData}
                      currentUser={currentUser}
                      checked={checked}
                      setIsOnline={setIsOnline}
                      eventList={eventList}
                      setEventList={setEventList}
                    />
                  )}
                </IonCardContent>
              </IonCard>
              {teacherUid && (
                <IonCard>
                  <IonCardHeader>
                    <h1>今後のレッスン予定（生徒として）</h1>
                  </IonCardHeader>
                  <IonCardContent>
                    <ReservationStatus
                      setOpenLessonReady={setOpenLessonReady}
                      setClassroomData={setClassroomData}
                      currentUser={currentUser}
                      checked={checked}
                      setIsOnline={setIsOnline}
                      eventList={eventList}
                      setEventList={setEventList}
                    />
                  </IonCardContent>
                </IonCard>
              )}
              <IonCard>
                <IonCardHeader>
                  <h1>受講履歴</h1>
                </IonCardHeader>
                <IonCardContent>
                  <AttendedStatus
                    teacherUid={teacherUid}
                    currentUser={currentUser}
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                {linkedProvider === googleProvider && (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {
                          setOpenLoginModal(true);
                        }}
                      >
                        Emailとパスワードのログインを有効にする
                      </IonButton>
                    </IonCardContent>
                  </>
                )}
                {/* {linkedProvider === passwordProvider && (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {}}
                      >
                        Googleログインもできるようにする
                      </IonButton>
                    </IonCardContent>
                  </>
                )} */}
                {/* {agencyUserUid ? (
                  <IonCardContent>
                        <IonButton
                          onClick={() => {
                            createBankAccount();
                          }}
                        >
                          銀行口座登録をする
                        </IonButton>
                      </IonCardContent>
                ) : (

                )} */}
                {teacherUid ? (
                  <>
                    <IonCardContent>
                      <IonButton
                        onClick={() => {
                          submitTeacherUid();
                        }}
                      >
                        予約枠を作成する
                      </IonButton>
                    </IonCardContent>
                    {reportingTo ? (
                      <></>
                    ) : (
                      <IonCardContent>
                        <IonButton
                          onClick={() => {
                            createBankAccount();
                          }}
                        >
                          銀行口座登録をする
                        </IonButton>
                      </IonCardContent>
                    )}
                    <IonCardContent>
                      {/* <IonButton
                        onClick={() => {
                          showPricingTable();
                        }}
                      >
                        委託料テーブル（謝礼）を確認する
                      </IonButton> */}
                    </IonCardContent>
                  </>
                ) : agencyUserUid ? (
                  <IonCardContent>
                    <IonButton
                      onClick={() => {
                        createBankAccount();
                      }}
                    >
                      銀行口座登録をする
                    </IonButton>
                  </IonCardContent>
                ) : (
                  <></>
                )}
                <IonCardContent>
                  <IonButton
                    onClick={() => {
                      firebase.logout();
                      props.history.push('/login');
                    }}
                  >
                    ログアウト
                  </IonButton>
                </IonCardContent>
                {!!teacherUid || !!directorUid ? (
                  <></>
                ) : (
                  <IonCardContent>
                    <a
                      className=""
                      href="#"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      退会
                    </a>
                  </IonCardContent>
                )}
              </IonCard>
              {/* <IonButton onClick={() => set()}>セット</IonButton> */}
              {modal()}
              {loginModal()}
              <Copyright />
              <IonToast
                isOpen={toastIsShown}
                onDidDismiss={() => setToastIsShown(false)}
                message={message}
                duration={5000}
              />
            </IonContent>
          </IonPage>
        </>
      ) : (
        <LessonReady
          classroomData={classroomData}
          teacherUid={teacherUid}
          closeLessonReady={setOpenLessonReady}
          isOnline={isOnline}
          beforeMinute={beforeMinute}
          setBeforeMinute={setBeforeMinute}
          takeLesson={takeLesson}
          lessonMessage={lessonMessage}
          toastIsShown={toastIsShown}
          setToastIsShown={setToastIsShown}
        />
      )}
    </>
  );
};

export default withRouter(Profile);
