import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonLabel,
  IonToolbar,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonModal,
} from '@ionic/react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Container,
  CssBaseline,
  Divider,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../hooks/firebase';
import Copyright from './Copyright';
import './Plans.css';
import LineAddFriends from './LineAddFriends';
import PlanCustomCard from './PlanCustomCard';
import PlanStatus from './PlanStatus';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(4),
    },
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
    },
  },
  cancelTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
  },
  cardLabel: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(1),
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
  },
  cardPricing: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
  },
  cardButton: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      margin: theme.spacing(1),
    },
  },
  dividerComponent: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
    },
  },
  divider: {
    border: 'none',
    borderTop: 'dotted 1px #000000',
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
  },
  modalWidth: {
    width: '900px',
  },
  center: {
    margin: 'auto',
  },
  modalTicket: {
    margin: 'auto',
  },
  close: {
    margin: 'auto',
    width: '100',
  },
}));

const Hr = props => {
  const classes = useStyles();
  return (
    <div className={classes.dividerComponent}>
      <Divider className={classes.divider} variant="fullWidth"></Divider>
    </div>
  );
};

const initBasicPlan = {
  priceId: '',
  id: '',
  title: '楽器(ベーシック)プラン 60分 月4回',
  price: '36000',
  mode: 'subscription',
  period: 1,
  times: 4,
  minute: 60,
  planType: 'basic',
  benefit: '10%OFF',
  description: ['定期購入'],
  status: 'inactive',
};

export const PlanChild = props => {
  const {
    isEmailVerify,
    tiers,
    currentTier,
    currentPlan,
    currentVoiceTrainingTier,
    currentVoiceTrainingPlan,
    currentVoiceTrainingInstallmentTier,
    currentVoiceTrainingInstallmentPlan,
    currentCanceledTier,
    currentCanceledPlan,
    availableZeroTicketTier,
    availableZeroTicketData,
    availableTicketTier,
    availableTicketData,
    availableVoiceTrial,
    availableVoiceTrainingTicketTier,
    availableVoiceTrainingTicketData,
    availableTrial,
    availableTrialTier,
    availableTrialData,
    availableVoiceTrainingTrialTier,
    availableVoiceTrainingTrialData,
    nextTier,
    nextPlan,
    instrumentTrial,
    voiceTrial,
    plusOne,
    plusVoiceOne,
    availableVoiceTrainingNumberOfClasses,
    planType,
    canceledTier,
    canceledPlan,
    hasPurchasedVoiceTraining,
    ticketType,
    history,
    coursePeriod,
  } = props;
  const classes = useStyles();
  const [plan, setPlan] = useState('basic'); // プルダウンのプラン
  const [period, setPeriod] = useState(1);
  const [times, setTimes] = useState(4); // プルダウンの回数
  const [minute, setMinute] = useState(60); // プルダウンのコースの時間
  const [showModal, setShowModal] = useState(false);

  const [toastIsShown, setToastIsShown] = useState(false);
  const initButtonDisabled = {
    basic: false,
    ondai: false,
    takaraduka: false,
    voiceTraining: false,
    minute45: false,
    minute60: false,
    minute75: false,
    period1: false,
    period2: false,
    period4: false,
    month1: false,
    month2: false,
    month3: false,
    month4: false,
    month8: false,
  };
  const [buttonDisabled, setButtonDisabled] = useState(initButtonDisabled);

  const [] = useState(null);
  const [newPlan, setNewPlan] = useState(initBasicPlan);
  const now = new Date();

  const setCustomPlan = async value => {
    let findPlan;
    setPlan(value);
    if (value === 'trail') {
      findPlan = tiers.find(
        tier =>
          tier.planType === value &&
          tier.times === 1 &&
          tier.minute === 30 &&
          tier.mode === 'subscription',
      );
      if (findPlan !== undefined) {
        setTimes(1);
        setMinute(30);
        setNewPlan(findPlan);
      }
    } else {
      findPlan = tiers.find(
        tier =>
          tier.planType === value &&
          tier.times === times &&
          tier.minute === minute &&
          tier.mode === 'subscription',
      );
      if (findPlan !== undefined) {
        setNewPlan(findPlan);
      }
      if (value === 'voiceTraining') {
        setShowModal(true);
      } else if (value === 'basic') {
        initBasicFunction(tiers);
        setPeriod(1);
        setTimes(4);
        setMinute(60);
      } else if (value === 'ondai') {
        setNewPlan({
          priceId: findPlan.priceId,
          id: findPlan.id,
          title: '楽器(プロフェッショナル)プラン 60分 月4回',
          price: '46800',
          mode: 'subscription',
          period: 1,
          times: 4,
          minute: 60,
          planType: 'ondai',
          benefit: '10%OFF',
          description: ['定期購入'],
          status: 'inactive',
        });
        setPeriod(1);
        setTimes(4);
        setMinute(60);
        // } else if (value === 'line') {
        //   setNewPlan({
        //     priceId: '',
        //     id: '',
        //     title: '楽器(ベーシック)プラン 1ヶ月 60分 月4回',
        //     price: '36000',
        //     mode: 'subscription',
        //     period: 1,
        //     times: 4,
        //     minute: 60,
        //     planType: 'basic',
        //     benefit: '10%OFF',
        //     description: ['定期購入'],
        //     status: 'inactive',
        //   });
        //   setPeriod(1);
        //   setTimes(4);
        //   setMinute(60);
        // }
      }
    }
  };

  const setCustomTimes = value => {
    const findPlan = tiers.find(
      tier =>
        tier.planType === plan &&
        tier.period === period &&
        tier.times === value &&
        tier.minute === minute &&
        tier.mode === 'subscription',
    );
    if (findPlan !== undefined) {
      setTimes(value);
      setNewPlan(findPlan);
    }
  };

  const setCustomMinute = value => {
    const findPlan = tiers.find(
      tier =>
        tier.planType === plan &&
        tier.period === period &&
        tier.times === times &&
        tier.minute === value &&
        tier.mode === 'subscription',
    );
    if (findPlan !== undefined) {
      setMinute(value);
      setNewPlan(findPlan);
    }
  };

  const initBasicFunction = tiers => {
    const findPlan = tiers.find(
      tier =>
        tier.planType === 'basic' &&
        tier.times === 4 &&
        tier.minute === 60 &&
        tier.mode === 'subscription',
    );
    setNewPlan({
      priceId: findPlan.priceId,
      id: findPlan.id,
      title: '楽器(ベーシック)プラン 60分 月4回',
      price: '36000',
      mode: 'subscription',
      period: 1,
      times: 4,
      minute: 60,
      planType: 'basic',
      benefit: '10%OFF',
      description: ['定期購入'],
      status: 'inactive',
    });
  };

  useEffect(() => {
    (async () => {
      const getTiers = await firebase.tiers();
      const initPlan =
        getTiers.find(
          tier =>
            tier.planType === 'basic' &&
            tier.minute === 60 &&
            tier.times === 4 &&
            tier.status === 'active',
        ) || getTiers.find(tier => tier.status === 'active');
      setNewPlan(initPlan);
    })();
  }, []);

  return (
    <>
      <IonContent>
        <React.Fragment>
          <CssBaseline />
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.mainTitle}
            >
              料金プラン
            </Typography>
          </Container>
          <Container maxWidth="md" component="main">
            {currentTier !== null ||
            currentCanceledTier !== null ||
            availableTicketTier !== null ||
            availableZeroTicketTier !== null ||
            availableTrialTier !== null ||
            availableVoiceTrainingTrialTier !== null ||
            nextTier !== null ||
            currentVoiceTrainingTier !== null ||
            currentVoiceTrainingInstallmentTier !== null ? (
              <>
                <h4 className={classes.subTitle}>
                  現在のプラン・チケットの取得状況
                </h4>
                <IonGrid>
                  {currentTier !== null && (
                    <PlanStatus
                      tier={currentTier}
                      courseEnrolledData={currentPlan}
                      title="現在契約中のプラン"
                      countLabel="更新日までの残り回数"
                      dateLabel="更新日"
                      subscription={true}
                      next={false}
                      cancel={false}
                    />
                  )}
                  {currentCanceledTier !== null && (
                    <PlanStatus
                      tier={currentCanceledTier}
                      courseEnrolledData={currentCanceledPlan}
                      title="解約される契約中のプラン"
                      countLabel="解約日までの残り回数"
                      dateLabel="解約日"
                      subscription={true}
                      next={false}
                      cancel={true}
                    />
                  )}
                  {availableTrialTier !== null && (
                    <PlanStatus
                      tier={availableTrialTier}
                      courseEnrolledData={availableTrialData}
                      title="トライアルチケット"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      subscription={false}
                      next={false}
                      cancel={false}
                    />
                  )}
                  {availableVoiceTrainingTrialTier !== null && (
                    <PlanStatus
                      tier={availableVoiceTrainingTrialTier}
                      courseEnrolledData={availableVoiceTrainingTrialData}
                      title="トライアルチケット"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      subscription={false}
                      next={false}
                      cancel={false}
                    />
                  )}
                  {availableTicketTier === null &&
                    availableZeroTicketTier !== null && (
                      <PlanStatus
                        tier={availableZeroTicketTier}
                        courseEnrolledData={availableZeroTicketData}
                        title="追加チケット"
                        countLabel="枚数"
                        dateLabel="予約期限"
                        subscription={false}
                        next={false}
                        cancel={false}
                      />
                    )}
                  {currentVoiceTrainingTier !== null && (
                    <PlanStatus
                      tier={currentVoiceTrainingTier}
                      courseEnrolledData={currentVoiceTrainingPlan}
                      title="現在契約中のプラン"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      subscription={false}
                      next={false}
                      cancel={false}
                      planType={planType}
                      ticketType={ticketType}
                      period={period}
                    />
                  )}
                  {currentVoiceTrainingInstallmentTier !== null && (
                    <PlanStatus
                      tier={currentVoiceTrainingInstallmentTier}
                      courseEnrolledData={currentVoiceTrainingInstallmentPlan}
                      title="現在契約中のプラン"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      coursePeriod={coursePeriod}
                      // endDateLabel="プラン終了日"
                      // countLabel="今月の残り回数"
                      // dateLabel="今月分のチケットの予約期限"
                      // endDateLabel="プラン終了日"
                      subscription={true}
                      next={false}
                      cancel={false}
                      planType={planType}
                      ticketType={ticketType}
                      period={period}
                    />
                  )}
                  {availableTicketTier !== null && (
                    <PlanStatus
                      tier={availableTicketTier}
                      courseEnrolledData={availableTicketData}
                      title="追加チケット"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      subscription={true}
                      next={false}
                      cancel={false}
                    />
                  )}
                  {availableVoiceTrainingTicketTier !== null && (
                    <PlanStatus
                      tier={availableVoiceTrainingTicketTier}
                      courseEnrolledData={availableVoiceTrainingTicketData}
                      title="追加チケット"
                      countLabel="枚数"
                      dateLabel="予約期限"
                      subscription={true}
                      next={false}
                      cancel={false}
                      planType={planType}
                      ticketType={ticketType}
                      period={period}
                    />
                  )}
                  {nextTier !== null && (
                    <PlanStatus
                      tier={nextTier}
                      courseEnrolledData={nextPlan}
                      title="次に契約予定のプラン"
                      countLabel="更新日までの残り回数"
                      dateLabel="更新日"
                      subscription={true}
                      next={true}
                      cancel={false}
                    />
                  )}
                </IonGrid>
                <Hr />
              </>
            ) : (
              <></>
            )}
            {currentTier === null &&
              nextTier === null &&
              currentCanceledTier !== null && (
                <>
                  <h4 className={classes.subTitle}>プラン</h4>
                  <IonItem>
                    <IonLabel>プラン</IonLabel>
                    <IonSelect
                      value={plan}
                      multiple={false}
                      cancelText="戻る"
                      okText="確定"
                      onIonChange={e => setCustomPlan(e.detail.value)}
                      interfaceOptions={{ cssClass: 'custom-class' }}
                    >
                      <IonSelectOption
                        disabled={buttonDisabled.basic}
                        value="basic"
                      >
                        楽器(ベーシック)プラン
                      </IonSelectOption>
                      <IonSelectOption
                        disabled={buttonDisabled.ondai}
                        value="ondai"
                      >
                        楽器(プロフェッショナル)プラン
                      </IonSelectOption>
                      {/* <IonSelectOption
                  disabled={buttonDisabled.takaraduka}
                  value="takaraduka"
                >
                  宝塚受験プラン
                </IonSelectOption> */}
                      <IonSelectOption
                        disabled={buttonDisabled.voiceTraining}
                        value="voiceTraining"
                      >
                        ボイスマスタープログラム
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {/* 時間できてからリファクタリング */}
                  {plan === 'voiceTraining' ? (
                    !hasPurchasedVoiceTraining ? (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          ボイスマスタープログラムは
                          <br />
                          LINEにてお問い合わせ下さい
                          <br />
                          <LineAddFriends />
                        </h1>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    ) : (currentVoiceTrainingTier !== null ||
                        currentVoiceTrainingInstallmentTier !== null) &&
                      (availableVoiceTrainingNumberOfClasses === 0 ||
                        availableVoiceTrainingNumberOfClasses === null) ? (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          追加チケットをご購入ください
                        </h1>
                        <p className={classes.modalTicket}>
                          <Grid container spacing={0} alignItems="flex-start">
                            {plusVoiceOne.map(plus => (
                              <PlanCustomCard
                                mode="payment"
                                tier={plus}
                                isEmailVerify={isEmailVerify}
                              />
                            ))}
                          </Grid>
                        </p>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    ) : (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          契約中のボイトレチケットを
                          <br />
                          使い切ってから追加チケット
                          <br />
                          をご購入ください
                        </h1>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    )
                  ) : (
                    <>
                      <IonItem>
                        <IonLabel>回数</IonLabel>
                        <IonSelect
                          value={times}
                          multiple={false}
                          cancelText="戻る"
                          okText="確定"
                          onIonChange={e => setCustomTimes(e.detail.value)}
                        >
                          <IonSelectOption
                            disabled={buttonDisabled.month1}
                            value={1}
                          >
                            月1回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month2}
                            value={2}
                          >
                            月2回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month3}
                            value={3}
                          >
                            月3回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month4}
                            value={4}
                          >
                            月4回
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>レッスン時間</IonLabel>
                        <IonSelect
                          value={minute}
                          multiple={false}
                          cancelText="戻る"
                          okText="確定"
                          onIonChange={e => setCustomMinute(e.detail.value)}
                        >
                          <IonSelectOption
                            disabled={buttonDisabled.minute45}
                            value={45}
                          >
                            45分
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.minute60}
                            value={60}
                          >
                            60分
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.minute75}
                            value={75}
                          >
                            75分
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <Grid container spacing={0} alignItems="flex-start">
                        <PlanCustomCard
                          isEmailVerify={isEmailVerify}
                          tier={newPlan}
                          mode="stopPlan"
                          currentPlan={currentCanceledPlan}
                          currentCanceledTier={currentCanceledTier}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            {currentTier === null &&
              nextTier === null &&
              currentCanceledTier === null && (
                <>
                  <h4 className={classes.subTitle}>プランを選ぶ</h4>
                  <IonItem>
                    <IonLabel>プラン</IonLabel>
                    <IonSelect
                      value={plan}
                      multiple={false}
                      cancelText="戻る"
                      okText="確定"
                      onIonChange={e => setCustomPlan(e.detail.value)}
                      interfaceOptions={{ cssClass: 'custom-class' }}
                    >
                      <IonSelectOption
                        disabled={buttonDisabled.basic}
                        value="basic"
                      >
                        楽器(ベーシック)プラン
                      </IonSelectOption>
                      <IonSelectOption
                        disabled={buttonDisabled.ondai}
                        value="ondai"
                      >
                        楽器(プロフェッショナル)プラン
                      </IonSelectOption>
                      {/* <IonSelectOption
                  disabled={buttonDisabled.takaraduka}
                  value="takaraduka"
                >
                  宝塚受験プラン
                </IonSelectOption> */}
                      <IonSelectOption
                        disabled={buttonDisabled.voiceTraining}
                        value="voiceTraining"
                      >
                        ボイスマスタープログラム
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {/* 時間できてからリファクタリング */}
                  {plan === 'voiceTraining' ? (
                    !hasPurchasedVoiceTraining ? (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          ボイスマスタープログラムは
                          <br />
                          LINEにてお問い合わせ下さい
                          <br />
                          <LineAddFriends />
                        </h1>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    ) : (currentVoiceTrainingTier !== null ||
                        currentVoiceTrainingInstallmentTier !== null) &&
                      (availableVoiceTrainingNumberOfClasses === 0 ||
                        availableVoiceTrainingNumberOfClasses === null) ? (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          追加チケットをご購入ください
                        </h1>
                        <p className={classes.modalTicket}>
                          <Grid container spacing={0} alignItems="flex-start">
                            {plusVoiceOne.map(plus => (
                              <PlanCustomCard
                                isEmailVerify={isEmailVerify}
                                mode="payment"
                                tier={plus}
                              />
                            ))}
                          </Grid>
                        </p>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    ) : (
                      <IonModal
                        isOpen={showModal}
                        onDidDismiss={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                        cssClass="custom-modal"
                      >
                        <h1 className={classes.center}>
                          契約中のボイトレチケットを
                          <br />
                          使い切ってから追加チケット
                          <br />
                          をご購入ください
                        </h1>
                        <Button
                          variant="outlined"
                          className={classes.close}
                          onClick={() => {
                            setShowModal(false);
                            initBasicFunction(tiers);
                            setPlan('basic');
                            setPeriod(1);
                            setTimes(4);
                            setMinute(60);
                          }}
                        >
                          閉じる
                        </Button>
                      </IonModal>
                    )
                  ) : (
                    <>
                      <IonItem>
                        <IonLabel>回数</IonLabel>
                        <IonSelect
                          value={times}
                          multiple={false}
                          cancelText="戻る"
                          okText="確定"
                          onIonChange={e => setCustomTimes(e.detail.value)}
                        >
                          <IonSelectOption
                            disabled={buttonDisabled.month1}
                            value={1}
                          >
                            月1回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month2}
                            value={2}
                          >
                            月2回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month3}
                            value={3}
                          >
                            月3回
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.month4}
                            value={4}
                          >
                            月4回
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel>レッスン時間</IonLabel>
                        <IonSelect
                          value={minute}
                          multiple={false}
                          cancelText="戻る"
                          okText="確定"
                          onIonChange={e => setCustomMinute(e.detail.value)}
                        >
                          <IonSelectOption
                            disabled={buttonDisabled.minute45}
                            value={45}
                          >
                            45分
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.minute60}
                            value={60}
                          >
                            60分
                          </IonSelectOption>
                          <IonSelectOption
                            disabled={buttonDisabled.minute75}
                            value={75}
                          >
                            75分
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <Grid container spacing={0} alignItems="flex-start">
                        <PlanCustomCard
                          isEmailVerify={isEmailVerify}
                          tier={newPlan}
                          mode="subscription"
                          currentCanceledTier={currentCanceledTier}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            {((currentTier === null &&
              availableTrialTier === null &&
              currentCanceledTier === null) ||
              (currentVoiceTrainingTier === null &&
                currentVoiceTrainingInstallmentTier === null &&
                availableVoiceTrainingTrialTier === null)) && (
              <>
                <h4 className={classes.subTitle}>
                  トライアルレッスンを購入する
                </h4>
                {/* 楽器トライアル */}
                {currentTier === null &&
                  availableTrialTier === null &&
                  currentCanceledTier === null && (
                    <>
                      <p>楽器プランのトライアルレッスン</p>
                      <PlanCustomCard
                        isEmailVerify={isEmailVerify}
                        tier={instrumentTrial}
                        mode="payment"
                      />
                    </>
                  )}
                {/* トライアルボイストレーニング */}
                {currentVoiceTrainingTier === null &&
                  currentVoiceTrainingInstallmentTier === null &&
                  availableVoiceTrainingTrialTier === null && (
                    <>
                      <p>ボイスマスタープログラムのトライアルレッスン</p>
                      <PlanCustomCard
                        isEmailVerify={isEmailVerify}
                        tier={voiceTrial}
                        mode="payment"
                      />
                    </>
                  )}
                <Hr />
              </>
            )}
            {(((currentTier !== null || currentCanceledTier !== null) &&
              (availableTicketTier === null ||
                availableTicketData?.numberOfClasses === 0)) ||
              (currentVoiceTrainingTier !== null &&
                (availableVoiceTrainingNumberOfClasses === 0 ||
                  availableVoiceTrainingNumberOfClasses === null)) ||
              (currentVoiceTrainingInstallmentTier !== null &&
                (availableVoiceTrainingNumberOfClasses === 0 ||
                  availableVoiceTrainingNumberOfClasses === null))) && (
              <>
                {(planType === 'takaraduka' ||
                  planType === 'ondai' ||
                  planType === 'basic' ||
                  planType === 'voiceTraining') && (
                  <>
                    {currentTier === null && nextTier === null && <Hr />}
                    <h4 className={classes.subTitle}>追加チケットを買う</h4>
                    <div className={classes.margin10}></div>
                    {(currentTier !== null || currentCanceledTier !== null) &&
                      (availableTicketTier === null ||
                        availableTicketData.numberOfClasses === 0) && (
                        <>
                          <p>楽器プランの追加チケット</p>
                          <Grid container spacing={0} alignItems="flex-start">
                            {plusOne.map(plus => (
                              <PlanCustomCard
                                isEmailVerify={isEmailVerify}
                                mode="payment"
                                tier={plus}
                                currentPlan={currentPlan}
                                history={history}
                              />
                            ))}
                          </Grid>
                        </>
                      )}
                    {(currentVoiceTrainingTier !== null ||
                      currentVoiceTrainingInstallmentTier !== null) &&
                      (availableVoiceTrainingNumberOfClasses === 0 ||
                        availableVoiceTrainingNumberOfClasses === null) && (
                        <>
                          <p>ボイスマスタープログラムの追加チケット</p>
                          <Grid container spacing={0} alignItems="flex-start">
                            {plusVoiceOne.map(plus => (
                              <PlanCustomCard
                                isEmailVerify={isEmailVerify}
                                mode="payment"
                                tier={plus}
                                currentPlan={currentPlan}
                                history={history}
                              />
                            ))}
                          </Grid>
                        </>
                      )}
                    <div className={classes.margin20}></div>
                  </>
                )}
              </>
            )}
            {currentTier !== null && currentCanceledTier === null && (
              <>
                <Hr />
                <h4 className={classes.subTitle}>
                  プランを変更する(現在のプランを解約して新しく契約)
                </h4>
                <IonItem>
                  <IonLabel>プラン</IonLabel>
                  <IonSelect
                    value={plan}
                    multiple={false}
                    cancelText="戻る"
                    okText="確定"
                    onIonChange={e => setCustomPlan(e.detail.value)}
                    interfaceOptions={{ cssClass: 'custom-class' }}
                  >
                    <IonSelectOption value="basic">
                      楽器(ベーシック)プラン
                    </IonSelectOption>
                    <IonSelectOption value="ondai">
                      楽器(プロフェッショナル)プラン
                    </IonSelectOption>
                    {/* <IonSelectOption value="takaraduka">
                宝塚受験プラン
              </IonSelectOption> */}
                    <IonSelectOption value="voiceTraining">
                      ボイスマスタープログラム
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                {plan === 'voiceTraining' ? (
                  !hasPurchasedVoiceTraining ? (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        ボイスマスタープログラムは
                        <br />
                        LINEにてお問い合わせ下さい
                        <br />
                        <LineAddFriends />
                      </h1>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  ) : (currentVoiceTrainingTier !== null ||
                      currentVoiceTrainingInstallmentTier !== null) &&
                    (availableVoiceTrainingNumberOfClasses === 0 ||
                      availableVoiceTrainingNumberOfClasses === null) ? (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        追加チケットをご購入ください
                      </h1>
                      <p className={classes.modalTicket}>
                        <Grid container spacing={0} alignItems="flex-start">
                          {plusVoiceOne.map(plus => (
                            <PlanCustomCard
                              isEmailVerify={isEmailVerify}
                              mode="payment"
                              tier={plus}
                            />
                          ))}
                        </Grid>
                      </p>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  ) : (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        契約中のボイトレチケットを
                        <br />
                        使い切ってから追加チケット
                        <br />
                        をご購入ください
                      </h1>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  )
                ) : (
                  <>
                    <IonItem>
                      <IonLabel>回数</IonLabel>
                      <IonSelect
                        value={times}
                        multiple={false}
                        cancelText="戻る"
                        okText="確定"
                        onIonChange={e => setCustomTimes(e.detail.value)}
                      >
                        <IonSelectOption
                          disabled={buttonDisabled.month1}
                          value={1}
                        >
                          月1回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month2}
                          value={2}
                        >
                          月2回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month3}
                          value={3}
                        >
                          月3回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month4}
                          value={4}
                        >
                          月4回
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel>レッスン時間</IonLabel>
                      <IonSelect
                        value={minute}
                        multiple={false}
                        cancelText="戻る"
                        okText="確定"
                        onIonChange={e => setCustomMinute(e.detail.value)}
                      >
                        <IonSelectOption
                          disabled={buttonDisabled.minute45}
                          value={45}
                        >
                          45分
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.minute60}
                          value={60}
                        >
                          60分
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.minute75}
                          value={75}
                        >
                          75分
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <Grid container spacing={0} alignItems="flex-start">
                      <PlanCustomCard
                        isEmailVerify={isEmailVerify}
                        tier={newPlan}
                        mode="update"
                        currentPlan={currentPlan}
                        history={history}
                        nextPlan={nextPlan}
                        nextTier={nextTier}
                        currentCanceledTier={currentCanceledTier}
                        currentTier={currentTier}
                      />
                    </Grid>
                  </>
                )}
                <div className={classes.margin40}></div>
              </>
            )}
            {nextTier !== null && (
              <>
                <Hr />
                <h4 className={classes.subTitle}>
                  次に契約予定のプランを変更する
                </h4>
                <IonItem>
                  <IonLabel>新しいプランを選ぶ</IonLabel>
                  <IonSelect
                    value={plan}
                    multiple={false}
                    cancelText="戻る"
                    okText="確定"
                    onIonChange={e => setCustomPlan(e.detail.value)}
                    interfaceOptions={{ cssClass: 'custom-class' }}
                  >
                    <IonSelectOption value="basic">
                      楽器(ベーシック)プラン
                    </IonSelectOption>
                    <IonSelectOption value="ondai">
                      楽器(プロフェッショナル)プラン
                    </IonSelectOption>
                    {/* <IonSelectOption value="takaraduka">
                宝塚受験プラン
              </IonSelectOption> */}
                    <IonSelectOption value="voiceTraining">
                      ボイスマスタープログラム
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                {/* 時間できてからリファクタリング */}
                {plan === 'voiceTraining' ? (
                  !hasPurchasedVoiceTraining ? (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        ボイスマスタープログラムは
                        <br />
                        LINEにてお問い合わせ下さい
                        <br />
                        <LineAddFriends />
                      </h1>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  ) : (currentVoiceTrainingTier !== null ||
                      currentVoiceTrainingInstallmentTier !== null) &&
                    (availableVoiceTrainingNumberOfClasses === 0 ||
                      availableVoiceTrainingNumberOfClasses === null) ? (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        追加チケットをご購入ください
                      </h1>
                      <p className={classes.modalTicket}>
                        <Grid container spacing={0} alignItems="flex-start">
                          {plusVoiceOne.map(plus => (
                            <PlanCustomCard
                              isEmailVerify={isEmailVerify}
                              mode="payment"
                              tier={plus}
                            />
                          ))}
                        </Grid>
                      </p>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  ) : (
                    <IonModal
                      isOpen={showModal}
                      onDidDismiss={() => {
                        setShowModal(false);
                        initBasicFunction(tiers);
                        setPlan('basic');
                        setPeriod(1);
                        setTimes(4);
                        setMinute(60);
                      }}
                      cssClass="custom-modal"
                    >
                      <h1 className={classes.center}>
                        契約中のボイトレチケットを
                        <br />
                        使い切ってから追加チケット
                        <br />
                        をご購入ください
                      </h1>
                      <Button
                        variant="outlined"
                        className={classes.close}
                        onClick={() => {
                          setShowModal(false);
                          initBasicFunction(tiers);
                          setPlan('basic');
                          setPeriod(1);
                          setTimes(4);
                          setMinute(60);
                        }}
                      >
                        閉じる
                      </Button>
                    </IonModal>
                  )
                ) : (
                  <>
                    <IonItem>
                      <IonLabel>回数</IonLabel>
                      <IonSelect
                        value={times}
                        multiple={false}
                        cancelText="戻る"
                        okText="確定"
                        onIonChange={e => setCustomTimes(e.detail.value)}
                      >
                        <IonSelectOption
                          disabled={buttonDisabled.month1}
                          value={1}
                        >
                          月1回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month2}
                          value={2}
                        >
                          月2回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month3}
                          value={3}
                        >
                          月3回
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.month4}
                          value={4}
                        >
                          月4回
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel>レッスン時間</IonLabel>
                      <IonSelect
                        value={minute}
                        multiple={false}
                        cancelText="戻る"
                        okText="確定"
                        onIonChange={e => setCustomMinute(e.detail.value)}
                      >
                        <IonSelectOption
                          disabled={buttonDisabled.minute45}
                          value={45}
                        >
                          45分
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.minute60}
                          value={60}
                        >
                          60分
                        </IonSelectOption>
                        <IonSelectOption
                          disabled={buttonDisabled.minute75}
                          value={75}
                        >
                          75分
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <Grid container spacing={0} alignItems="flex-start">
                      <PlanCustomCard
                        isEmailVerify={isEmailVerify}
                        mode="update"
                        tier={newPlan}
                        currentCanceledTier={currentCanceledTier}
                        currentPlan={nextPlan}
                        nextTier={nextTier}
                        nextPlan={nextPlan}
                        history={history}
                      />
                    </Grid>
                  </>
                )}
                <div className={classes.margin40}></div>
              </>
            )}
            {currentTier !== null && (
              <>
                <Hr />
                <IonCol size="1">
                  <IonRow>
                    <h4 className={classes.cancelTitle} color="danger">
                      プランの解約
                    </h4>
                  </IonRow>
                  <div className={classes.margin10}></div>
                  <IonRow>
                    <h5 className={classes.cardLabel} size="small">
                      あなたのプラン
                    </h5>
                  </IonRow>
                  <IonRow>
                    <PlanCustomCard
                      isEmailVerify={isEmailVerify}
                      mode="cancel"
                      tier={currentTier}
                      currentPlan={currentPlan}
                      history={history}
                    />
                    <div className={classes.margin10}></div>
                  </IonRow>
                </IonCol>
              </>
            )}

            {currentCanceledTier !== null && nextTier !== null && (
              <>
                <Hr />
                <IonCol size="1">
                  <IonRow>
                    <h4 className={classes.cancelTitle} color="danger">
                      次に契約予定のプランの解約
                    </h4>
                  </IonRow>
                  <div className={classes.margin10}></div>
                  <p>
                    次に契約予定のプランの『{nextTier?.title}
                    』の料金は発生しなくなります。
                  </p>
                  <p>
                    現在のプラン解約後は、無料プランとなります。無料プランの場合でも、講師検索や月額料金プランのご契約等は可能です。
                  </p>
                  <IonRow>
                    <h5 className={classes.cardLabel} size="small">
                      次に契約予定のプラン
                    </h5>
                  </IonRow>
                  <IonRow>
                    <PlanCustomCard
                      isEmailVerify={isEmailVerify}
                      mode="cancel"
                      tier={nextTier}
                      currentPlan={nextPlan}
                      history={history}
                    />
                    <div className={classes.margin10}></div>
                  </IonRow>
                </IonCol>
              </>
            )}
          </Container>
          <div className={classes.margin20}></div>
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <Box mt={8}>
                <Copyright />
              </Box>
            </IonToolbar>
          </IonFooter>
        </React.Fragment>
      </IonContent>
    </>
  );
};
