import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StarIcon } from '@material-ui/icons/StarBorder';
import CheckoutButton from './CheckoutButton';
import SubscriptionButton from './SubscriptionButton';
import UpdateSubscription from './UpdateSubscription';
import CancelSubscription from './CancelSubscription';
import './Plans.css';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(4),
    },
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
    },
  },
  cancelTitle: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(2),
      color: '#ff0000',
    },
  },
  cardLabel: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      marginTop: theme.spacing(1),
    },
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
  },
  cardPricing: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
  },
  cardButton: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      margin: theme.spacing(1),
    },
  },
  dividerComponent: {
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
    },
  },
  divider: {
    border: 'none',
    borderTop: 'dotted 1px #000000',
    [theme.breakpoints.down('sm')]: {
      // スマホサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      // タブレット, PCサイズ
      width: '100%',
      height: '1px',
      marginTop: '60px',
      marginBottom: '60px',
      backgroundColor: '#fff',
      // margin: theme.spacing(1),
    },
  },
  modalWidth: {
    width: '900px',
  },
  center: {
    margin: 'auto',
  },
  modalTicket: {
    margin: 'auto',
  },
  close: {
    margin: 'auto',
    width: '100',
  },
}));

const PlanCustomCard = props => {
  const {
    isEmailVerify,
    tier,
    mode,
    currentPlan,
    history,
    nextTier,
    nextPlan,
    currentCanceledTier,
    currentTier,
  } = props;
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <CardHeader
        title={tier?.title}
        subheader={tier?.description}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={tier?.title === 'Pro' ? <StarIcon /> : null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h2" variant="h3" color="textPrimary">
            ¥{tier?.price}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            /月
          </Typography>
        </div>
        <ul>
          {tier?.description &&
            tier?.description.map(line => (
              <Typography
                component="li"
                variant="subtitle1"
                align="center"
                key={line}
              >
                {line}
              </Typography>
            ))}
        </ul>
      </CardContent>
      {isEmailVerify && (
        <CardActions className={classes.cardButton}>
          {mode === 'stopPlan' ? (
            <p>
              解約中のプランは
              {currentPlan?.completedAt?.toDate().getMonth() + 1}月
              {currentPlan?.completedAt?.toDate().getDate()}
              日までご利用いただけます。
              <br />
              {currentPlan?.completedAt?.toDate().getMonth() + 1}月
              {currentPlan?.completedAt?.toDate().getDate() + 1}
              日より新しいプランをご契約可能です。
            </p>
          ) : tier?.title === currentTier?.title ? (
            <p>現在契約中のプランです</p>
          ) : tier?.title === currentCanceledTier?.title ? (
            <p>解約される契約中のプランです</p>
          ) : tier?.title === nextTier?.title ? (
            <p>次に契約予定のプランです</p>
          ) : mode === 'subscription' ? (
            <SubscriptionButton
              amount={Number(tier?.price)}
              priceId={tier?.priceId}
              title={tier?.title}
              description={tier?.description}
              times={tier?.times}
              id={tier?.id}
              mode={'create'}
            />
          ) : mode === 'payment' ? (
            <CheckoutButton
              amount={Number(tier?.price)}
              priceId={tier?.priceId}
              title={tier?.title}
              description={tier?.description}
              times={tier?.times}
              id={tier?.id}
            />
          ) : mode === 'update' ? (
            <UpdateSubscription
              nextId={nextTier ? nextTier?.id : null}
              at={nextPlan ? nextPlan.enrolledAt?.toDate() : null}
              amount={Number(tier?.price)}
              priceId={tier?.priceId}
              title={tier?.title}
              description={tier?.description}
              times={tier?.times}
              id={tier?.id}
              firestoreData={currentPlan}
              mode={'update'}
              history={history}
            />
          ) : (
            <CancelSubscription
              courseId={tier?.id}
              firestoreData={currentPlan}
              history={history}
            />
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default PlanCustomCard;
