const log = (text: any, subtext: any = "") => {
  const url = window.location.host;
  const headText = url.slice(0, 5);
  if (!(headText === "stg-l" || headText === "music" || headText === "langd")) {
    console.log(text, subtext);
  }
}

const dir = (text: any, subtext: any = "") => {
  const url = window.location.host;
  const headText = url.slice(0, 5);
  if (!(headText === "stg-l" || headText === "music" || headText === "langd")) {
    console.dir(text, subtext);
  }
}

const error = (text: any, subtext: any = "") => {
  const url = window.location.host;
  const headText = url.slice(0, 5);
  if (!(headText === "stg-l" || headText === "music" || headText === "langd")) {
    console.error(text, subtext);
  }
}

export default {
  log,
  dir,
  error
}