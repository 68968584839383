import React, { useContext, useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import firebase, { firestore } from '../hooks/firebase';
import { AuthContext } from '../hooks/Auth';
import Console from "../hooks/console";

type SubscriptionProps = {
  id: string;
  amount: Number;
  priceId: string;
  title: string;
  description: string;
  times: Number;
  mode: string;
  firestoreData: any;
  history: any
  nextId: string | null;
  at: Date | null;
};

const UpdateSubscription = (props: SubscriptionProps) => {
  const { id, amount, priceId, title, description, times, mode, firestoreData, history, nextId, at } = props;
  const { currentUser } = useContext(AuthContext);

  const handleClick = async () => {
    // const createSubscription = firebase.createSubscription();
    const userRef = firebase.getStudent(currentUser?.uid);
    const userDoc: any = await userRef.get().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data();
      }
    });
    const courseEnrolledData = userDoc.courseEnrolled;
    const stripeData = userDoc.stripeData;
    const data = {
      id: stripeData.subscription,
      priceId: priceId,
    };
    Console.log('updateSubscription data:', data)
    const subscription: any = await firebase.updateSubscription(data)
      .then(res => {
        Console.dir("updateSubscription", res);
        return res.result;
      })
      .catch(error => {
        Console.error(error);
      });
    Console.log('subscription: ' + JSON.stringify(subscription));

    if (!!subscription) {
      const enrolledAt: Date = firestoreData.enrolledAt.toDate();
      const now = new Date();
      let maturity: Date;
      // let nextMaturity: Date | null = null;
      let newEnrolledAt: Date;
      // let nextEnrolledAt: Date;
      if (enrolledAt.getDate() <= new Date().getDate()) { //
        maturity = new Date(
          new Date().getFullYear(),
          now.getMonth() + 1,
          enrolledAt.getDate() - 1,
          23,
          59,
          59,
        );
        newEnrolledAt = new Date(now.getFullYear(), now.getMonth() + 1, enrolledAt.getDate());
      } else {
        maturity = new Date(
          now.getFullYear(),
          now.getMonth(),
          enrolledAt.getDate() - 1,
          23,
          59,
          59,
        );
        newEnrolledAt = new Date(now.getFullYear(), now.getMonth(), enrolledAt.getDate());
      }
      // if (at) {
      //   if (at.getDate() <= now.getDate()) {
      //     nextMaturity = new Date(
      //       at.getFullYear(),
      //       at.getMonth() + 1,
      //       at.getDate() - 1,
      //       23,
      //       59,
      //       59,
      //     );
      //     nextEnrolledAt = new Date(at.getFullYear(), at.getMonth() + 1, at.getDate());
      //   } else {
      //     nextMaturity = new Date(
      //       at.getFullYear(),
      //       at.getMonth(),
      //       at.getDate() - 1,
      //       23,
      //       59,
      //       59,
      //     );
      //     nextEnrolledAt = new Date(at.getFullYear(), at.getMonth() + 1, at.getDate());
      //   }
      // } else {
      //   nextEnrolledAt = new Date();
      // }
      const newCourseEnrolledId = 'courses/' + id;
      const currentCourseEnrolledId = 'courses/' + firestoreData.id;
      const setData = nextId ? {
        courseEnrolled: {
          ...courseEnrolledData,
          [newCourseEnrolledId]: {
            id: id,
            status: 'ongoing',
            completedAt: null,
            enrolledAt: newEnrolledAt,
            numberOfClasses: times,
            mode: "subscription",
          },
          [`courses/${nextId}`]: {
            status: 'canceled',
            numberOfClasses: 0,
            enrolledAt: new Date(),
            completedAt: new Date(),
          }
        },
      } : {
          courseEnrolled: {
            ...courseEnrolledData,
            [newCourseEnrolledId]: {
              id: id,
              status: 'ongoing',
              completedAt: null,
              enrolledAt: newEnrolledAt,
              numberOfClasses: times,
              mode: "subscription",
            },
            [currentCourseEnrolledId]: {
              status: 'canceled',
              completedAt: maturity,
            },
          }
        };
      Console.log(setData);
      userRef
        .set(setData, { merge: true })
        .then(() => history.push('/successupdate'))
        .catch(e => { });
    }
  }
  return <IonButton onClick={async () => await handleClick()}>このプランに変更する</IonButton>;
};

export default UpdateSubscription;
